import { Action } from 'common/interfaces/root';
import { brandSettingsActionTypes as T } from '../actions';

const initState: BrandSettingsTypes.PendingInvitesStore = {
  loading: true,
  error: null,
  data: [],
};

export default (
  state: BrandSettingsTypes.PendingInvitesStore = initState,
  { type, payload }: Action
): BrandSettingsTypes.PendingInvitesStore => {
  switch (type) {
    case T.GET_PENDING_INVITATIONS:
      return { ...state, loading: true };

    case T.GET_PENDING_INVITATIONS_SUCCESS:
      return { ...state, loading: false, data: payload.data };

    case T.GET_PENDING_INVITATIONS_ERROR:
      return { ...state, loading: false, error: payload };

    default:
      return state;
  }
};
