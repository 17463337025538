import { AxiosError } from 'axios';
import { toast } from 'react-hot-toast';
import { ErrorRequest } from '../interfaces/root';

interface Options {
  showNotification?: boolean;
}

interface ErrorData {
  message?: string;
}

const message = 'Something went wrong';
const defaultOptions: Options = {
  showNotification: true,
};

export const utilRequestErrorMessage = (
  err: ErrorRequest,
  defaultMessage: string = message,
  options: Options = defaultOptions
) => {
  const { showNotification = true } = options;
  let errorMessage;

  if (err instanceof AxiosError<ErrorData>) {
    const axiosErrorMessage = err?.response?.data.message;
    errorMessage = axiosErrorMessage ? `${err?.response?.status}: ${axiosErrorMessage}` : defaultMessage;
  } else {
    errorMessage = err?.message ?? defaultMessage;
  }

  if (showNotification) {
    toast.error(errorMessage);
  }

  return errorMessage;
};
