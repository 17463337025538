import { getDataListActionTypes } from 'common/store/actions/data-list';

const INTEGRATIONS_MODULE = '[brand-settings:integrations]';
const BRAND_MODULE = '[brand-settings:brand]';
const ATTRIBUTION_MODULE = '[brand-settings:attribution]';
const ATTRIBUTION_PROFILES_MODULE = '[brand-settings:attribution-profiles]';
const ATTRIBUTION_PUBLISHERS_MODULE = '[brand-settings:attribution-publishers]';
const PAYMENT_MODULE = '[brand-settings:payment]';
const PAYMENT_TRANSACTIONS_MODULE = '[brand-settings:payment-transactions]';
const ATTRIBUTION_STORES_MODULE = '[brand-settings:attribution-stores]';
const ATTRIBUTION_ASINS_MODULE = '[brand-settings:attribution-asins]';
const TEAM_MODULE = '[brand-settings:team]';
const DOCUMENTS_MODULE = '[brand-settings:documents]';

const GET_BRAND = `${BRAND_MODULE} GET_BRAND`;
const GET_BRAND_SUCCESS = `${BRAND_MODULE} GET_BRAND_SUCCESS`;
const GET_BRAND_ERROR = `${BRAND_MODULE} GET_BRAND_ERROR`;

const UPDATE_BRAND = `${BRAND_MODULE} UPDATE_BRAND`;
const UPDATE_BRAND_SUCCESS = `${BRAND_MODULE} UPDATE_BRAND_SUCCESS`;
const UPDATE_BRAND_ERROR = `${BRAND_MODULE} UPDATE_BRAND_ERROR`;

const REVOKE_INTEGRATION = `${INTEGRATIONS_MODULE} REVOKE_INTEGRATION`;
const INIT_INTEGRATION = `${INTEGRATIONS_MODULE} INIT_INTEGRATION`;

const GET_ATTRIBUTION_DATA = `${ATTRIBUTION_MODULE} GET_ATTRIBUTION_DATA`;
const GET_ATTRIBUTION_DATA_SUCCESS = `${ATTRIBUTION_MODULE} GET_ATTRIBUTION_DATA_SUCCESS`;
const GET_ATTRIBUTION_DATA_ERROR = `${ATTRIBUTION_MODULE} GET_ATTRIBUTION_DATA_ERROR`;

const POST_ATTRIBUTION_DATA = `${ATTRIBUTION_MODULE} POST_ATTRIBUTION_DATA`;
const POST_ATTRIBUTION_DATA_SUCCESS = `${ATTRIBUTION_MODULE} POST_ATTRIBUTION_DATA_SUCCESS`;
const POST_ATTRIBUTION_DATA_ERROR = `${ATTRIBUTION_MODULE} POST_ATTRIBUTION_DATA_ERROR`;

const PATCH_ATTRIBUTION_DATA = `${ATTRIBUTION_MODULE} PATCH_ATTRIBUTION_DATA`;
const PATCH_ATTRIBUTION_DATA_SUCCESS = `${ATTRIBUTION_MODULE} PATCH_ATTRIBUTION_DATA_SUCCESS`;
const PATCH_ATTRIBUTION_DATA_ERROR = `${ATTRIBUTION_MODULE} PATCH_ATTRIBUTION_DATA_ERROR`;

const GET_ATTRIBUTION_TAG = `${ATTRIBUTION_MODULE} GET_ATTRIBUTION_TAG`;

const GET_WALLET_INFO = `${PAYMENT_MODULE} GET_WALLET_INFO`;

const ADD_NEW_CARD = `${PAYMENT_MODULE} ADD_NEW_CARD`;
const ADD_NEW_CARD_TO_WALLET = `${PAYMENT_MODULE} ADD_NEW_CARD_TO_WALLET`;

const DELETE_CARD = `${PAYMENT_MODULE} DELETE_CARD`;

const ADD_FUNDS = `${PAYMENT_MODULE} ADD_FUNDS`;
const ADD_FUNDS_SUCCESS = `${PAYMENT_MODULE} ADD_FUNDS_SUCCESS`;
const ADD_FUNDS_ERROR = `${PAYMENT_MODULE} ADD_FUNDS_ERROR`;

const EXPORT_PDF_TRANSACTION = `${PAYMENT_MODULE} EXPORT_PDF_TRANSACTION`;

const GET_BRAND_TEAM = `${TEAM_MODULE} GET_BRAND_TEAM`;
const GET_BRAND_TEAM_SUCCESS = `${TEAM_MODULE} GET_BRAND_TEAM_SUCCESS`;
const GET_BRAND_TEAM_ERROR = `${TEAM_MODULE} GET_BRAND_TEAM_ERROR`;

const SEND_INVITATION = `${TEAM_MODULE} SEND_INVITATION`;
const SEND_INVITATION_SUCCESS = `${TEAM_MODULE} SEND_INVITATION_SUCCESS`;
const SEND_INVITATION_ERROR = `${TEAM_MODULE} SEND_INVITATION_ERROR`;

const GET_PENDING_INVITATIONS = `${TEAM_MODULE} GET_PENDING_INVITATIONS`;
const GET_PENDING_INVITATIONS_SUCCESS = `${TEAM_MODULE} GET_PENDING_INVITATIONS_SUCCESS`;
const GET_PENDING_INVITATIONS_ERROR = `${TEAM_MODULE} GET_PENDING_INVITATIONS_ERROR`;

const REMOVE_TEAM_MEMBER = `${TEAM_MODULE} REMOVE_TEAM_MEMBER`;
const REMOVE_TEAM_MEMBER_SUCCESS = `${TEAM_MODULE} REMOVE_TEAM_MEMBER_SUCCESS`;
const REMOVE_TEAM_MEMBER_ERROR = `${TEAM_MODULE} REMOVE_TEAM_MEMBER_ERROR`;

const GET_TEAM = `${TEAM_MODULE} GET_TEAM`;
const GET_TEAM_SUCCESS = `${TEAM_MODULE} GET_TEAM_SUCCESS`;
const GET_TEAM_ERROR = `${TEAM_MODULE} GET_TEAM_ERROR`;

const GET_CLIENTS = `${TEAM_MODULE} GET_CLIENTS`;
const GET_CLIENTS_SUCCESS = `${TEAM_MODULE} GET_CLIENTS_SUCCESS`;
const GET_CLIENTS_ERROR = `${TEAM_MODULE} GET_CLIENTS_ERROR`;

const PATCH_TEAM_USER = `${TEAM_MODULE} PATCH_TEAM_USER`;
const PATCH_TEAM_USER_SUCCESS = `${TEAM_MODULE} PATCH_TEAM_USER_SUCCESS`;
const PATCH_TEAM_USER_ERROR = `${TEAM_MODULE} PATCH_TEAM_USER_ERROR`;

const CANCEL_TEAM_USER = `${TEAM_MODULE} CANCEL_TEAM_USER`;
const CANCEL_TEAM_USER_SUCCESS = `${TEAM_MODULE} CANCEL_TEAM_USER_SUCCESS`;
const CANCEL_TEAM_USER_ERROR = `${TEAM_MODULE} CANCEL_TEAM_USER_ERROR`;

const RESEND_TEAM_USER = `${TEAM_MODULE} RESEND_TEAM_USER`;
const RESEND_TEAM_USER_SUCCESS = `${TEAM_MODULE} RESEND_TEAM_USER_SUCCESS`;
const RESEND_TEAM_USER_ERROR = `${TEAM_MODULE} RESEND_TEAM_USER_ERROR`;

const POST_DOCUMENT = `${DOCUMENTS_MODULE} POST_DOCUMENT`;
const POST_DOCUMENT_SUCCESS = `${DOCUMENTS_MODULE} POST_DOCUMENT_SUCCESS`;
const POST_DOCUMENT_ERROR = `${DOCUMENTS_MODULE} POST_DOCUMENT_ERROR`;

export const integrationsListActionTypes = getDataListActionTypes(INTEGRATIONS_MODULE);
export const attributionProfilesListTypes = getDataListActionTypes(ATTRIBUTION_PROFILES_MODULE);
export const attributionPublishersListTypes = getDataListActionTypes(ATTRIBUTION_PUBLISHERS_MODULE);
export const userWalletActionTypes = getDataListActionTypes(PAYMENT_MODULE);
export const walletTransactionsActionTypes = getDataListActionTypes(PAYMENT_TRANSACTIONS_MODULE);
export const attributionStoresListTypes = getDataListActionTypes(ATTRIBUTION_STORES_MODULE);
export const attributionAsinsListTypes = getDataListActionTypes(ATTRIBUTION_ASINS_MODULE);
export const docsListTypes = getDataListActionTypes(DOCUMENTS_MODULE);

export const brandSettingsActionTypes = {
  REVOKE_INTEGRATION,
  INIT_INTEGRATION,
  GET_ATTRIBUTION_DATA,
  GET_ATTRIBUTION_DATA_SUCCESS,
  GET_ATTRIBUTION_DATA_ERROR,
  POST_ATTRIBUTION_DATA,
  POST_ATTRIBUTION_DATA_SUCCESS,
  POST_ATTRIBUTION_DATA_ERROR,
  PATCH_ATTRIBUTION_DATA,
  PATCH_ATTRIBUTION_DATA_SUCCESS,
  PATCH_ATTRIBUTION_DATA_ERROR,
  GET_ATTRIBUTION_TAG,
  GET_BRAND,
  GET_BRAND_SUCCESS,
  GET_BRAND_ERROR,
  UPDATE_BRAND,
  UPDATE_BRAND_SUCCESS,
  UPDATE_BRAND_ERROR,
  GET_WALLET_INFO,
  ADD_NEW_CARD,
  ADD_NEW_CARD_TO_WALLET,
  DELETE_CARD,
  ADD_FUNDS,
  ADD_FUNDS_SUCCESS,
  ADD_FUNDS_ERROR,
  EXPORT_PDF_TRANSACTION,
  GET_BRAND_TEAM,
  GET_BRAND_TEAM_SUCCESS,
  GET_BRAND_TEAM_ERROR,
  SEND_INVITATION,
  SEND_INVITATION_SUCCESS,
  SEND_INVITATION_ERROR,
  REMOVE_TEAM_MEMBER,
  REMOVE_TEAM_MEMBER_SUCCESS,
  REMOVE_TEAM_MEMBER_ERROR,
  POST_DOCUMENT,
  POST_DOCUMENT_SUCCESS,
  POST_DOCUMENT_ERROR,
  GET_PENDING_INVITATIONS,
  GET_PENDING_INVITATIONS_SUCCESS,
  GET_PENDING_INVITATIONS_ERROR,
  GET_TEAM,
  GET_TEAM_SUCCESS,
  GET_TEAM_ERROR,
  GET_CLIENTS,
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_ERROR,
  PATCH_TEAM_USER,
  PATCH_TEAM_USER_SUCCESS,
  PATCH_TEAM_USER_ERROR,
  CANCEL_TEAM_USER,
  CANCEL_TEAM_USER_SUCCESS,
  CANCEL_TEAM_USER_ERROR,
  RESEND_TEAM_USER,
  RESEND_TEAM_USER_SUCCESS,
  RESEND_TEAM_USER_ERROR,
};
