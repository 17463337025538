import { getDataListActions } from 'common/store/actions/data-list';
import {
  campaignCreationActionTypes as T,
  campaignsActionTypes,
  descriptionsActionTypes,
  headlinesActionTypes,
  keywordsActionTypes,
  ordersActionTypes,
} from './action-types';

const getPrecalculatedForm = (form: CampaignCreationTypes.PrecalculatedFormBody) => ({
  type: T.GET_PRECALCULATED_FORM,
  payload: form,
});

const getPrecalculatedFormSuccess = (data: CampaignCreationTypes.PrecalculatedFormData) => ({
  type: T.GET_PRECALCULATED_FORM_SUCCESS,
  payload: data,
});

const getPrecalculatedFormError = (error: Error) => ({
  type: T.GET_PRECALCULATED_FORM_ERROR,
  payload: error,
});

const postCampaign = (data: CampaignCreationTypes.CampaignCreationBody, onSuccess: () => void) => ({
  type: T.POST_CAMPAIGN,
  payload: data,
  meta: { onSuccess },
});

const postCampaignSuccess = () => ({
  type: T.POST_CAMPAIGN_SUCCESS,
});

const postCampaignError = (error: Error) => ({
  type: T.POST_CAMPAIGN_ERROR,
  payload: error,
});

export const ordersActions = getDataListActions<CampaignCreationTypes.Order>(ordersActionTypes);
export const headlinesActions = getDataListActions<string>(headlinesActionTypes);
export const descriptionsActions = getDataListActions<string>(descriptionsActionTypes);
export const keywordsActions = getDataListActions<string>(keywordsActionTypes);
export const campaignsActions = getDataListActions<CampaignCreationTypes.Campaign>(campaignsActionTypes);

export const campaignCreationActions = {
  getPrecalculatedForm,
  getPrecalculatedFormSuccess,
  getPrecalculatedFormError,
  postCampaign,
  postCampaignSuccess,
  postCampaignError,
};
