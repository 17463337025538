import { getDataListActions } from 'common/store/actions/data-list';
import { getFiltersActions } from 'common/store/actions/filters';
import { EditableBrandFields } from 'common/types';
import { IGetQueryString } from 'common/ui/base-filters/types';
import { PerformanceFilterDataTypes } from 'modules/admin/constants';
import {
  adminBrandsListActionTypes,
  adminActionTypes as T,
  adminBrandsFiltersActionTypes,
  performanceTableActionTypes,
  pendingBrandsActionTypes,
  adminFiltersActionTypes,
  ordersFiltersActionTypes,
  ordersListActionTypes,
  performanceFiltersActionTypes,
  brandParentsActionTypes,
  brandNoteActionTypes,
  publishersActionTypes,
  adminsPageActionTypes,
  pubMaverickTableActionTypes,
  mavProductsActionTypes,
  mavProductsFiltersActionTypes,
  brandsProductsActionTypes,
} from './action-types';

const createBrand = (brand: EditableBrandFields, newBrand: string) => ({
  type: T.CREATE_BRAND,
  payload: { brand, newBrand },
});

const createBrandSuccess = () => ({
  type: T.CREATE_BRAND_SUCCESS,
});

const createBrandError = (error: Error) => ({
  type: T.CREATE_BRAND_ERROR,
  payload: error,
});

const editBrand = (brand: EditableBrandFields, id: number, newBrand?: string, isUpdatedAll?: boolean) => ({
  type: T.EDIT_BRAND,
  payload: { brand, newBrand, id, isUpdatedAll },
});

const editBrandSuccess = () => ({
  type: T.EDIT_BRAND_SUCCESS,
});

const editBrandError = (error: Error) => ({
  type: T.EDIT_BRAND_ERROR,
  payload: error,
});

const deleteBrand = (id: number) => ({
  type: T.DELETE_BRAND,
  payload: id,
});

const deleteBrandSuccess = () => ({
  type: T.DELETE_BRAND_SUCCESS,
});

const deleteBrandError = (error: Error) => ({
  type: T.DELETE_BRAND_ERROR,
  payload: error,
});

const updateBrandBalance = (
  walletId: number | null,
  brandId: number,
  value: number,
  desc: string,
  oldBalance: number
) => ({
  type: T.UPDATE_BRAND_BALANCE,
  payload: { walletId, value, desc, brandId, oldBalance },
});

const updateBrandBalanceSuccess = () => ({
  type: T.UPDATE_BRAND_BALANCE_SUCCESS,
});

const updateBrandBalanceError = (error: Error) => ({
  type: T.UPDATE_BRAND_BALANCE_ERROR,
  payload: error,
});

const getUnifiedReporting = (params: IGetQueryString) => ({
  type: T.GET_UNIFIED_REPORTING,
  payload: { params },
});

const getUnifiedReportingSuccess = (
  currentData: AdminTypes.UnifiedReportingData,
  previousPeriodData: AdminTypes.UnifiedReportingData
) => ({
  type: T.GET_UNIFIED_REPORTING_SUCCESS,
  payload: { currentData, previousPeriodData },
});

const getUnifiedReportingError = (error: Error) => ({
  type: T.GET_UNIFIED_REPORTING_ERROR,
  payload: error,
});

const generateIntegrationLink = (brandId: number, integrationType: string) => ({
  type: T.GENERATE_INTEGRATION_LINK,
  payload: { brandId, integrationType },
});

const generateIntegrationLinkSuccess = () => ({
  type: T.GENERATE_INTEGRATION_LINK_SUCCESS,
});

const generateIntegrationLinkError = () => ({
  type: T.GENERATE_INTEGRATION_LINK_ERROR,
});

const generateAttrTag = (publisherId: number, asin: string, url: string) => ({
  type: T.GENERATE_ATTR_TAG,
  payload: { publisherId, asin, url },
});

const generateAttrTagSuccess = () => ({
  type: T.GENERATE_ATTR_TAG_SUCCESS,
});

const generateAttrTagError = () => ({
  type: T.GENERATE_ATTR_TAG_ERROR,
});

const getOrderHistory = (orderId: string) => ({
  type: T.GET_ORDER_HISTORY,
  payload: orderId,
});

const getOrderHistorySuccess = (history: Array<AdminTypes.OrderHistoryEntry>) => ({
  type: T.GET_ORDER_HISTORY_SUCCESS,
  payload: history,
});

const getOrderHistoryError = (error: Error) => ({
  type: T.GET_ORDER_HISTORY_ERROR,
  payload: error,
});

const brandSearchValueChange = (payload: string | number) => ({
  type: T.BRAND_SEARCH_VALUE_CHANGE,
  payload,
});

const getBrandsExports = (params: IGetQueryString) => ({
  type: T.GET_BRANDS_EXPORTS,
  payload: { params },
});

const getBrandsExportsSuccess = (payload: string) => ({
  type: T.GET_BRANDS_EXPORTS_SUCCESS,
  payload,
});

const getBrandsExportsError = (error: Error) => ({
  type: T.GET_BRANDS_EXPORTS_ERROR,
  payload: error,
});

const getBrandsPerformanceExports = (params: IGetQueryString | PerformanceFilterDataTypes) => ({
  type: T.GET_BRANDS_PERFORMANCE_EXPORTS,
  payload: { params },
});

const getBrandsPerformanceExportSuccess = (payload: string) => ({
  type: T.GET_BRANDS_PERFORMANCE_EXPORTS_SUCCESS,
  payload,
});

const getBrandsPerformanceExportError = (error: Error) => ({
  type: T.GET_BRANDS_PERFORMANCE_EXPORTS_ERROR,
  payload: error,
});

const addBrandsParents = (
  payload: { name: string; brand: EditableBrandFields },
  onSuccess: (value: EditableBrandFields) => void,
  onRefresh: () => void
) => ({
  type: T.ADD_BRANDS_PARENTS,
  payload,
  meta: { onSuccess, onRefresh },
});

const patchBrandsParents = (payload: { name: string; id: number }, onRefresh: () => void) => ({
  type: T.PATCH_BRANDS_PARENTS,
  payload,
  meta: { onRefresh },
});

const deleteBrandsParents = (payload: string, onSuccess: () => void) => ({
  type: T.DELETE_BRANDS_PARENTS,
  payload,
  meta: { onSuccess },
});

const updateBrandAsinFee = (payload: { brandId: number; asinFee: number }) => ({
  type: T.UPDATE_BRAND_ASIN_FEE,
  payload,
});

const updateBrandAsinFeeSuccess = () => ({
  type: T.UPDATE_BRAND_ASIN_FEE_SUCCESS,
});

const updateBrandAsinFeeError = (error: Error) => ({
  type: T.UPDATE_BRAND_ASIN_FEE_ERROR,
  payload: error,
});

const getOrdersExports = (params: IGetQueryString) => ({
  type: T.GET_ORDERS_EXPORTS,
  payload: { params },
});

const getOrdersExportsSuccess = (payload: string) => ({
  type: T.GET_ORDERS_EXPORTS_SUCCESS,
  payload,
});

const getOrdersExportsError = (error: Error) => ({
  type: T.GET_ORDERS_EXPORTS_ERROR,
  payload: error,
});

const addBrandNote = (payload: { id: number; note: string }, onSuccess: () => void) => ({
  type: T.ADD_BRAND_NOTE,
  payload,
  meta: { onSuccess },
});

const addBrandNoteSuccess = () => ({
  type: T.ADD_BRAND_NOTE_SUCCESS,
});

const addBrandNoteError = (error: Error) => ({
  type: T.ADD_BRAND_NOTE_ERROR,
  payload: error,
});

const deleteBrandNote = (payload: { id: number; noteId: number }, onSuccess: () => void) => ({
  type: T.DELETE_BRAND_NOTE,
  payload,
  meta: { onSuccess },
});

const deleteBrandNoteSuccess = () => ({
  type: T.DELETE_BRAND_NOTE_SUCCESS,
});

const deleteBrandNoteError = (error: Error) => ({
  type: T.DELETE_BRAND_NOTE_ERROR,
  payload: error,
});

const editBrandNote = (payload: { id: number; noteId: number; note: string }, onSuccess: () => void) => ({
  type: T.EDIT_BRAND_NOTE,
  payload,
  meta: { onSuccess },
});

const editBrandNoteSuccess = () => ({
  type: T.EDIT_BRAND_NOTE_SUCCESS,
});

const editBrandNoteError = (error: Error) => ({
  type: T.EDIT_BRAND_NOTE_ERROR,
  payload: error,
});

const postCopyIntegration = (payload: { integrationID: number; brandId: number }) => ({
  type: T.POST_COPY_INTEGRATION,
  payload,
});

const postCopyIntegrationSuccess = () => ({
  type: T.POST_COPY_INTEGRATION_SUCCESS,
});

const postCopyIntegrationError = (error: Error) => ({
  type: T.POST_COPY_INTEGRATION_ERROR,
  payload: error,
});

const deleteAdminRole = (payload: string, onSuccess: () => void) => ({
  type: T.DELETE_ADMIN_ROLE,
  payload,
  meta: { onSuccess },
});

const deleteAdminRoleSuccess = () => ({
  type: T.DELETE_ADMIN_ROLE_SUCCESS,
});

const deleteAdminRoleError = (error: Error) => ({
  type: T.DELETE_ADMIN_ROLE_ERROR,
  payload: error,
});

const inviteAdminMember = (payload: string, onSuccess: () => void) => ({
  type: T.INVITE_ADMIN_MEMBER,
  payload,
  meta: { onSuccess },
});

const inviteAdminMemberSuccess = () => ({
  type: T.INVITE_ADMIN_MEMBER_SUCCESS,
});

const inviteAdminMemberError = (error: Error) => ({
  type: T.INVITE_ADMIN_MEMBER_ERROR,
  payload: error,
});

const postNewAdmin = (payload: number, onSuccess: () => void) => ({
  type: T.POST_NEW_ADMIN,
  payload,
  meta: { onSuccess },
});

const postNewAdminSuccess = () => ({
  type: T.POST_NEW_ADMIN_SUCCESS,
});

const postNewAdminError = (error: Error) => ({
  type: T.INVITE_ADMIN_MEMBER_ERROR,
  payload: error,
});

const getPubMavPerformanceExport = (params: IGetQueryString | PerformanceFilterDataTypes) => ({
  type: T.GET_PUB_MAV_PERFORMANCE_EXPORTS,
  payload: { params },
});

const getPubMavPerformanceExportSuccess = (payload: string) => ({
  type: T.GET_PUB_MAV_PERFORMANCE_EXPORTS_SUCCESS,
  payload,
});

const getPubMavPerformanceExportError = (error: Error) => ({
  type: T.GET_PUB_MAV_PERFORMANCE_EXPORTS_ERROR,
  payload: error,
});

const getInvoices = (payload: AdminTypes.InvoiceParams, onSuccess: (value: Array<AdminTypes.InvoiceData>) => void) => ({
  type: T.GET_INVOICES,
  payload,
  meta: { onSuccess },
});

const getInvoicesSuccess = (payload: string) => ({
  type: T.GET_INVOICES_SUCCESS,
  payload,
});

const getInvoicesError = (error: Error) => ({
  type: T.GET_INVOICES_ERROR,
  payload: error,
});

const createInvoice = (
  payload: AdminTypes.NewInvoice,
  onSuccess: (value: string) => void,
  onUpdated: (created: number, paid: boolean) => void
) => ({
  type: T.CREATE_INVOICE,
  payload,
  meta: { onSuccess, onUpdated },
});

const createInvoiceSuccess = (payload: string) => ({
  type: T.CREATE_INVOICE_SUCCESS,
  payload,
});

const createInvoiceError = (error: Error) => ({
  type: T.CREATE_INVOICE_ERROR,
  payload: error,
});

const getInvoiceById = (payload: number) => ({
  type: T.GET_INVOICE_BY_ID,
  payload,
});

const getInvoiceByIdSuccess = (payload: string) => ({
  type: T.GET_INVOICE_BY_ID_SUCCESS,
  payload,
});

const getInvoiceByIdError = (error: Error) => ({
  type: T.GET_INVOICE_BY_ID_ERROR,
  payload: error,
});

const chargeInvoice = (
  payload: { id: number; markPaid: boolean },
  onUpdated: (created: number, paid: boolean) => void
) => ({
  type: T.CHARGE_INVOICE,
  payload,
  meta: { onUpdated },
});

const chargeInvoiceSuccess = (payload: string) => ({
  type: T.CHARGE_INVOICE_SUCCESS,
  payload,
});

const chargeInvoiceError = (error: Error) => ({
  type: T.CHARGE_INVOICE_ERROR,
  payload: error,
});

const resetInvoiceData = () => ({
  type: T.RESET_INVOICE_DATA,
});

const mavProductsExports = (params: IGetQueryString) => ({
  type: T.MAV_PRODUCTS_EXPORTS,
  payload: { params },
});

const mavProductsExportsSuccess = (payload: string) => ({
  type: T.MAV_PRODUCTS_EXPORTS_SUCCESS,
  payload,
});

const mavProductsExportsError = (error: Error) => ({
  type: T.MAV_PRODUCTS_EXPORTS_ERROR,
  payload: error,
});

const getMavAsinsStats = (payload: IGetQueryString) => ({
  type: T.GET_MAV_ASINS_STATS,
  payload,
});

const getMavAsinsStatsSuccess = (payload: AdminTypes.MavStats) => ({
  type: T.GET_MAV_ASINS_STATS_SUCCESS,
  payload,
});

const getMavAsinsStatsError = (error: Error) => ({
  type: T.GET_MAV_ASINS_STATS_ERROR,
  payload: error,
});

const mavInternalProductsExports = (params: IGetQueryString) => ({
  type: T.MAV_INTERNAL_PRODUCTS_EXPORTS,
  payload: { params },
});

const mavInternalProductsExportsSuccess = () => ({
  type: T.MAV_INTERNAL_PRODUCTS_EXPORTS_SUCCESS,
});

const mavInternalProductsExportsError = (error: Error) => ({
  type: T.MAV_INTERNAL_PRODUCTS_EXPORTS_ERROR,
  payload: error,
});

const getMavPlusPerformanceSummary = (payload: IGetQueryString) => ({
  type: T.GET_MAV_PERFORMANCE_SUMMARY,
  payload,
});

const getMavPlusPerformanceSummarySuccess = (payload: AdminTypes.MavStatsSummary) => ({
  type: T.GET_MAV_PERFORMANCE_SUMMARY_SUCCESS,
  payload,
});

const getMavPlusPerformanceSummaryError = (error: Error) => ({
  type: T.GET_MAV_PERFORMANCE_SUMMARY_ERROR,
  payload: error,
});

const patchBrandsSettingsPeriods = (
  payload: { brandId: number; periodId: number; type: string; date: Date | string },
  onSuccess: () => void
) => ({
  type: T.PATCH_BRANDS_SETTINGS_PERIODS,
  payload,
  meta: { onSuccess },
});

const postBrandsSettingsPeriod = (payload: AdminTypes.BrandsSettingsPeriod, onSuccess: () => void) => ({
  type: T.POST_BRANDS_SETTINGS_PERIOD,
  payload,
  meta: { onSuccess },
});

const deleteBrandsSettingsPeriod = (payload: { brandId: number; periodId: number }, onSuccess: () => void) => ({
  type: T.DELETE_BRANDS_SETTINGS_PERIOD,
  payload,
  meta: { onSuccess },
});

const getBrandsPublishers = (payload: number) => ({
  type: T.GET_BRANDS_PUBLISHERS,
  payload,
});

const getBrandsPublishersSuccess = (payload: Array<{ publisher_id: number }>) => ({
  type: T.GET_BRANDS_PUBLISHERS_SUCCESS,
  payload,
});

const getBrandsPublishersError = (error: Error) => ({
  type: T.GET_BRANDS_PUBLISHERS_ERROR,
  payload: error,
});

const addBrandsPublishers = (payload: { id: number; publisher_id: number }) => ({
  type: T.ADD_BRANDS_PUBLISHERS,
  payload,
});

const addBrandsPublishersSuccess = () => ({
  type: T.ADD_BRANDS_PUBLISHERS_SUCCESS,
});

const addBrandsPublishersError = (error: Error) => ({
  type: T.ADD_BRANDS_PUBLISHERS_ERROR,
  payload: error,
});

const deleteBrandsPublishers = (payload: { id: number; publisher_id: number }) => ({
  type: T.DELETE_BRANDS_PUBLISHERS,
  payload,
});

const deleteBrandsPublishersSuccess = () => ({
  type: T.DELETE_BRANDS_PUBLISHERS_SUCCESS,
});

const deleteBrandsPublishersError = (error: Error) => ({
  type: T.DELETE_BRANDS_PUBLISHERS_ERROR,
  payload: error,
});

const switchAllBrandProductsMavEligibleStatus = (
  payload: {
    id: number;
    isMavEligible: boolean;
    products_brands_ids?: number[];
    affectAssociatedProducts?: boolean;
  },
  onSuccess?: () => void,
  onError?: () => void
) => ({
  type: T.SWITCH_BRAND_PRODUCTS_MAV_ELIGIBLE_STATUS,
  payload,
  meta: {
    onSuccess,
    onError,
  },
});

export const performanceTableActions = getDataListActions(performanceTableActionTypes);
export const adminBrandsListActions = getDataListActions(adminBrandsListActionTypes);
export const adminBrandsFiltersActions = getFiltersActions(adminBrandsFiltersActionTypes);
export const pendingBrandsActions = getDataListActions(pendingBrandsActionTypes);
export const adminFiltersActions = getFiltersActions(adminFiltersActionTypes);
export const ordersFiltersActions = getFiltersActions(ordersFiltersActionTypes);
export const ordersListActions = getDataListActions(ordersListActionTypes);
export const performanceFiltersActions = getFiltersActions(performanceFiltersActionTypes);
export const brandParentsActions = getDataListActions(brandParentsActionTypes);
export const brandNoteActions = getDataListActions(brandNoteActionTypes);
export const publishersActions = getDataListActions(publishersActionTypes);
export const adminsPageActions = getDataListActions(adminsPageActionTypes);
export const pubMaverickTableAction = getDataListActions(pubMaverickTableActionTypes);
export const mavProductsActions = getDataListActions(mavProductsActionTypes);
export const mavProductsFiltersActions = getFiltersActions(mavProductsFiltersActionTypes);
export const brandsProductsActions = getDataListActions(brandsProductsActionTypes);

export const adminActions = {
  createBrand,
  createBrandSuccess,
  createBrandError,
  editBrand,
  editBrandSuccess,
  editBrandError,
  deleteBrand,
  deleteBrandSuccess,
  deleteBrandError,
  generateIntegrationLink,
  updateBrandBalance,
  updateBrandBalanceSuccess,
  updateBrandBalanceError,
  getUnifiedReporting,
  getUnifiedReportingSuccess,
  getUnifiedReportingError,
  getOrderHistory,
  getOrderHistorySuccess,
  getOrderHistoryError,
  brandSearchValueChange,
  getBrandsExports,
  getBrandsExportsSuccess,
  getBrandsExportsError,
  getBrandsPerformanceExports,
  getBrandsPerformanceExportSuccess,
  getBrandsPerformanceExportError,
  addBrandsParents,
  patchBrandsParents,
  deleteBrandsParents,
  updateBrandAsinFee,
  updateBrandAsinFeeSuccess,
  updateBrandAsinFeeError,
  getOrdersExports,
  getOrdersExportsSuccess,
  getOrdersExportsError,
  addBrandNote,
  editBrandNote,
  editBrandNoteSuccess,
  editBrandNoteError,
  deleteBrandNote,
  addBrandNoteSuccess,
  addBrandNoteError,
  deleteBrandNoteSuccess,
  deleteBrandNoteError,
  postCopyIntegration,
  postCopyIntegrationSuccess,
  postCopyIntegrationError,
  generateAttrTag,
  generateIntegrationLinkSuccess,
  generateIntegrationLinkError,
  generateAttrTagSuccess,
  generateAttrTagError,
  deleteAdminRole,
  deleteAdminRoleSuccess,
  deleteAdminRoleError,
  inviteAdminMember,
  inviteAdminMemberSuccess,
  inviteAdminMemberError,
  postNewAdmin,
  postNewAdminSuccess,
  postNewAdminError,
  getPubMavPerformanceExport,
  getPubMavPerformanceExportSuccess,
  getPubMavPerformanceExportError,
  getInvoices,
  getInvoicesSuccess,
  getInvoicesError,
  createInvoice,
  createInvoiceSuccess,
  createInvoiceError,
  getInvoiceById,
  getInvoiceByIdSuccess,
  getInvoiceByIdError,
  chargeInvoice,
  chargeInvoiceSuccess,
  chargeInvoiceError,
  resetInvoiceData,
  mavProductsExports,
  mavProductsExportsSuccess,
  mavProductsExportsError,
  getMavAsinsStats,
  getMavAsinsStatsSuccess,
  getMavAsinsStatsError,
  mavInternalProductsExports,
  mavInternalProductsExportsSuccess,
  mavInternalProductsExportsError,
  patchBrandsSettingsPeriods,
  postBrandsSettingsPeriod,
  deleteBrandsSettingsPeriod,
  getBrandsPublishers,
  getBrandsPublishersSuccess,
  getBrandsPublishersError,
  addBrandsPublishers,
  addBrandsPublishersSuccess,
  addBrandsPublishersError,
  deleteBrandsPublishers,
  deleteBrandsPublishersSuccess,
  deleteBrandsPublishersError,
  switchAllBrandProductsMavEligibleStatus,
  getMavPlusPerformanceSummary,
  getMavPlusPerformanceSummarySuccess,
  getMavPlusPerformanceSummaryError,
};
