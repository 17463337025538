import { getDataListActionTypes } from 'common/store/actions/data-list';

const ATTRIBUTION_PERFORMANCE_MODULE = '[attribution-performance]';
const ATTRIBUTION_PERFORMANCE_ORDERS_MODULE = '[attribution-performance:orders]';
const ATTRIBUTION_PERFORMANCE_AD_GROUPS_MODULE = '[attribution-performance:ad-groups]';
const ATTRIBUTION_PERFORMANCE_ORDER_MODULE = '[attribution-performance:order-export]';

const GET_ATTRIBUTION_PERFORMANCE = `${ATTRIBUTION_PERFORMANCE_MODULE} GET_ATTRIBUTION_PERFORMANCE`;
const GET_ATTRIBUTION_PERFORMANCE_SUCCESS = `${ATTRIBUTION_PERFORMANCE_MODULE} GET_ATTRIBUTION_PERFORMANCE_SUCCESS`;
const GET_ATTRIBUTION_PERFORMANCE_ERROR = `${ATTRIBUTION_PERFORMANCE_MODULE} GET_ATTRIBUTION_PERFORMANCE_ERROR`;

const GET_ATTRIBUTION_PERFORMANCE_ORDER_EXPORT = `${ATTRIBUTION_PERFORMANCE_ORDER_MODULE} GET_ATTRIBUTION_PERFORMANCE_ORDER_EXPORT`;
const GET_ATTRIBUTION_PERFORMANCE_ORDER_EXPORT_SUCCESS = `${ATTRIBUTION_PERFORMANCE_ORDER_MODULE} GET_ATTRIBUTION_PERFORMANCE_ORDER_EXPORT_SUCCESS`;
const GET_ATTRIBUTION_PERFORMANCE_ORDER_EXPORT_ERROR = `${ATTRIBUTION_PERFORMANCE_ORDER_MODULE} GET_ATTRIBUTION_PERFORMANCE_ORDER_EXPORT_ERROR`;

const CHANGE_KEYWORD_INFO = `${ATTRIBUTION_PERFORMANCE_MODULE} CHANGE_KEYWORD_INFO`;
const CHANGE_CPC_SUCCESS = `${ATTRIBUTION_PERFORMANCE_MODULE} CHANGE_CPC_SUCCESS`;
const CHANGE_CPC_ERROR = `${ATTRIBUTION_PERFORMANCE_MODULE} CHANGE_CPC_ERROR`;

const CHANGE_CAMPAIGN_STATUS = `${ATTRIBUTION_PERFORMANCE_MODULE} CHANGE_CAMPAIGN_STATUS`;
const CHANGE_CAMPAIGN_STATUS_SUCCESS = `${ATTRIBUTION_PERFORMANCE_MODULE} CHANGE_CAMPAIGN_STATUS_SUCCESS`;
const CHANGE_CAMPAIGN_STATUS_ERROR = `${ATTRIBUTION_PERFORMANCE_MODULE} CHANGE_CAMPAIGN_STATUS_ERROR`;

const CHANGE_ADGROUP_STATUS = `${ATTRIBUTION_PERFORMANCE_MODULE} CHANGE_ADGROUP_STATUS`;
const CHANGE_ADGROUP_STATUS_SUCCESS = `${ATTRIBUTION_PERFORMANCE_MODULE} CHANGE_ADGROUP_STATUS_SUCCESS`;
const CHANGE_ADGROUP_STATUS_ERROR = `${ATTRIBUTION_PERFORMANCE_MODULE} CHANGE_ADGROUP_STATUS_ERROR`;

export const attributionPerformanceOrdersActionTypes = getDataListActionTypes(ATTRIBUTION_PERFORMANCE_ORDERS_MODULE);
export const attributionAdGroupsActionTypes = getDataListActionTypes(ATTRIBUTION_PERFORMANCE_AD_GROUPS_MODULE);

export const attributionPerformanceActionTypes = {
  GET_ATTRIBUTION_PERFORMANCE,
  GET_ATTRIBUTION_PERFORMANCE_SUCCESS,
  GET_ATTRIBUTION_PERFORMANCE_ERROR,
  GET_ATTRIBUTION_PERFORMANCE_ORDER_EXPORT,
  GET_ATTRIBUTION_PERFORMANCE_ORDER_EXPORT_SUCCESS,
  GET_ATTRIBUTION_PERFORMANCE_ORDER_EXPORT_ERROR,
  CHANGE_KEYWORD_INFO,
  CHANGE_CPC_SUCCESS,
  CHANGE_CPC_ERROR,
  CHANGE_CAMPAIGN_STATUS,
  CHANGE_CAMPAIGN_STATUS_SUCCESS,
  CHANGE_CAMPAIGN_STATUS_ERROR,
  CHANGE_ADGROUP_STATUS,
  CHANGE_ADGROUP_STATUS_SUCCESS,
  CHANGE_ADGROUP_STATUS_ERROR,
};
