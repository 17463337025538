import { Action } from 'common/interfaces/root';
import { RESET_REDUCER } from 'common/store/actions';
import { brandSettingsActionTypes as T } from '../actions';

const initState: BrandSettingsTypes.BrandStore = {
  loading: false,
  error: null,
  data: null,
};

export default (
  state: BrandSettingsTypes.BrandStore = initState,
  { type, payload }: Action
): BrandSettingsTypes.BrandStore => {
  switch (type) {
    case T.GET_BRAND:
      return { ...state, loading: true };

    case T.GET_BRAND_SUCCESS:
      return { ...state, loading: false, data: payload };

    case T.GET_BRAND_ERROR:
      return { ...state, loading: false, error: payload };

    case RESET_REDUCER:
      return { ...initState };

    default:
      return state;
  }
};
