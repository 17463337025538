import { getDataListActions } from 'common/store/actions/data-list';
import { getFiltersActions } from 'common/store/actions/filters';
import {
  productsActionTypes as T,
  productsFiltersActionTypes,
  productsForOrdersActionTypes,
  productsListActionTypes,
} from './action-types';

const importProducts = (asins: Array<string>, brandId: number, ad_channel: string) => ({
  type: T.IMPORT_PRODUCTS,
  payload: { asins, brandId, ad_channel },
});

const exportProducts = (brandID: number, execInstantly: boolean) => ({
  type: T.EXPORT_PRODUCTS,
  payload: { brandID, execInstantly },
});

const exportProductsSuccess = (link: string) => ({
  type: T.EXPORT_SUCCESS,
  payload: link,
});

const exportProductsError = (error: Error) => ({
  type: T.EXPORT_ERROR,
  payload: error,
});

const deleteProduct = (productId: number, brandId: number) => ({
  type: T.DELETE_PRODUCT,
  payload: { productId, brandId },
});

const deleteProductSuccess = () => ({
  type: T.DELETE_PRODUCT_SUCCESS,
});

const deleteProductError = (error: Error) => ({
  type: T.DELETE_PRODUCT_ERROR,
  payload: error,
});

const changeCategory = (asin: string, brandId: number, category: string, shouldPatch: boolean) => ({
  type: T.CHANGE_CATEGORY,
  payload: { asin, category, brandId },
  meta: { shouldPatch },
});

const changeCategorySuccess = () => ({
  type: T.CHANGE_CATEGORY_SUCCESS,
});

const changeCategoryError = (error: Error) => ({
  type: T.CHANGE_CATEGORY_ERROR,
  payload: error,
});

const changeTargetAcos = (asin: string, brandId: number, targetAcos: number) => ({
  type: T.CHANGE_TARGET_ACOS,
  payload: { asin, targetAcos, brandId },
});

const changeTargetAcosSuccess = () => ({
  type: T.CHANGE_TARGET_ACOS_SUCCESS,
});

const changeTargetAcosError = (error: Error) => ({
  type: T.CHANGE_TARGET_ACOS_ERROR,
  payload: error,
});

const changeMavPercent = (asin: string, brandId: number, mavPercent: number) => ({
  type: T.CHANGE_MAV_PERCENT,
  payload: { asin, mavPercent, brandId },
});

const changeMavPercentSuccess = () => ({
  type: T.CHANGE_MAV_PERCENT_SUCCESS,
});

const changeMavPercentError = (error: Error) => ({
  type: T.CHANGE_MAV_PERCENT_ERROR,
  payload: error,
});

const updateAdChannel = (
  payload: { brandId: number; id: number; adChannel: string; affectAssociatedProducts?: boolean },
  onSuccess: () => void,
  onError: () => void
) => ({
  type: T.UPDATE_AD_CHANNEL,
  payload,
  meta: { onSuccess, onError },
});

const updateAdChannelSuccess = () => ({
  type: T.UPDATE_AD_CHANNEL_SUCCESS,
});

const updateAdChannelError = (error: Error) => ({
  type: T.UPDATE_AD_CHANNEL_ERROR,
  payload: error,
});

const deleteProducts = (
  payload: { brandId: number; products_ids?: number[]; brands_ids?: number[] },
  onSuccess: () => void,
  onError: () => void
) => ({
  type: T.DELETE_PRODUCTS,
  payload,
  meta: { onSuccess, onError },
});

export const productsFiltersActions = {
  ...getFiltersActions(productsFiltersActionTypes),
  getProductsBrands: (payload: number | string) => ({
    type: productsFiltersActionTypes.GET_BRAND_PRODUCTS_BRANDS,
    payload,
  }),
  setProductsBrands: (payload: number | string) => ({
    type: productsFiltersActionTypes.SET_BRAND_PRODUCTS_BRANDS,
    payload,
  }),
  updateBrandsIds: (payload: { ids: number[] }) => {
    return {
      type: productsFiltersActionTypes.SET_BRANDS_IDS,
      payload,
    };
  },
  applyFilters: () => ({
    type: productsFiltersActionTypes.APPLY_FILTERS,
  }),
};
export const productsListActions = getDataListActions<Products.Product>(productsListActionTypes);
export const productsListForOrderActions = getDataListActions<Products.Product>(productsForOrdersActionTypes);

export const productsActions = {
  importProducts,
  exportProducts,
  exportProductsSuccess,
  exportProductsError,
  deleteProduct,
  deleteProductSuccess,
  deleteProductError,
  changeCategory,
  changeCategorySuccess,
  changeCategoryError,
  changeTargetAcos,
  changeTargetAcosSuccess,
  changeTargetAcosError,
  changeMavPercent,
  changeMavPercentSuccess,
  changeMavPercentError,
  updateAdChannel,
  updateAdChannelSuccess,
  updateAdChannelError,
  deleteProducts,
};
