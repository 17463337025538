import { State } from 'common/store/reducers';

export const selectIntegrations = (state: State) => state.brandSettings.integrations.data;

export const selectIntegrationsLoading = (state: State) => state.brandSettings.integrations.loading;

export const selectBrand = (state: State) => state.brandSettings.brand.data;

export const selectBrandLoading = (state: State) => state.brandSettings.brand.loading;

export const selectAttributionData = (state: State) => state.brandSettings.attribution.data;

export const selectAttributionDataLoading = (state: State) => state.brandSettings.attribution.loading;

export const selectAttributionProfiles = (state: State) => state.brandSettings.attributionProfiles.data;

export const selectAttributionPublishers = (state: State) => state.brandSettings.attributionPublishers.data;

export const selectAttributionProfilesLoading = (state: State) => state.brandSettings.attributionProfiles.loading;

export const selectAttributionPublishersLoading = (state: State) => state.brandSettings.attributionPublishers.loading;

export const selectPaymentCards = (state: State) => state.brandSettings.userWallet.data;

export const selectPaymentId = (state: State) => state.brandSettings.userWallet.amount;

export const selectPaymentCardsLoading = (state: State) => state.brandSettings.userWallet.loading;

export const selectWalletTransactions = (state: State) => state.brandSettings.walletTransactions.data;

export const selectWalletTransactionsLoading = (state: State) => state.brandSettings.walletTransactions.loading;

export const selectWalletTransactionsAmount = (state: State) => state.brandSettings.walletTransactions.amount;

export const selectWalletTransactionsPage = (state: State) => state.brandSettings.walletTransactions.page;

export const selectWalletTransactionsLimit = (state: State) => state.brandSettings.walletTransactions.limit;

export const selectWalletInfo = (state: State) => state.brandSettings.wallet;

export const selectAttributionStoresLoading = (state: State) => state.brandSettings.attributionStores.loading;

export const selectAttributionStores = (state: State) => state.brandSettings.attributionStores.data;

export const selectAsins = (state: State) => state.brandSettings.asins.data;

export const selectAsinsLoading = (state: State) => state.brandSettings.asins.loading;

export const selectBrandTeamInfo = (state: State) => state.brandSettings.team.data;

export const selectBrandTeamLoading = (state: State) => state.brandSettings.team.loading;

export const selectDocuments = (state: State) => state.brandSettings.documents.data;

export const selectDocumentsLoading = (state: State) => state.brandSettings.documents.loading;

export const selectPendingInvitations = (state: State) => state.brandSettings.pendingInvitations.data;

export const selectPendingInvitationsLoading = (state: State) => state.brandSettings.pendingInvitations.loading;

export const selectTeamInfo = (state: State) => state.brandSettings.newTeam.data;

export const selectTeamLoading = (state: State) => state.brandSettings.newTeam.loading;

export const selectTeamClientsInfo = (state: State) => state.brandSettings.newTeam.clientsData;

export const selectTeamClientsLoading = (state: State) => state.brandSettings.newTeam.loadingClients;
