import { getDataListReducer } from 'common/store/reducers/data-list';
import { getFiltersReducer } from 'common/store/reducers/filters';
import { Brand } from 'common/types';
import { AdminMavTableSortField, OrdersTableSortField, PerformanceTableSortField } from 'modules/admin/constants';
import { combineReducers } from 'redux';
import { Order } from 'common/ui/create-order-modal/types';
import unifiedReporting from './unified-reporting';
import exportReducer from './export';
import orderExportReducer from './order-export';
import performanceExportReducer from './performance-export';
import pubMavPerformanceExportReducer from './pub-mav-performance-export';
import brandSearchValueReducer from './brand-search-value';
import {
  adminBrandsFiltersActionTypes,
  adminBrandsListActionTypes,
  adminFiltersActionTypes,
  adminsPageActionTypes,
  brandNoteActionTypes,
  brandParentsActionTypes,
  brandsProductsActionTypes,
  mavProductsActionTypes,
  mavProductsFiltersActionTypes,
  ordersFiltersActionTypes,
  ordersListActionTypes,
  pendingBrandsActionTypes,
  performanceFiltersActionTypes,
  performanceTableActionTypes,
  publishersActionTypes,
  pubMaverickTableActionTypes,
} from '../actions';
import orderHistory from './order-history';
import connections from './connections';
import invoicesReducer from './invoices';
import mavProductsExportReducer from './mav-products-export';
import mavPlusStatsReducer from './mav-plus-stats';
import pubSelectReducer from './publisher-select';
import mavPubPerformanceSummary from './mav-stats-summary';

export const adminReducer = combineReducers({
  export: exportReducer,
  mavPlusStats: mavPlusStatsReducer,
  orderExport: orderExportReducer,
  invoices: invoicesReducer,
  performanceExport: performanceExportReducer,
  pubMavPerformanceExport: pubMavPerformanceExportReducer,
  mavProductsExport: mavProductsExportReducer,
  mavProductsFilters: getFiltersReducer(mavProductsFiltersActionTypes, {
    sort: { field: AdminMavTableSortField.price, direction: 'desc' },
  }),
  mavProducts: getDataListReducer<AdminTypes.MavProduct>(mavProductsActionTypes, { limit: 10, skipGlobalReset: true }),
  brandSearchValue: brandSearchValueReducer,
  brandsList: getDataListReducer<Brand>(adminBrandsListActionTypes, {
    limit: 500,
    skipGlobalReset: true,
  }),
  brandNotes: getDataListReducer<AdminTypes.BrandNote>(brandNoteActionTypes, { skipGlobalReset: true }),
  brandsFilters: getFiltersReducer(adminBrandsFiltersActionTypes),
  unifiedReporting,
  performanceTable: getDataListReducer<AdminTypes.PerformanceBrand>(performanceTableActionTypes, {
    limit: 100,
    skipGlobalReset: true,
  }),
  performanceFilters: getFiltersReducer(performanceFiltersActionTypes, {
    sort: { field: PerformanceTableSortField.total_sales14d, direction: 'desc' },
  }),
  pendingBrands: getDataListReducer<AdminTypes.Brand>(pendingBrandsActionTypes, { skipGlobalReset: true }),
  adminFilters: getFiltersReducer(adminFiltersActionTypes),
  ordersFilters: getFiltersReducer(ordersFiltersActionTypes, {
    sort: { field: OrdersTableSortField.updated_at, direction: 'desc' },
  }),
  orders: getDataListReducer<Order>(ordersListActionTypes, { limit: 100 }),
  orderHistory,
  brandParents: getDataListReducer<AdminTypes.BrandParent>(brandParentsActionTypes, {
    skipGlobalReset: true,
  }),
  publishers: getDataListReducer<AdminTypes.Publisher>(publishersActionTypes),
  connections,
  adminUsers: getDataListReducer<AdminTypes.AdminUser>(adminsPageActionTypes),
  pubMaverickTable: getDataListReducer<AdminTypes.PubMaverickPerformance>(pubMaverickTableActionTypes, {
    limit: 100,
    skipGlobalReset: true,
  }),
  brandsPeriods: getDataListReducer<AdminTypes.BrandsPeriodsItem>(brandsProductsActionTypes),
  publisherSelect: pubSelectReducer,
  pubMavPerformanceSummary: mavPubPerformanceSummary,
});
