/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, Typography } from '@mui/material';
import { MENU_WIDTH_SM, MENU_WIDTH_XL } from 'common/constants/sizes';
import { theme } from 'common/constants/theme';
import { Integration, integrationsApi } from 'common/services/api/integrations-api.service';
import { selectGlobalFilters } from 'common/store/selectors';
import { Portal } from 'common/ui/portal';
import { Styles } from 'common/types';
import { ReactElement, useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import { AmazonLogin } from './amazon-login';
import { LoadingContainer } from './loading-container';
import { Menu } from './menu';
import { MenuCtx, menuCtx } from './menu/menu-ctx';

const disabledAmazonOAuthPages = [
  '/brand/general',
  '/brand/attribution',
  '/brand/integrations',
  '/admin/brands',
  '/admin/integrations',
  '/admin/overview',
  '/admin/performance',
  '/admin/mav-products',
  '/brand/payment',
  '/brand/team',
  '/user/settings',
  '/brand/documents',
  '/brand/contacts',
  '/admin/orders',
  '/admin/admin-users',
  '/campaign-creation',
  '/admin/mav',
  '/brand-rank',
];

interface StylesProps {
  menuOpen: boolean;
}

const getStyles = ({ menuOpen }: StylesProps): Styles => ({
  container: { display: 'flex', height: '100%', backgroundColor: theme.palette.background.default },
  headerContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    boxSizing: 'border-box',
    paddingLeft: menuOpen ? `${MENU_WIDTH_XL}px` : `${MENU_WIDTH_SM}px`,
    transition: 'padding-left 0.225s ease-in-out',
    width: '100%',
  },
  contentContainer: { minHeight: 'calc(100vh)', padding: '0px 40px 30px 40px', boxSizing: 'border-box' },
  contentHeaderContainer: { padding: '30px 40px', boxSizing: 'border-box' },
  loadingContainer: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  reconnectBanner: {
    backgroundColor: '#ffffff',
    width: '100%',
    borderRadius: '10px',
    minHeight: '80px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    padding: '0 20px',
    boxSizing: 'border-box',
    color: '#4288F0',
    justifyContent: 'space-between',
    mt: '20px',
  },
  reconnectText: {
    mr: 2,
  },
});

export function Layout(): ReactElement {
  const location = useLocation();

  const topAnchorRef = useRef<HTMLDivElement>();

  const [integrationsLoading, setIntegrationsLoading] = useState<boolean>(true);
  const [forceAmazonAuth, setForceAmazonAuth] = useState<boolean>(false);
  const [isAmazonNotIntegrated, setIsAmazonNotIntegrated] = useState<boolean>(false);
  const [forceAmazonAuthBuffer, setForceAmazonAuthBuffer] = useState<boolean>(false);
  const [forceChecked, setForceChecked] = useState<boolean>(false);
  const [amazonAuthLink, setAmazonAuthLink] = useState<string>('');

  const { open } = useContext(menuCtx);

  const styles = getStyles({ menuOpen: open });

  const globalFilters = useSelector(selectGlobalFilters);

  useEffect(() => {
    if (!topAnchorRef.current) return;

    setTimeout(() => {
      topAnchorRef.current.scrollIntoView({ behavior: 'smooth' });
    });
  }, [topAnchorRef, location.pathname]);

  useEffect(() => {
    setForceAmazonAuth(false);
    setForceAmazonAuthBuffer(false);
    setForceChecked(false);
  }, [globalFilters.brand_id]);

  useEffect(() => {
    const skipForcedAuth = disabledAmazonOAuthPages.find(page => page === location.pathname);

    if (skipForcedAuth) {
      setIntegrationsLoading(false);
      setForceAmazonAuth(false);
      setIsAmazonNotIntegrated(false);
      return;
    }

    if (!globalFilters.brand_id || forceChecked) {
      setForceAmazonAuth(forceAmazonAuthBuffer);
      return;
    }

    const getIntegrations = async () => {
      setIntegrationsLoading(true);

      const { data: integrationsData } = await integrationsApi.getIntegrations(globalFilters.brand_id);

      const foundAmazonIntegration = integrationsData.integrations.find(
        (i: Integration) => ['amazonads', 'perpetua'].includes(i.type) && i.status === 'active'
      );

      const amazonIntegrations = integrationsData.integrations.find((i: Integration) =>
        ['amazonads', 'perpetua'].includes(i.type)
      );

      if (!foundAmazonIntegration) {
        const { data: initializedIntegrationData } = await integrationsApi.initIntegration(
          globalFilters.brand_id,
          'amazonads',
          window.location.href
        );
        setForceAmazonAuth(true);
        setForceAmazonAuthBuffer(true);
        setAmazonAuthLink(initializedIntegrationData.link);
      } else {
        if (!amazonIntegrations) {
          const { data: initializedIntegrationData } = await integrationsApi.initIntegration(
            globalFilters.brand_id,
            'amazonads',
            window.location.href
          );
          setIsAmazonNotIntegrated(true);
          setAmazonAuthLink(initializedIntegrationData.link);
        }
        setForceAmazonAuth(false);
        setForceAmazonAuthBuffer(false);
      }

      setIntegrationsLoading(false);
      setForceChecked(true);
    };

    getIntegrations();
  }, [forceAmazonAuthBuffer, forceChecked, globalFilters.brand_id, location.pathname]);

  if (integrationsLoading) return <LoadingContainer type="fullscreen" />;

  return (
    <Box sx={styles.container}>
      <Menu />
      <Box sx={styles.headerContentContainer}>
        {isAmazonNotIntegrated ? (
          <AmazonLogin link={amazonAuthLink} />
        ) : (
          <>
            <Box sx={styles.contentHeaderContainer}>
              <div id="page-header" />
              {forceAmazonAuth && (
                <Box sx={styles.reconnectBanner}>
                  <Typography sx={styles.reconnectText}>
                    Please re-connect your Amazon Account so that we can continue to retrieve your most up to date
                    stats.
                  </Typography>
                  <Button variant="contained" onClick={() => window.open(amazonAuthLink)}>
                    Reconnect
                  </Button>
                </Box>
              )}
            </Box>
            <Box sx={styles.contentContainer}>
              <Outlet />
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
}
