import { SagaIterator } from '@redux-saga/types';
import { Action } from 'common/interfaces/root';
import { selectGlobalFilters } from 'common/store/selectors';
import { productsApi } from 'modules/products/services/products.service';
import toast from 'react-hot-toast';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { checkExportStatus } from 'modules/admin/store/sagas';
import {
  productsActions,
  productsActionTypes as T,
  productsFiltersActions,
  productsFiltersActionTypes,
  productsForOrdersActionTypes,
  productsListActions,
  productsListActionTypes,
  productsListForOrderActions,
} from '../actions';
import { selectProductsFilters, selectProductsLimitPageAmount } from '../selectors';

function* getProducts({ payload }: Action) {
  try {
    const { data } = yield call(productsApi.getProducts, payload);
    yield put(productsListActions.getSuccess(data.products, data.products_count));
  } catch (err) {
    yield put(productsListActions.getError(err as Error));
  }
}

function* getProductsForOrders({ payload }: Action) {
  try {
    const { data } = yield call(productsApi.getProducts, payload);
    yield put(productsListForOrderActions.getSuccess(data.products, data.products_count));
  } catch (err) {
    yield put(productsListForOrderActions.getError(err as Error));
  }
}

function* refreshProducts() {
  const { page, limit } = yield select(selectProductsLimitPageAmount);
  const { search } = yield select(selectProductsFilters);
  const { brand_id } = yield select(selectGlobalFilters);
  yield put(productsListActions.get({ limit, offset: limit * page, brand_id, search }));
}

function* importProducts({ payload }: Action) {
  try {
    yield call(productsApi.importProducts, payload.asins, payload.brandId, payload.ad_channel);
    toast.success('Products imported succesfully!');
    yield call(refreshProducts);
  } catch (err) {
    toast.error('Products import failed');
  }
}

function* exportProducts({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(productsApi.exportProducts, payload.brandID, payload.execInstantly);
    const link = yield call(checkExportStatus, data.job_id);

    window.open(link);

    yield put(productsActions.exportProductsSuccess(link));
  } catch (err) {
    yield put(productsActions.exportProductsError(err as Error));
    toast.error('Export failed');
  }
}

function* deleteProduct({ payload }: Action) {
  try {
    yield call(productsApi.deleteProduct, payload.productId, payload.brandId);
    yield put(productsActions.deleteProductSuccess());
    toast.success('Product deleted succesfully!');
    yield call(refreshProducts);
  } catch (err) {
    yield put(productsActions.deleteProductError(err as Error));
    toast.error('Error while deleting product!');
  }
}

function* changeCategory({ payload, meta }: Action) {
  try {
    yield call(
      meta.shouldPatch ? productsApi.patchCategory : productsApi.postCategory,
      payload.asin,
      payload.brandId,
      payload.category
    );
    yield put(productsActions.changeCategorySuccess());
    toast.success('Product category changed succesfully!');
    yield call(refreshProducts);
  } catch (err) {
    yield put(productsActions.changeCategoryError(err as Error));
    toast.error('Error while changing product category!');
  }
}

function* changeTargetAcos({ payload }: Action) {
  try {
    yield call(productsApi.patchTargetAcos, payload.asin, payload.brandId, payload.targetAcos);
    yield put(productsActions.changeTargetAcosSuccess());
    toast.success('Product target ACOS changed succesfully!');
    yield call(refreshProducts);
  } catch (err) {
    yield put(productsActions.changeTargetAcosError(err as Error));
    toast.error('Error while changing product target ACOS!');
  }
}

function* changeMavPercent({ payload }: Action) {
  try {
    yield call(productsApi.patchMavPercent, payload.asin, payload.brandId, payload.mavPercent);
    yield put(productsActions.changeMavPercentSuccess());
    toast.success('Product mav percent changed succesfully!');
    yield call(refreshProducts);
  } catch (err) {
    yield put(productsActions.changeMavPercentError(err as Error));
    toast.error('Error while changing product mav percent!');
  }
}

function* updateAdChannel({ payload, meta }: Action) {
  try {
    yield call(productsApi.patchAdChannel, payload);
    yield put(productsActions.updateAdChannelSuccess());
    meta.onSuccess();
  } catch (err) {
    yield put(productsActions.updateAdChannelError(err as Error));
    toast.error('Brand not marked as Mav+ Eligible');
    meta.onError();
  }
}

function* getProductsBrands({ payload }: Action) {
  try {
    const { data } = yield call(productsApi.getProductsBrands, payload);
    yield put(productsFiltersActions.setProductsBrands(data.brands));
  } catch (err) {
    yield put(productsListActions.getError(err as Error));
  }
}

function* deleteProducts({ payload, meta }: Action) {
  try {
    yield call(productsApi.deleteProducts, payload.brandId, payload);
    meta.onSuccess();
  } catch (e) {
    toast.error('Error delete products');
    meta.onError();
  }
}

export function* productsSaga(): SagaIterator {
  yield all([
    takeEvery(T.IMPORT_PRODUCTS, importProducts),
    takeEvery(T.EXPORT_PRODUCTS, exportProducts),
    takeEvery(productsListActionTypes.GET, getProducts),
    takeEvery(productsForOrdersActionTypes.GET, getProductsForOrders),
    takeEvery(T.DELETE_PRODUCT, deleteProduct),
    takeEvery(T.CHANGE_CATEGORY, changeCategory),
    takeEvery(T.CHANGE_TARGET_ACOS, changeTargetAcos),
    takeEvery(T.CHANGE_MAV_PERCENT, changeMavPercent),
    takeEvery(T.UPDATE_AD_CHANNEL, updateAdChannel),
    takeEvery(productsFiltersActionTypes.GET_BRAND_PRODUCTS_BRANDS, getProductsBrands),
    takeEvery(T.DELETE_PRODUCTS, deleteProducts),
  ]);
}
