import { getDataListReducer } from 'common/store/reducers/data-list';
import { combineReducers } from 'redux';
import { attributionAdGroupsActionTypes, attributionPerformanceOrdersActionTypes } from '../actions';
import data from './data';
import exportReducer from './export';

export const attributionPerformanceReducer = combineReducers({
  data,
  export: exportReducer,
  orders: getDataListReducer<AttributionPerformanceTypes.Order>(attributionPerformanceOrdersActionTypes),
  adGroups: getDataListReducer<AttributionPerformanceTypes.PerformanceAdGroup>(attributionAdGroupsActionTypes),
});
