import { Action } from 'common/interfaces/root';
import { newOverviewActionTypes as T } from '../actions';

const initialState: NewOverviewTypes.StatsStore = {
  loading: false,
  error: null,
  data: null,
};

export default (
  state: NewOverviewTypes.StatsStore = initialState,
  { type, payload }: Action
): NewOverviewTypes.StatsStore => {
  switch (type) {
    case T.GET_OVERVIEW_DATA:
      return { ...state, loading: true };
    case T.GET_OVERVIEW_DATA_SUCCESS:
      return { ...state, data: payload, loading: false };
    case T.GET_OVERVIEW_DATA_ERROR:
      return { ...state, loading: false, error: payload };
    default:
      return state;
  }
};
