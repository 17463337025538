import { createTheme } from '@mui/material';
import { background, paper, primary, primaryLight } from './colors';

export const theme = createTheme({
  palette: {
    primary: {
      main: primary,
      light: primaryLight,
    },
    background: {
      default: background,
      paper,
    },
  },
  typography: {
    fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
  },
  components: {
    MuiTextField: { styleOverrides: {} },
    MuiButton: { styleOverrides: { root: { borderRadius: '12px', textTransform: 'capitalize' } } },
    MuiDivider: { styleOverrides: { root: { borderColor: background } } },
    MuiTableCell: { styleOverrides: { root: { borderColor: background } } },
  },
});
