import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Typography } from '@mui/material';
import { NavItem } from 'common/constants/nav';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Styles } from 'common/types';
import { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { handleActivePath } from 'common/utils';
import { paper, text } from 'common/constants/colors';
import { useLocation, useNavigate } from 'react-router-dom';
import { NavItems } from 'root/layout/menu/nav-items';
import { selectRootDemo } from 'common/store/selectors';
import { useSelector } from 'react-redux';
import { menuCtx } from '../menu-ctx';

interface StylesProps {
  open: boolean;
  hovered: boolean;
  active: boolean;
  level: number;
  isAdminPanelItem?: boolean;
  hidden?: boolean;
}

const getStyles = ({ open, hovered, level, active, isAdminPanelItem, hidden }: StylesProps): Styles => ({
  container: {
    marginTop: isAdminPanelItem ? '50px' : 0,
    boxShadow: 'none',
    // borderTop: isAdminPanelItem ? '1px solid #d9d9d9d5' : 'none',
    // marginRight: isAdminPanelItem ? '36px' : 2,
    backgroundColor: 'transparent',
    opacity: hidden ? 0 : 1,
    ':before': { display: 'none' },
  },
  summaryContainer: { display: 'flex', flexDirection: 'column', width: '100%' },
  summary: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    color: paper,
    paddingLeft: level > 1 ? `${level * 8}px` : 0,
  },
  title: {
    fontSize: 16,
    fontWeight: 400,
    marginLeft: 2,
    // eslint-disable-next-line no-nested-ternary
    color: hovered || active ? text : '#6F8490',
    transition: 'color 0.15s ease-in-out',
  },
  icon: { color: hovered || active ? text : '#6F8490', transition: 'color 0.15s ease-in-out' },
  arrow: {
    color: hovered ? text : '#6F8490',
    marginLeft: 'auto',
    transform: open ? 'rotate(0deg)' : 'rotate(-90deg)',
    transition: 'transform 0.15s ease-in-out, color 0.15s ease-in-out',
  },
  iconTitleContainer: { display: 'flex', alignItems: 'center' },
  customNestedDetails: { padding: '8px 0' },
  divider: { borderColor: '#d9d9d9d5', mb: 1.5 },
});

interface Props {
  config: NavItem;
  level: number;
  nestedExpands?: Array<() => void>;
}

export function NavRowItem({ config, level, nestedExpands }: Props) {
  const navigate = useNavigate();
  const location = useLocation();

  const [expanded, setExpanded] = useState<boolean>(false);
  const [hovered, setHovered] = useState<boolean>(false);

  const { timedOpen } = useContext(menuCtx);

  const isDemoMode = useSelector(selectRootDemo);

  const isAdminPanelItem = config.title === 'Admin Panel';

  const styles = getStyles({
    open: expanded,
    level,
    hovered,
    active: config.path ? handleActivePath(config.path, location) : false,
    hidden: isAdminPanelItem ? isDemoMode : false,
    isAdminPanelItem,
  });

  const handleExpand = (e: SyntheticEvent, expanded: boolean) => {
    if (!config.nested?.length) return;
    setExpanded(expanded);
  };

  const handleExpandOpen = () => {
    setExpanded(true);
  };

  const handleOpen = () => {
    if (config.nested?.length) return;
    navigate(config.path);
  };

  const handleHover = () => {
    setHovered(hovered => !hovered);
  };

  useEffect(() => {
    const active = config.path ? handleActivePath(config.path, location) : false;
    if (!active) return;
    nestedExpands.forEach(expand => expand());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Accordion
      sx={styles.container}
      onChange={handleExpand}
      onClick={handleOpen}
      expanded={expanded}
      square
      disableGutters
    >
      <AccordionSummary onMouseEnter={handleHover} onMouseLeave={handleHover} className={config.target}>
        <Box sx={styles.summaryContainer}>
          {isAdminPanelItem && <Divider sx={styles.divider} />}
          <Box sx={styles.summary}>
            <config.icon sx={styles.icon} />
            {timedOpen && <Typography sx={styles.title}>{config.title}</Typography>}
            {Boolean(config.nested?.length) && <KeyboardArrowDownIcon sx={styles.arrow} />}
          </Box>
        </Box>
      </AccordionSummary>
      {config.nested?.length && timedOpen && (
        <AccordionDetails sx={config.nested ? styles.customNestedDetails : {}}>
          <NavItems level={level + 1} items={config.nested} nestedExpands={[...nestedExpands, handleExpandOpen]} />
        </AccordionDetails>
      )}
    </Accordion>
  );
}
