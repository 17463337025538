import { ApiService } from 'common/services';
import { IGetQueryString } from 'common/ui/base-filters/types';

class DocumentsApiService extends ApiService {
  getDocuments = (filters: IGetQueryString) => {
    return this.get(`/api/agreements/clients/${filters.brand_id}`, { params: { client_type: 'brand' } });
  };

  getDocumentPreview = (documentId: number, brandId: number) => {
    return this.get(`/api/agreements/${documentId}/clients/${brandId}`, { params: { client_type: 'brand' } });
  };

  postDocument = (brandId: number, file: File) => {
    return this.postFormData(`/api/agreements/clients/${brandId}`, { client_type: 'brand', file });
  };
}

export const documentsApi = new DocumentsApiService(process.env.REACT_APP_API_URL);
