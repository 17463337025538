import { userService } from 'common/services/user.service';
import { FunctionComponent } from 'react';
import { PERMISSIONS } from './permissions';

const Products = React.lazy(() => import('modules/products'));
const Dashboard = React.lazy(() => import('modules/dashboard'));
const AttributionPerformance = React.lazy(() => import('modules/attribution-performance'));
const BrandSettings = React.lazy(() => import('modules/brand-settings'));
const AdminBrands = React.lazy(() => import('modules/admin/components/pages/admin-brands'));
const AdminIntegrations = React.lazy(() => import('modules/admin/components/pages/admin-integrations'));
const AdminOverview = React.lazy(() => import('modules/admin/components/pages/admin-overview'));
const AdminPerfomancePage = React.lazy(() => import('modules/admin/components/pages/admin-perfomance-page'));
const AdminCompetitor = React.lazy(() => import('modules/admin/components/pages/admin-competitor'));
const AdminOrders = React.lazy(() => import('modules/admin/components/pages/admin-orders'));
const AdminsPage = React.lazy(() => import('modules/admin/components/pages/admins-page'));
const MavPage = React.lazy(() => import('modules/admin/components/pages/mav-plus-page'));
const AdminMavPage = React.lazy(() => import('modules/admin/components/pages/admin-mav-plus-page'));
const AdminMavProducts = React.lazy(() => import('modules/admin/components/pages/admin-mav-products'));
const AdminBrandsPeriods = React.lazy(() => import('modules/admin/components/pages/admin-brands-periods'));
const AttributionMappings = React.lazy(() => import('modules/attribution-mappings'));
const UserSettings = React.lazy(() => import('modules/user-settings'));
const CampaignCreation = React.lazy(() => import('modules/campaign-creation'));
const IntOAuthSuccess = React.lazy(() => import('common/ui/int-oauth-success'));
const FAQ = React.lazy(() => import('modules/faq'));
const BrandRank = React.lazy(() => import('modules/brand-rank'));
const OverviewNew = React.lazy(() => import('modules/overview-new'));

interface Route {
  path: string;
  component: FunctionComponent;
  isAvailable: boolean;
}

export const getProtectedRoutes = (isBrandHaveMavAccess: boolean): Array<Route> => [
  {
    path: '/',
    component: Dashboard,
    isAvailable: true,
  },
  {
    path: '/overview-new',
    component: OverviewNew,
    isAvailable: true,
  },
  {
    path: '/products',
    component: Products,
    isAvailable: true,
  },
  {
    path: '/brand-rank',
    component: BrandRank,
    isAvailable: true,
  },
  {
    path: '/performance',
    component: AttributionPerformance,
    isAvailable: userService.hasAccess([PERMISSIONS.PERFORMANCE_READ]),
  },
  {
    path: '/brand/:nav',
    component: BrandSettings,
    isAvailable: true,
  },
  {
    path: '/mav',
    component: MavPage,
    isAvailable: isBrandHaveMavAccess,
  },
  {
    path: '/admin/brands',
    component: AdminBrands,
    isAvailable: userService.hasAccess([PERMISSIONS.BRANDS_READ]),
  },
  {
    path: '/admin/integrations',
    component: AdminIntegrations,
    isAvailable: userService.hasAccess([PERMISSIONS.BRANDS_READ]),
  },
  {
    path: '/admin/overview',
    component: AdminOverview,
    isAvailable: userService.hasAccess([PERMISSIONS.BRANDS_READ]),
  },
  {
    path: '/admin/performance',
    component: AdminPerfomancePage,
    isAvailable: userService.hasAccess([PERMISSIONS.BRANDS_READ]),
  },
  {
    path: '/admin/orders',
    component: AdminOrders,
    isAvailable: userService.hasAccess([PERMISSIONS.BRANDS_READ]),
  },
  {
    path: '/admin/mav',
    component: AdminMavPage,
    isAvailable: userService.hasAccess([PERMISSIONS.BRANDS_READ]),
  },
  {
    path: '/admin/mav-products',
    component: AdminMavProducts,
    isAvailable: userService.hasAccess([PERMISSIONS.BRANDS_READ]),
  },
  {
    path: '/admin/brands-periods',
    component: AdminBrandsPeriods,
    isAvailable: userService.hasAccess([PERMISSIONS.BRANDS_READ]),
  },

  {
    path: '/admin/competitor-analysis',
    component: AdminCompetitor,
    isAvailable: userService.hasAccess([PERMISSIONS.BRANDS_READ]),
  },
  {
    path: '/user/settings',
    component: UserSettings,
    isAvailable: true,
  },
  {
    path: '/attribution-mappings',
    component: AttributionMappings,
    isAvailable: true,
  },
  {
    path: '/admin/admin-users',
    component: AdminsPage,
    isAvailable: true,
  },
  {
    path: '/campaign-creation',
    component: CampaignCreation,
    isAvailable: true,
  },
];

export const getUnProtectedRoutes = (): Array<Route> => [
  {
    path: '/success',
    component: IntOAuthSuccess,
    isAvailable: true,
  },
  {
    path: '/faq',
    component: FAQ,
    isAvailable: true,
  },
];
