import { useEffect, useRef, useState } from 'react';

const isElementInViewport = (el: HTMLElement) => {
  if (!el) return false;

  const rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const useInView = (el: HTMLElement) => {
  const [inView, setInView] = useState<boolean>(isElementInViewport(el));
  const inViewRef = useRef<boolean>(isElementInViewport(el));

  useEffect(() => {
    const handler = () => {
      const visible = isElementInViewport(el);

      if (visible !== inViewRef.current) {
        setInView(visible);
        inViewRef.current = visible;
      }
    };

    if (el) handler();

    window.addEventListener('scroll', handler, false);
    window.addEventListener('resize', handler, false);

    return () => {
      window.removeEventListener('scroll', handler, false);
      window.removeEventListener('resize', handler, false);
    };
  }, [el]);

  return inView;
};
