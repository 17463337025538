import { Action } from 'common/interfaces/root';
import { adminActionTypes as T } from '../actions';

const initState: AdminTypes.ConnectionsStoreTypes = {
  tagLoading: false,
  integrationLinkLoading: false,
};

export default (
  state: AdminTypes.ConnectionsStoreTypes = initState,
  { type }: Action
): AdminTypes.ConnectionsStoreTypes => {
  switch (type) {
    case T.GENERATE_INTEGRATION_LINK:
      return { ...state, integrationLinkLoading: true };
    case T.GENERATE_ATTR_TAG:
      return { ...state, tagLoading: true };

    case T.GENERATE_ATTR_TAG_SUCCESS:
    case T.GENERATE_ATTR_TAG_ERROR:
      return { ...state, tagLoading: false };

    case T.GENERATE_INTEGRATION_LINK_SUCCESS:
    case T.GENERATE_INTEGRATION_LINK_ERROR:
      return { ...state, integrationLinkLoading: false };

    default:
      return state;
  }
};
