import { State } from 'common/store/reducers';

export const selectAdminBrands = (state: State) => state.admin.brandsList.data;

export const selectAdminBrandsCacheTime = (state: State) => state.admin.brandsList.cacheTime;

export const selectAdminBrandsLimitPageAmount = (state: State) => ({
  limit: state.admin.brandsList.limit,
  page: state.admin.brandsList.page,
  amount: state.admin.brandsList.amount,
});

export const selectAdminBrandsLoading = (state: State) => state.admin.brandsList.loading;

export const selectAdminBrandsCanLoadMore = (state: State) => state.admin.brandsList.canLoadMore;

export const selectAdminBrandsFilters = (state: State) => state.admin.brandsFilters;

export const selectAdminUnifiedReporting = (state: State) => ({
  current: state.admin.unifiedReporting.currentData,
  previous: state.admin.unifiedReporting.previousPeriodData,
});

export const selectAdminUnifiedReportingLoading = (state: State) => state.admin.unifiedReporting.loading;

export const selectPerformanceTable = (state: State) => state.admin.performanceTable.data;

export const selectPerformanceTableLoading = (state: State) => state.admin.performanceTable.loading;

export const selectPerformanceTableAmount = (state: State) => state.admin.performanceTable.amount;

export const selectPerformanceTablePage = (state: State) => state.admin.performanceTable.page;

export const selectPerformanceTableLimit = (state: State) => state.admin.performanceTable.limit;

export const selectPendingBrands = (state: State) => state.admin.pendingBrands.data;

export const selectPendingBrandsLoading = (state: State) => state.admin.pendingBrands.loading;

export const selectAdminFilters = (state: State) => state.admin.adminFilters;

export const selectOrdersFilters = (state: State) => state.admin.ordersFilters;

export const selectOrdersLimitPageAmount = (state: State) => ({
  limit: state.admin.orders.limit,
  page: state.admin.orders.page,
  amount: state.admin.orders.amount,
});

export const selectOrders = (state: State) => state.admin.orders.data;

export const selectOrdersLoading = (state: State) => state.admin.orders.loading;

export const selectPerformanceFilters = (state: State) => state.admin.performanceFilters;

export const selectOrderHistory = (state: State) => state.admin.orderHistory.data;

export const selectOrderHistoryLoading = (state: State) => state.admin.orderHistory.loading;

export const selectExportLoading = (state: State) => state.admin.export.loading;

export const selectPerformanceExportLoading = (state: State) => state.admin.performanceExport.loading;

export const selectBrandSearchValue = (state: State) => state.admin.brandSearchValue.value;

export const selectBrandParentsData = (state: State) => state.admin.brandParents.data;

export const selectExportOrdersLoading = (state: State) => state.admin.orderExport.loading;

export const selectBrandNotes = (state: State) => state.admin.brandNotes.data;

export const selectBrandNotesLoading = (state: State) => state.admin.brandNotes.loading;

export const selectPublishers = (state: State) => state.admin.publishers.data;

export const selectConnectionsStore = (state: State) => state.admin.connections;

export const selectAdminUsers = (state: State) => state.admin.adminUsers.data;

export const selectAdminUsersLoading = (state: State) => state.admin.adminUsers.loading;

export const selectPubMaverickTable = (state: State) => state.admin.pubMaverickTable.data;

export const selectPubMaverickTableLoading = (state: State) => state.admin.pubMaverickTable.loading;

export const selectPubMaverickTableAmount = (state: State) => state.admin.pubMaverickTable.amount;

export const selectPubMaverickTablePage = (state: State) => state.admin.pubMaverickTable.page;

export const selectPubMaverickTableLimit = (state: State) => state.admin.pubMaverickTable.limit;

export const selectPubMavPerformanceExportLoading = (state: State) => state.admin.pubMavPerformanceExport.loading;

export const selectInvoicesData = (state: State) => state.admin.invoices.data;

export const selectInvoicesLoading = (state: State) => state.admin.invoices.loading;

export const selectMavProductsData = (state: State) => state.admin.mavProducts.data;

export const selectMavProductsLoading = (state: State) => state.admin.mavProducts.loading;

export const selectMavProductsFilters = (state: State) => state.admin.mavProductsFilters;

export const selectMavProductsLimitPageAmount = (state: State) => ({
  limit: state.admin.mavProducts.limit,
  page: state.admin.mavProducts.page,
  amount: state.admin.mavProducts.amount,
});

export const selectMavProductsExportLoading = (state: State) => state.admin.mavProductsExport.loading;

export const selectMavPlusStats = (state: State) => state.admin.mavPlusStats.data;

export const selectBrandsPeriodsData = (state: State) => state.admin.brandsPeriods.data;

export const selectBrandsPeriodsLoading = (state: State) => state.admin.brandsPeriods.loading;

export const selectBrandsPeriodsAmount = (state: State) => state.admin.brandsPeriods.amount;

export const selectBrandsPeriodsPage = (state: State) => state.admin.brandsPeriods.page;

export const selectBrandsPeriodsLimit = (state: State) => state.admin.brandsPeriods.limit;

export const selectPublisherSelectData = (state: State) => state.admin.publisherSelect.data;

export const selectPublisherSelectLoading = (state: State) => state.admin.publisherSelect.loading;

export const selectPubMavSummary = (state: State) => state.admin.pubMavPerformanceSummary.data;

export const selectPubMavSummaryLoading = (state: State) => state.admin.orders.loading;
