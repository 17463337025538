import { Action, ProdVideoEmailData, ProdVideoNotifyData, UserData } from 'common/interfaces/root';
import {
  GET_ME,
  GET_ME_ERROR,
  GET_ME_SUCCESS,
  POST_PROD_VIDEO_EMAIL,
  PUT_DEMO_MODE,
  PUT_IS_ELIGIBLE_STATS,
  PUT_LANGUAGE,
  RESET_REDUCER,
} from './actions-types';

export const putLanguage = (lang: string): Action => ({
  type: PUT_LANGUAGE,
  payload: lang,
});

export const putDemoMode = (payload: boolean): Action => ({
  type: PUT_DEMO_MODE,
  payload,
});

export const putIsEligibleStats = (payload: boolean): Action => ({
  type: PUT_IS_ELIGIBLE_STATS,
  payload,
});

export const postProdVideoEmail = (mail: ProdVideoEmailData, notify: ProdVideoNotifyData): Action => ({
  type: POST_PROD_VIDEO_EMAIL,
  payload: { mail, notify },
});

export const getMe = () => ({
  type: GET_ME,
});

export const getMeSuccess = (data: UserData) => ({
  type: GET_ME_SUCCESS,
  payload: data,
});

export const getMeError = (error: Error) => ({
  type: GET_ME_ERROR,
  payload: error,
});

export const resetReducer = () => ({
  type: RESET_REDUCER,
});
