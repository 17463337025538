import { SagaIterator } from '@redux-saga/types';
import { ordersSaga } from 'common/ui/create-order-modal/store';
import { adminSaga } from 'modules/admin/store';
import { attributionMappingsSaga } from 'modules/attribution-mappings/store';
import { attributionPerformanceSaga } from 'modules/attribution-performance/store';
import { brandSettingsSaga } from 'modules/brand-settings/store';
import { campaignCreationSaga } from 'modules/campaign-creation/store';
import { newOverviewSaga } from 'modules/overview-new/store';
import { productsSaga } from 'modules/products/store';
import { userSettingsSaga } from 'modules/user-settings/store';
import { all, fork, takeLatest } from 'redux-saga/effects';
import { POST_PROD_VIDEO_EMAIL } from '../actions';
import { presetsActionsTypes } from '../actions/presets';
import { getPresetsSaga, getTimeInfo, postProdVideoEmail } from './root';

function* rootSaga(): SagaIterator {
  yield all([
    takeLatest(presetsActionsTypes.GET_FILTERS_PRESETS, getPresetsSaga),
    takeLatest(presetsActionsTypes.GET_TIME_INFO, getTimeInfo),
    takeLatest(POST_PROD_VIDEO_EMAIL, postProdVideoEmail),
    fork(userSettingsSaga),
    fork(productsSaga),
    fork(brandSettingsSaga),
    fork(adminSaga),
    fork(ordersSaga),
    fork(attributionPerformanceSaga),
    fork(attributionMappingsSaga),
    fork(campaignCreationSaga),
    fork(newOverviewSaga),
  ]);
}

export default rootSaga;
