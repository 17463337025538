import { Action } from 'common/interfaces/root';
import { adminActionTypes as T } from '../actions';

const initState: AdminTypes.OrderHistoryStore = {
  loading: true,
  error: null,
  data: null,
};

export default (
  state: AdminTypes.OrderHistoryStore = initState,
  { type, payload }: Action
): AdminTypes.OrderHistoryStore => {
  switch (type) {
    case T.GET_ORDER_HISTORY:
      return { ...state, loading: true };

    case T.GET_ORDER_HISTORY_SUCCESS:
      return { ...state, loading: false, data: payload };

    case T.GET_ORDER_HISTORY_ERROR:
      return { ...state, loading: false, error: payload };

    default:
      return state;
  }
};
