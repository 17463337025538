import { ProdVideoEmailData } from 'common/interfaces/root';
import { ApiService } from 'common/services';

class PresetsApiService extends ApiService {
  getBrands = () => {
    return this.get('/api/presets/filters/brands');
  };

  getTimeInfo = () => {
    return this.get('/api/presets/filters/time-info');
  };

  postProdVideoEmail = (payload: ProdVideoEmailData) => {
    return this.post('/api/emails', payload);
  };

  postVideoRequestsNotify = (payload: ProdVideoEmailData) => {
    return this.post('/api/video-requests/notify', payload);
  };
}

export const presetsApi = new PresetsApiService(process.env.REACT_APP_API_URL);
