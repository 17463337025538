import { SagaIterator } from '@redux-saga/types';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Action, ErrorRequest } from 'common/interfaces/root';
import { integrationsApi } from 'common/services/api/integrations-api.service';
import { selectGlobalFilters } from 'common/store/selectors';
import { brandApi } from 'modules/brand-settings/services/brand-api.service';
import toast from 'react-hot-toast';
import { attributionApi } from 'modules/brand-settings/services/attribution-api.service';
import { paymentApi } from 'modules/brand-settings/services/payment-api.service';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { teamApi } from 'modules/brand-settings/services/team-api.service';
import { documentsApi } from 'modules/brand-settings/services/documents-api.service';
import { newTeamApi } from 'modules/brand-settings/services/team-v2-api.service';
import {
  attributionAsinsListActions,
  attributionAsinsListTypes,
  attributionProfilesListActions,
  attributionProfilesListTypes,
  attributionPublishersListActions,
  attributionPublishersListTypes,
  attributionStoresListActions,
  attributionStoresListTypes,
  brandSettingsActions,
  brandSettingsActionTypes as T,
  docsListActions,
  docsListTypes,
  integrationsListActions,
  integrationsListActionTypes,
  userWalletActionTypes,
  userWalletListActions,
  walletTransactionsActionTypes,
  walletTransactionsListActions,
} from '../actions';

function* getIntegrations({ payload }: Action) {
  try {
    const { data } = yield call(integrationsApi.getIntegrations, payload.brand_id);

    yield put(integrationsListActions.getSuccess(data.integrations));
  } catch (err) {
    yield put(integrationsListActions.getError(err as Error));
  }
}

function* revokeIntegration({ payload }: Action): SagaIterator {
  try {
    yield call(integrationsApi.revokeIntegration, payload);
    const globalFilters = yield select(selectGlobalFilters);
    yield put(integrationsListActions.get({ brand_id: globalFilters.brand_id }));
    toast.success('Integration revoked successfully!');
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    toast.error('Error while revoking integration!');
  }
}

function* initIntegration({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(
      integrationsApi.initIntegration,
      payload.brandId,
      payload.integrationType,
      payload.redirectUrl
    );
    window.open(data.link);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    toast.error('Error while initializing integration!');
  }
}

function* getBrand({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(brandApi.getBrand, payload);
    yield put(brandSettingsActions.getBrandSuccess(data));
  } catch (err) {
    yield put(brandSettingsActions.getBrandError(err as Error));
  }
}

function* updateBrand({ payload }: Action): SagaIterator {
  try {
    yield call(brandApi.editBrand, payload.brand, payload.brandId);
    toast.success('Brand updated successfully!');
  } catch (err) {
    yield put(brandSettingsActions.updateBrandError(err as Error));
    toast.error('Error while updating a brand!');
  }
}

function* getAttributionData({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(attributionApi.getAttributionData, payload);

    const attribution = { ...data, ...JSON.parse(data.value) };
    delete attribution.value;

    yield put(brandSettingsActions.getAttributionDataSuccess(attribution));
  } catch (err) {
    yield put(brandSettingsActions.getAttributionDataError(err as Error));
  }
}

function* getAttributionProfiles({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(attributionApi.getAttributionProfiles, payload.brand_id);
    yield put(attributionProfilesListActions.getSuccess(data));
  } catch (err) {
    yield put(attributionProfilesListActions.getError(err as Error));
  }
}

function* getAttributionPublishers({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(attributionApi.getAttributionPublishers, payload.brand_id, payload.profile_id);
    yield put(attributionPublishersListActions.getSuccess(data.publishers));
  } catch (err: ErrorRequest) {
    yield put(attributionPublishersListActions.getError(err as Error));
    toast.error(err?.response?.data?.message ?? 'Something went wrong');
  }
}

function* getAttributionStores({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(attributionApi.getAttributionStores, payload.brand_id, payload.profile_id);
    yield put(attributionStoresListActions.getSuccess(data));
  } catch (err) {
    yield put(attributionStoresListActions.getError(err as Error));
  }
}

function* getDocuments({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(documentsApi.getDocuments, payload);
    yield put(docsListActions.getSuccess(data.agreements, data.agreements_count));
  } catch (err) {
    yield put(docsListActions.getError(err as Error));
  }
}

function* postAttributionData({ payload }: Action): SagaIterator {
  try {
    yield call(attributionApi.postAttributionData, payload.data, payload.brandId);
    yield put(brandSettingsActions.postAttributionDataSuccess());
    yield put(brandSettingsActions.getAttributionData(payload.brandId));
    toast.success('Attribution saved successfully!');
  } catch (err) {
    yield put(brandSettingsActions.postAttributionDataError(err as Error));
    toast.error('Error while saving attribution!');
  }
}

function* patchAttributionData({ payload }: Action): SagaIterator {
  try {
    yield call(attributionApi.patchAttributionData, payload.data, payload.brandId, payload.attributionId);
    yield put(brandSettingsActions.patchAttributionDataSuccess());
    toast.success('Attribution saved successfully!');
  } catch (err) {
    yield put(brandSettingsActions.patchAttributionDataError(err as Error));
    toast.error('Error while saving attribution!');
  }
}

function* getAttributionTag({ payload, meta }: Action): SagaIterator {
  try {
    const { data } = yield call(
      attributionApi.getAttributionTag,
      payload.brandId,
      payload.profileId,
      payload.publisherId
    );
    const tags = Object.values(data)[0];
    const tag = Object.values(tags)[0];
    meta.onSuccess(tag);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('get tag error');
    toast.error('Error while getting attribution tag!');
  }
}

function* addNewCard({ payload, meta }: Action): SagaIterator {
  yield call(paymentApi.addNewPayment, payload);
  meta?.onSuccess();
}

function* deleteCard({ payload, meta }: Action): SagaIterator {
  yield call(paymentApi.deleteCard, payload);
  meta?.onSuccess();
}

function* addNewCardToWallet({ payload, meta }: Action): SagaIterator {
  yield call(paymentApi.addNewCardToWallet, payload);
  meta?.onSuccess();
}

function* getUserWallet({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(paymentApi.getPaymentInfo, payload);
    if (data?.wallet?.id) {
      const wallets = yield call(paymentApi.getWalletCards, data?.wallet?.id);
      yield put(userWalletListActions.getSuccess(wallets?.data?.cards ?? null, data?.wallet?.id));
    } else {
      yield put(userWalletListActions.getSuccess(null, data?.wallet?.id));
    }
    yield put(brandSettingsActions.getWalletInfo({ id: data?.wallet?.id, balance: data?.wallet?.balance }));
  } catch (err) {
    yield put(userWalletListActions.getError(err as Error));
  }
}

function* getWalletTransactions({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(paymentApi.getWalletTransactions, payload);
    yield put(walletTransactionsListActions.getSuccess(data?.transactions ?? [], data?.transactions_count ?? 0));
  } catch (err) {
    yield put(walletTransactionsListActions.getError(err as Error));
  }
}

function* addFunds({ payload }: Action): SagaIterator {
  try {
    yield call(paymentApi.addFunds, payload);
    yield put(brandSettingsActions.addFundsSuccess());
  } catch (err) {
    yield put(brandSettingsActions.addFundsError(err as Error));
  }
}

function* getAsins({ payload }: Action): SagaIterator {
  try {
    const responses = yield all(
      payload.storefront_pages.map((p: string) =>
        call(attributionApi.getAsins, payload.brand_id, payload.profile_id, p)
      )
    );

    const asinsSet = new Set<string>();

    responses.forEach((item: { data: { asinList: Array<string> } }) => {
      item.data.asinList.forEach(asin => asinsSet.add(asin));
    }, []);

    yield put(attributionAsinsListActions.getSuccess(Array.from(asinsSet)));
  } catch (err) {
    yield put(attributionAsinsListActions.getError(err as Error));
  }
}

function* getBrandTeam({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(teamApi.getBrandTeam, payload);
    yield put(brandSettingsActions.getBrandTeamSuccess(data));
  } catch (err) {
    yield put(brandSettingsActions.getBrandTeamError(err as Error));
  }
}

function* sendInvitation({ payload, meta }: Action): SagaIterator {
  try {
    yield call(teamApi.sendInvitation, payload);
    yield put(brandSettingsActions.sendInvitationSuccess());
    meta.onSuccess();
  } catch (err) {
    yield put(brandSettingsActions.sendInvitationError(err as Error));
    meta.onError();
  }
}

function* postDocument({ payload }: Action): SagaIterator {
  try {
    yield call(documentsApi.postDocument, payload.brandId, payload.file);
    const globalFilters = yield select(selectGlobalFilters);
    yield put(docsListActions.get({ brand_id: globalFilters?.brand_id }));
    toast.success('Document uploaded successfully!');
  } catch (err) {
    yield put(brandSettingsActions.postDocumentError(err as Error));
    toast.error('Error while uploading a document!');
  }
}

function* removeTeamMember({ payload }: Action): SagaIterator {
  try {
    yield call(teamApi.removeTeamMember, payload);
    yield put(brandSettingsActions.removeTeamMemberSuccess(payload.userId));
  } catch (err) {
    yield put(brandSettingsActions.removeTeamMemberError(err as Error));
  }
}

function* getPendingInvitations({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(teamApi.getPendingInvitations, payload);
    yield put(brandSettingsActions.getPendingInvitationsSuccess(data));
  } catch (err) {
    yield put(brandSettingsActions.getPendingInvitationsError(err as Error));
  }
}

function* exportPdfTransaction({ payload }: Action): SagaIterator {
  try {
    yield call(paymentApi.exportPdfTransaction, payload);
    toast.success('PDF exported successfully!');
  } catch (err) {
    toast.error('Error while exporting PDF!');
  }
}

function* getTeam({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(newTeamApi.getTeam, payload);
    yield put(brandSettingsActions.getTeamSuccess(data));
  } catch (err) {
    yield put(brandSettingsActions.getTeamError(err as Error));
  }
}

function* getClients(): SagaIterator {
  try {
    const { data } = yield call(newTeamApi.getClients);
    yield put(brandSettingsActions.getClientsSuccess(data));
  } catch (err) {
    yield put(brandSettingsActions.getClientsError(err as Error));
  }
}

function* patchTeamUser({ payload, meta }: Action): SagaIterator {
  try {
    const { data } = yield call(newTeamApi.patchTeamUser, payload);
    yield put(brandSettingsActions.patchTeamUserSuccess(data));
    meta.onSuccess();
  } catch (err) {
    yield put(brandSettingsActions.patchTeamUserError(err as Error));
    meta.onSuccess();
  }
}

function* cancelTeamUser({ payload, meta }: Action): SagaIterator {
  try {
    yield call(newTeamApi.cancelTeamUser, payload);
    yield put(brandSettingsActions.cancelTeamUserSuccess());
    meta.onSuccess();
  } catch (err) {
    yield put(brandSettingsActions.cancelTeamUserError(err as Error));
    meta.onSuccess();
  }
}

function* resendTeamUser({ payload, meta }: Action): SagaIterator {
  try {
    yield call(newTeamApi.resendTeamUser, payload);
    yield put(brandSettingsActions.resendTeamUserSuccess());
    meta.onSuccess();
  } catch (err) {
    yield put(brandSettingsActions.resendTeamUserError(err as Error));
    meta.onSuccess();
  }
}

export function* brandSettingsSaga(): SagaIterator {
  yield all([
    takeEvery(integrationsListActionTypes.GET, getIntegrations),
    takeEvery(T.REVOKE_INTEGRATION, revokeIntegration),
    takeEvery(T.INIT_INTEGRATION, initIntegration),
    takeEvery(T.GET_BRAND, getBrand),
    takeEvery(T.UPDATE_BRAND, updateBrand),
    takeEvery(T.GET_ATTRIBUTION_DATA, getAttributionData),
    takeEvery(attributionProfilesListTypes.GET, getAttributionProfiles),
    takeEvery(attributionPublishersListTypes.GET, getAttributionPublishers),
    takeEvery(T.POST_ATTRIBUTION_DATA, postAttributionData),
    takeEvery(T.PATCH_ATTRIBUTION_DATA, patchAttributionData),
    takeEvery(T.GET_ATTRIBUTION_TAG, getAttributionTag),
    takeEvery(T.ADD_NEW_CARD, addNewCard),
    takeEvery(T.DELETE_CARD, deleteCard),
    takeEvery(T.ADD_NEW_CARD_TO_WALLET, addNewCardToWallet),
    takeEvery(userWalletActionTypes.GET, getUserWallet),
    takeEvery(walletTransactionsActionTypes.GET, getWalletTransactions),
    takeEvery(T.ADD_FUNDS, addFunds),
    takeEvery(attributionStoresListTypes.GET, getAttributionStores),
    takeEvery(attributionAsinsListTypes.GET, getAsins),
    takeEvery(T.GET_BRAND_TEAM, getBrandTeam),
    takeEvery(T.SEND_INVITATION, sendInvitation),
    takeEvery(docsListTypes.GET, getDocuments),
    takeEvery(T.POST_DOCUMENT, postDocument),
    takeEvery(T.REMOVE_TEAM_MEMBER, removeTeamMember),
    takeEvery(T.GET_PENDING_INVITATIONS, getPendingInvitations),
    takeEvery(T.EXPORT_PDF_TRANSACTION, exportPdfTransaction),
    takeEvery(T.GET_TEAM, getTeam),
    takeEvery(T.GET_CLIENTS, getClients),
    takeEvery(T.PATCH_TEAM_USER, patchTeamUser),
    takeEvery(T.CANCEL_TEAM_USER, cancelTeamUser),
    takeEvery(T.RESEND_TEAM_USER, resendTeamUser),
  ]);
}
