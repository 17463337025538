export enum PERMISSIONS {
  BRANDS_READ = 'carrick-dashboard.brands.read',
  HISTORICAL_READ = 'carrick-dashboard.historical.read',
  TARGET_ACOS_READ = 'carrick-dashboard.products.acos.read',
  ORDER_LINKS_READ = 'carrick-dashboard.order-links.read',
  AD_LINKS_READ = 'carrick-dashboard.ad-links.read',
  FINAL_URLS_READ = 'carrick-dashboard.final-urls.read',
  CPC_READ = 'carrick-dashboard.cpc.read',
  KEYWORD_READ = 'carrick-dashboard.keyword.read',
  BRAND_LIFETIME_BUDGET_WRITE = 'carrick-dashboard.brand-lifetime-budget.write',
  ORDER_TARGET_ROAS_WRITE = 'carrick-dashboard.order-target-roas.write',
  ORDERS_DELETE = 'carrick-dashboard.orders.delete',
  INTEGRATIONS_DELETE = 'carrick-dashboard.integrations.delete',
  ORDERS_WRITE = 'carrick-dashboard.orders.write',
  PERFORMANCE_READ = 'carrick-dashboard.attribution-performance.read',
}
