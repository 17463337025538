const MODULE_NAME = 'root';

export const PUT_LANGUAGE = `[${MODULE_NAME}] PUT_LANGUAGE`;
export const PUT_DEMO_MODE = `[${MODULE_NAME}] PUT_DEMO_MODE`;
export const PUT_IS_ELIGIBLE_STATS = `[${MODULE_NAME}] PUT_IS_ELIGIBLE_STATS`;
export const POST_PROD_VIDEO_EMAIL = `[${MODULE_NAME}] POST_PROD_VIDEO_EMAIL`;

export const GET_ME = `[${MODULE_NAME}] GET_ME`;
export const GET_ME_SUCCESS = `[${MODULE_NAME}] GET_ME_SUCCESS`;
export const GET_ME_ERROR = `[${MODULE_NAME}] GET_ME_ERROR`;

export const RESET_REDUCER = `[${MODULE_NAME}] RESET_REDUCER`;
