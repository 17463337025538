import { ApiService } from 'common/services';

class NewTeamApiService extends ApiService {
  getTeam = (payload?: number) => {
    return this.get('/api/team', {
      params: {
        client_type: 'brand',
        ...(payload ? { client_id: payload } : {}),
      },
    });
  };

  getClients = () => {
    return this.get('/api/team/clients', {
      params: {
        client_type: 'brand',
      },
    });
  };

  patchTeamUser = (payload: BrandSettingsTypes.PatchUserTeam) => {
    return this.post('/api/team/invite', payload);
  };

  cancelTeamUser = (payload: string) => {
    return this.post('/api/team/invite/cancel', {
      client_type: 'brand',
      email: payload,
    });
  };

  resendTeamUser = (payload: string) => {
    return this.post('/api/team/invite/resend', {
      client_type: 'brand',
      email: payload,
    });
  };
}

export const newTeamApi = new NewTeamApiService(process.env.REACT_APP_API_URL);
