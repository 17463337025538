import { Action } from 'common/interfaces/root';
import { adminActionTypes as T } from '../actions';

const initState: AdminTypes.PubSelectStore = {
  loading: true,
  error: null,
  data: null,
};

export default (state: AdminTypes.PubSelectStore = initState, { type, payload }: Action): AdminTypes.PubSelectStore => {
  switch (type) {
    case T.GET_BRANDS_PUBLISHERS:
      return { ...state, loading: true };

    case T.GET_BRANDS_PUBLISHERS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: payload,
      };

    case T.GET_BRANDS_PUBLISHERS_ERROR:
      return { ...state, loading: false, error: payload };

    default:
      return state;
  }
};
