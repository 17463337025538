const DATE_PICKER_COMPARATOR_MODULE = '[date-picker-comparitor]';

const CHANGE_IS_COMPARE = `${DATE_PICKER_COMPARATOR_MODULE} CHANGE_IS_COMPARE`;
const CHANGE_DATE_RANGES = `${DATE_PICKER_COMPARATOR_MODULE} CHANGE_DATE_RANGES`;
const CHANGE_DATES_TO_COMPARE = `${DATE_PICKER_COMPARATOR_MODULE} CHANGE_DATES_TO_COMPARE`;

export const datePickerComparatorActionTypes = {
  CHANGE_IS_COMPARE,
  CHANGE_DATE_RANGES,
  CHANGE_DATES_TO_COMPARE,
};
