import { Box, Typography } from '@mui/material';
import { paper } from 'common/constants/colors';
import { Styles } from 'common/types';
import { MouseEvent, ReactElement } from 'react';
import { transformBrandLogo } from 'common/utils/brand-logo';

interface StylesProps {
  active: boolean;
}

const getStyles = ({ active }: StylesProps): Styles => ({
  row: {
    backgroundColor: active ? '#f5f5f5' : paper,
    borderBottom: '1px solid #e5e5e5',
    height: 48,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '0 4px',
    ':hover': {
      backgroundColor: '#f5f5f5',
    },
  },
  title: {
    marginRight: '5px',
  },
});

interface Props {
  active: boolean;
  handleBrandChange: (brand: number | null | string) => void;
  title: string;
  clientId: string;
  value: number | string;
  handlePopoverClose: () => void;
}

export function BrandRow({
  active,
  handleBrandChange,
  title,
  clientId,
  value,
  handlePopoverClose,
}: Props): ReactElement {
  const styles = getStyles({ active });

  const handlePublisherClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    handleBrandChange(value);
    handlePopoverClose();
  };

  return (
    <Box sx={styles.row} onClick={handlePublisherClick}>
      <Typography sx={styles.title}>{title}</Typography> {transformBrandLogo(clientId)}
    </Box>
  );
}
