import { Action } from 'common/interfaces/root';
import { adminActionTypes as T } from '../actions';

const initState: AdminTypes.BrandSearchStoreTypes = {
  value: 1,
};

export default (
  state: AdminTypes.BrandSearchStoreTypes = initState,
  { type, payload }: Action
): AdminTypes.BrandSearchStoreTypes => {
  switch (type) {
    case T.BRAND_SEARCH_VALUE_CHANGE:
      return { ...state, value: payload };

    default:
      return state;
  }
};
