import { Box, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { text } from 'common/constants/colors';
import { Styles } from 'common/types';
import { ReactElement, ReactNode } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { theme } from 'common/constants/theme';

interface StylesProps {
  borderBottom?: boolean;
}

const getStyles = ({ borderBottom }: StylesProps): Styles => ({
  dialog: { borderRadius: '20px' },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 2, 2, 3),
    boxSizing: 'border-box',
    borderBottom: borderBottom ? '1px solid #C2DEEB' : 'none',
  },
  title: { color: text, fontSize: 21, p: 0 },
  content: { padding: 0 },
  contentWrapper: { padding: 3 },
  close: { marginLeft: 0.5 },
});

interface Props {
  title?: string;
  children: ReactNode;
  open: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  scrollType?: 'paper' | 'body';
  hiddenHeader?: boolean;
  borderBottom?: boolean;
  onClose: () => void;
  fullWidth?: boolean;
}

export function Modal({
  title,
  children,
  open,
  onClose,
  scrollType,
  maxWidth = 'sm',
  hiddenHeader = false,
  borderBottom = false,
  fullWidth = true,
}: Props): ReactElement {
  const styles = getStyles({ borderBottom });

  return (
    <Dialog
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      scroll={scrollType}
      open={open}
      onClose={onClose}
      PaperProps={{ sx: styles.dialog }}
    >
      {!hiddenHeader && (
        <Box sx={styles.titleContainer}>
          <DialogTitle sx={styles.title}>{title}</DialogTitle>
          <IconButton sx={styles.close} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      )}
      <DialogContent sx={styles.content}>
        <Box sx={styles.contentWrapper}>{children}</Box>
      </DialogContent>
    </Dialog>
  );
}
