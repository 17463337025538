import { SagaIterator } from '@redux-saga/types';
import { AxiosError } from 'axios';
import { Action } from 'common/interfaces/root';
import { authApi } from 'common/services/api/auth-api.service';
import { userService } from 'common/services/user.service';
import { userSettingsApi } from 'modules/user-settings/service/user-settings.service';
import toast from 'react-hot-toast';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { userSettingsActions, userSettingsActionTypes as T } from '../actions';

function* getUserSettingsInfo(): SagaIterator {
  try {
    const id = userService.user.internal_id;
    const { data } = yield call(userSettingsApi.getUserSettingsInfo, id);
    yield put(userSettingsActions.getUserSettingsInfoSuccess(data));
  } catch (err) {
    yield put(userSettingsActions.getUserSettingsInfoError(err as AxiosError));
  }
}

function* patchUserSettingsInfo({ payload }: Action): SagaIterator {
  try {
    const id = userService.user.internal_id;
    yield call(userSettingsApi.patchUserSettingsInfo, { ...payload, id });
    const { data } = yield call(authApi.getMe);
    yield put(
      userSettingsActions.patchUserSettingsInfoSuccess({ avatar: data.user.avatar, id: data.id, name: data.user.name })
    );
    toast.success('User settings updated successfully!');
  } catch (err) {
    yield put(userSettingsActions.patchUserSettingsInfoError(err as AxiosError));
  }
}

export function* userSettingsSaga(): SagaIterator {
  yield all([
    takeLatest(T.GET_USER_SETTINGS_INFO, getUserSettingsInfo),
    takeLatest(T.PATCH_USER_SETTINGS_INFO, patchUserSettingsInfo),
  ]);
}
