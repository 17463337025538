import { SagaIterator } from '@redux-saga/types';
import { Action } from 'common/interfaces/root';
import { adminBrandsApi } from 'modules/admin/services/admin-brands.service';
import { performanceTableActions } from 'modules/admin/store/actions';
import { attributionPerformanceApi } from 'modules/attribution-performance/services/attribution-performance-api.service';
import toast from 'react-hot-toast';
import { all, call, delay, put, takeEvery } from 'redux-saga/effects';
import {
  attributionAdGroupsActions,
  attributionAdGroupsActionTypes,
  attributionPerformanceActions,
  attributionPerformanceActionTypes as T,
  attributionPerformanceOrdersActions,
  attributionPerformanceOrdersActionTypes,
} from '../actions';

// eslint-disable-next-line consistent-return
function* checkExportStatus(id: string): SagaIterator<string> {
  let status = '';

  while (status !== 'success') {
    const { data } = yield call(adminBrandsApi.getExportStatus, id);

    switch (data.status) {
      case 'pending':
        status = data.status;
        yield delay(1000);
        break;
      case 'success':
        status = data.status;
        return data.link;
      case 'failed':
        status = data.status;
        throw new Error('export failed');
      default:
        throw new Error('export failed');
    }
  }
}

function* getAttributionWidgets({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(attributionPerformanceApi.getWidgets, payload);
    yield put(
      attributionPerformanceActions.getAttributionPerformanceSuccess(data.data, data.total, data.assignedCampaigns)
    );
  } catch (err) {
    yield put(attributionPerformanceActions.getAttributionPerformanceError(err as Error));
  }
}

function* getAttributionAdGroups({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(attributionPerformanceApi.getWidgets, payload);
    yield put(attributionAdGroupsActions.getSuccess(data.data));
  } catch (err) {
    yield put(attributionAdGroupsActions.getError(err as Error));
  }
}

function* getAttributionOrders({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(attributionPerformanceApi.getOrders, payload.brand_id, payload.limit);
    yield put(attributionPerformanceOrdersActions.getSuccess(data.results));
  } catch (err) {
    yield put(attributionPerformanceOrdersActions.getError(err as Error));
  }
}

function* getAttributionPerformanceOrderExports({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(attributionPerformanceApi.getAttributionPerformanceOrderExports, payload);

    const link = yield call(checkExportStatus, data.job_id);

    window.open(link);

    yield put(attributionPerformanceActions.getAttributionPerformanceOrderExportSuccess(link));
  } catch (err) {
    toast.error('Error while exporting data!');
    yield put(attributionPerformanceActions.getAttributionPerformanceOrderExportError(err as Error));
  }
}

function* changeKeywordInfo({ payload, meta }: Action): SagaIterator {
  try {
    yield call(attributionPerformanceApi.changeKeywordInfo, payload);
    yield put(attributionPerformanceActions.changeCpcSuccess());
    meta.onSuccess();
    toast.success('Keyword info updated succesfully!');
  } catch (err) {
    toast.error('Error while changing keyword info!');
  }
}

function* changeCampaignStatus({ payload, meta }: Action): SagaIterator {
  try {
    yield call(attributionPerformanceApi.changeCampaignStatus, payload.brandId, payload.campaignId, payload.status);
    yield put(
      performanceTableActions.updateEntity('campaign_id', payload.campaignId, { campaign_status: payload.status })
    );
    // eslint-disable-next-line no-unused-expressions
    meta.onSuccess && meta.onSuccess();
    toast.success('Campaign status updated succesfully!');
  } catch (err) {
    toast.error('Error while changing campaign status!');
  }
}

function* changeAdgroupStatus({ payload, meta }: Action): SagaIterator {
  try {
    yield call(attributionPerformanceApi.changeAdgroupStatus, payload.brandId, payload.adgroupId, payload.status);
    yield put(
      performanceTableActions.updateEntity('adgroup_id', payload.adgroupId, { adgroup_status: payload.status })
    );
    // eslint-disable-next-line no-unused-expressions
    meta.onSuccess && meta.onSuccess();
    toast.success('AdGroup status updated succesfully!');
  } catch (err) {
    toast.error('Error while changing adgroup status!');
  }
}

export function* attributionPerformanceSaga(): SagaIterator {
  yield all([
    takeEvery(T.GET_ATTRIBUTION_PERFORMANCE, getAttributionWidgets),
    takeEvery(attributionPerformanceOrdersActionTypes.GET, getAttributionOrders),
    takeEvery(attributionAdGroupsActionTypes.GET, getAttributionAdGroups),
    takeEvery(T.GET_ATTRIBUTION_PERFORMANCE_ORDER_EXPORT, getAttributionPerformanceOrderExports),
    takeEvery(T.CHANGE_KEYWORD_INFO, changeKeywordInfo),
    takeEvery(T.CHANGE_CAMPAIGN_STATUS, changeCampaignStatus),
    takeEvery(T.CHANGE_ADGROUP_STATUS, changeAdgroupStatus),
  ]);
}
