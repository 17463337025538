import { Box, Popover, Tab, Tabs, TextField } from '@mui/material';
import { ClientHashMapping } from 'common/constants/brands';
import { PERMISSIONS } from 'common/constants/permissions';
import { usePermission } from 'common/hooks';
import { FilterEntry } from 'common/interfaces/presets';
import { selectFiltersPresets } from 'common/store/selectors';
import { Styles } from 'common/types';
import { ChangeEvent, ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { BrandRow } from './brand-row';

enum NAV {
  ALL = 0,
  PEPRETUA,
  ADVON,
}

const styles: Styles = {
  container: { padding: 2, width: 280, maxWidth: 270, overflowY: 'scroll', scrollbarWidth: 'none' },
  publishers: { display: 'flex', flexDirection: 'column', marginTop: 2 },
  tabs: { marginTop: 1 },
};

interface Props {
  onClose: () => void;
  anchorElement: HTMLElement | null;
  selectedBrand: number | null | string;
  handleBrandChange: (brand: number | null | string) => void;
}

export function BrandsPickerPopover({ onClose, anchorElement, selectedBrand, handleBrandChange }: Props): ReactElement {
  const filtersPresets = useSelector(selectFiltersPresets);

  const canReadAllBrands = usePermission(PERMISSIONS.BRANDS_READ);

  const [search, setSearch] = useState<string>('');
  const [nav, setNav] = useState<NAV>(NAV.ALL);

  const handleClose = () => {
    onClose();
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleChangePath = (e: React.SyntheticEvent<Element, Event>, value: NAV) => {
    setNav(value);
  };

  const filterByNav = (nav: NAV, brand: FilterEntry) => {
    switch (nav) {
      case NAV.ALL:
        return true;
      case NAV.ADVON:
        return !brand.client_id;
      case NAV.PEPRETUA:
        return brand.client_id === ClientHashMapping.Perpetua;
      default:
        return false;
    }
  };

  return (
    <Popover
      anchorEl={anchorElement}
      open={Boolean(anchorElement)}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Box sx={styles.container}>
        <TextField
          size="small"
          variant="outlined"
          onChange={handleSearch}
          value={search}
          placeholder="Search..."
          fullWidth
        />
        {canReadAllBrands && (
          <Tabs sx={styles.tabs} value={nav} onChange={handleChangePath}>
            <Tab label="All" value={NAV.ALL} />
            <Tab label="Advon" value={NAV.ADVON} />
            <Tab label="Perpetua" value={NAV.PEPRETUA} />
          </Tabs>
        )}
        <Box sx={styles.publishers}>
          {(filtersPresets?.brands || [])
            .filter(brand => brand.name.toLowerCase().includes(search.toLowerCase()) && filterByNav(nav, brand))
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(brand => (
              <BrandRow
                key={brand.id}
                handleBrandChange={handleBrandChange}
                active={String(selectedBrand) === String(brand.id)}
                title={brand.name}
                clientId={brand.client_id}
                value={brand.id}
                handlePopoverClose={onClose}
              />
            ))}
        </Box>
      </Box>
    </Popover>
  );
}
