import { Action, RootStore } from 'common/interfaces/root';
import { GET_ME, GET_ME_ERROR, GET_ME_SUCCESS, PUT_DEMO_MODE, PUT_IS_ELIGIBLE_STATS, PUT_LANGUAGE } from '../actions';
import { rootInitState } from '../init-state';

export default (state: RootStore = rootInitState, { type, payload }: Action): RootStore => {
  switch (type) {
    case PUT_LANGUAGE:
      return { ...state, lang: payload };

    case PUT_DEMO_MODE:
      return { ...state, demo: payload };

    case PUT_IS_ELIGIBLE_STATS:
      return { ...state, isEligibleStats: payload };

    case GET_ME:
      return { ...state, loading: true };

    case GET_ME_SUCCESS:
      return { ...state, loading: false };

    case GET_ME_ERROR:
      return { ...state, loading: false, error: payload };

    default:
      return state;
  }
};
