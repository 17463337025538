import { ApiService } from 'common/services';

class InvoicesApiService extends ApiService {
  getInvoices = (filters: AdminTypes.InvoiceParams) => {
    return this.get(
      `/api/accounting/invoices?client_id=${filters.client_id}&client_type=${filters.client_type}&period[month]=${filters.period_month}&period[year]=${filters.period_year}`
    );
  };

  createInvoice = (payload: AdminTypes.NewInvoice) => {
    return this.post('/api/accounting/invoices', payload);
  };

  chargeInvoice = (payload: { id: number; markPaid?: boolean }) => {
    return this.post(`/api/accounting/invoices/${payload?.id}/charge`, {
      ...(payload.markPaid ? { markPaid: payload.markPaid } : {}),
    });
  };

  getInvoiceById = (id: number) => {
    return this.get(`/api/accounting/invoices/${id}`);
  };

  deleteInvoice = (id: number) => {
    return this.delete(`/api/accounting/invoices/${id}`);
  };
}

export const invoicesApi = new InvoicesApiService(process.env.REACT_APP_API_URL);
