export const getDataListActionTypes = (module: string) => ({
  GET: `${module} GET`,
  GET_SUCCESS: `${module} GET_SUCCESS`,
  GET_SUCCESS_LAZY: `${module} GET_SUCCESS_LAZY`,
  GET_ERROR: `${module} GET_ERROR`,
  DELETE: `${module} DELETE`,
  DELETE_SUCCESS: `${module} DELETE_SUCCESS`,
  DELETE_ERROR: `${module} DELETE_ERROR`,
  CHANGE_PAGE: `${module} CHANGE_PAGE`,
  RESET: `${module} RESET`,
  CHANGE_LIMIT: `${module} CHANGE_LIMIT`,
  CHANGE_CAN_LOAD_MORE: `${module} SET_CAN_LOAD_MORE`,
  UPDATE_ENTITY: `${module} UPDATE_ENTITY`,
});
