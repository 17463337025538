import { State } from './reducers';

export const selectRootLanguage = (state: State): string => state.root.lang;

export const selectRootLoading = (state: State): boolean => state.root.loading;

export const selectRootDemo = (state: State): boolean => state.root.demo;

export const selectRootIsEligibleStats = (state: State): boolean => state.root.isEligibleStats;

export const selectGlobalFilters = (state: State) => state.filters;

export const selectFiltersPresets = (state: State) => state.presets.filters;

export const selectPresetsLoading = (state: State) => state.presets.loading;

export const selectDatePickerComparator = (state: State) => state.datePickerComparator;
