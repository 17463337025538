import { ApiService } from 'common/services';

class AttributionApiService extends ApiService {
  getAttributionData = (brandId: number) => {
    return this.get(`/api/brands/${brandId}/attribution`);
  };

  getAttributionProfiles = (brandId: number) => {
    return this.get(`/api/brands/${brandId}/profiles`);
  };

  getAttributionPublishers = (brandId: number, profileId: string) => {
    return this.get(`/api/brands/${brandId}/attribution/profile/${profileId}/publishers`);
  };

  getAttributionStores = (brandId: number, profileId: string) => {
    return this.get(`/api/brands/${brandId}/attribution/profile/${profileId}/stores`);
  };

  getAttributionTag = (brandId: number, profileId: string, publisherId: string) => {
    return this.get(`/api/brands/${brandId}/attribution/profile/${profileId}/macro-tags`, {
      params: { publisher_ids: publisherId },
    });
  };

  postAttributionData = (data: BrandSettingsTypes.AttributionForm, brandId: number) => {
    return this.post(`/api/brands/${brandId}/attribution`, data);
  };

  patchAttributionData = (data: BrandSettingsTypes.AttributionForm, brandId: number, attributionId: number) => {
    return this.patch(`/api/brands/${brandId}/attribution/${attributionId}`, data);
  };

  getAsins = (brandId: number, profileId: string, page: string) => {
    return this.get(`/api/brands/${brandId}/profile/${profileId}/page-asins`, { params: { pageUrl: page } });
  };
}

export const attributionApi = new AttributionApiService(process.env.REACT_APP_API_URL);
