import { Button, CircularProgress, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { MouseEvent, ReactElement, useMemo, useState } from 'react';
import { theme } from 'common/constants/theme';
import GroupIcon from '@mui/icons-material/Group';
import { styled } from '@mui/material/styles';
import { Styles } from 'common/types';
import { selectFiltersPresets, selectPresetsLoading, selectRootDemo } from 'common/store/selectors';
import { transformBrandName } from 'common/utils/brand-name';
import { useSelector } from 'react-redux';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { BrandsPickerPopover } from './brands-picker-popover';

interface StylesProps {
  isDemoMode: boolean;
}

const BlurTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    filter: 'blur(3px)',
  },
}));

const getStyles = ({ isDemoMode }: StylesProps): Styles => ({
  publisherIcon: { marginRight: 1 },
  arrowIcon: { marginLeft: 1 },
  button: {
    color: theme.palette.primary.dark,
    borderRadius: 12,
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    ':hover': { backgroundColor: theme.palette.background.paper, border: 'none' },
    filter: isDemoMode ? 'blur(3px)' : 0,
  },
});

interface Props {
  selectedBrand: number | null | string;
  handleBrandChange: (brand: number | null | string) => void;
  shrinked: boolean;
}

export function BrandsPicker({ selectedBrand, handleBrandChange, shrinked }: Props): ReactElement {
  const filtersPresets = useSelector(selectFiltersPresets);
  const presetsLoading = useSelector(selectPresetsLoading);
  const isDemoMode = useSelector(selectRootDemo);

  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(null);

  const styles = getStyles({ isDemoMode });

  const selectedPublisherName = useMemo(() => {
    const brand = filtersPresets?.brands?.find(brand => Number(brand.id) === Number(selectedBrand));
    return brand ? transformBrandName(brand.name, brand.client_id) : '';
  }, [filtersPresets?.brands, selectedBrand]);

  const publisherName =
    selectedPublisherName?.length <= 8 ? selectedPublisherName : `${selectedPublisherName.slice(0, 8)}...`;

  const handlePublisherPickerOpen = (e: MouseEvent<HTMLButtonElement>) => {
    if (filtersPresets?.brands?.length <= 1) return;
    setAnchorElement(e.currentTarget);
  };

  const handlePublisherPickerClose = () => {
    setAnchorElement(null);
  };

  const brandPickerHeader = () => {
    const name = presetsLoading ? <CircularProgress size={14} /> : publisherName;

    return (
      <Button variant="outlined" onClick={handlePublisherPickerOpen} sx={styles.button}>
        <GroupIcon fontSize="small" sx={styles.publisherIcon} />
        {shrinked ? null : name}
        {!shrinked && <KeyboardArrowDownIcon fontSize="small" sx={styles.arrowIcon} />}
      </Button>
    );
  };

  return (
    <>
      {isDemoMode ? (
        <BlurTooltip title={selectedPublisherName}>{brandPickerHeader()}</BlurTooltip>
      ) : (
        <Tooltip title={selectedPublisherName}>{brandPickerHeader()}</Tooltip>
      )}
      <BrandsPickerPopover
        onClose={handlePublisherPickerClose}
        anchorElement={anchorElement}
        selectedBrand={selectedBrand}
        handleBrandChange={handleBrandChange}
      />
    </>
  );
}
