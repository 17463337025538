import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectGlobalFilters } from 'common/store/selectors';
import { selectBrand } from 'modules/brand-settings/store/selectors';
import { brandSettingsActions } from 'modules/brand-settings/store/actions';

export const useBrand = () => {
  const dispatch = useDispatch();

  const brand = useSelector(selectBrand);
  const globalFilters = useSelector(selectGlobalFilters);

  useEffect(() => {
    if (Number(brand?.id) === Number(globalFilters.brand_id) || !globalFilters?.brand_id) return;
    dispatch(brandSettingsActions.getBrand(globalFilters.brand_id));
  }, [dispatch, globalFilters.brand_id, brand?.id]);

  return brand;
};
