import { ApiService } from 'common/services';
import { IGetQueryString } from 'common/ui/base-filters/types';
import { parseFilters } from 'common/utils/parse-filters';

class AttributionPerformanceApiService extends ApiService {
  getWidgets = (params: IGetQueryString) => {
    return this.get('/api/v2/adipx/orders/performance', {
      params: parseFilters(params, {
        date_from: 'start_date',
        date_to: 'end_date',
      }),
    });
  };

  getOrders = (brandId: number, limit: number) => {
    return this.get('/api/orders', { params: { brand_id: brandId, limit } });
  };

  getAttributionPerformanceOrderExports = (params: IGetQueryString) => {
    return this.get('/api/orders/performance/exports', {
      params: parseFilters(params, {
        date_from: 'start_date',
        date_to: 'end_date',
      }),
    });
  };

  changeKeywordInfo = ({
    brandId,
    keywordId,
    cpc,
    status,
  }: {
    brandId: number;
    keywordId: string;
    cpc?: number;
    status?: string;
  }) => {
    return this.patch('/api/ads/entity-management/keyword', {
      brandID: brandId,
      keywordID: keywordId,
      bid: cpc === 0 || cpc ? { cpc } : null,
      status,
    });
  };

  changeCampaignStatus = (brandId: number, campaignId: string, status: string) => {
    return this.put('/api/ads/entity-management/campaign', { brandID: brandId, campaignID: campaignId, status });
  };

  changeAdgroupStatus = (brandId: number, adgroupId: string, status: string) => {
    return this.put('/api/ads/entity-management/adgroup', { brandID: brandId, adgroupID: adgroupId, status });
  };
}

export const attributionPerformanceApi = new AttributionPerformanceApiService(process.env.REACT_APP_API_URL);
