import { getDataListActionTypes } from 'common/store/actions/data-list';
import { getFiltersActionTypes } from 'common/store/actions/filters';

const ADMIN_MODULE = '[admin]';
const PUBLISHERS_MODULE = '[admin:publishers]';
const BRANDS_MODULE = '[admin:brands-table]';
const BRANDS_NOTE_MODULE = '[admin:brands-note]';
const BRAND_PARENTS_MODULE = '[admin:brand-parents]';
const BRANDS_EXPORT_MODULE = '[admin:brands-export]';
const INTEGRATIONS_MODULE = '[admin:integrations]';
const OVERVIEW_MODULE = '[admin:overview]';
const PERFORMANCE_TABLE_MODULE = '[admin:performance-table]';
const PENDING_BRANDS_MODULE = '[admin:pending-brands]';
const ORDERS_MODULE = '[admin:orders]';
const ORDERS_EXPORT_MODULE = '[admin:orders-export]';
const ADMINS_PAGE_MODULE = '[admin:admins-page]';
const PUB_MAVERICK_TABLE_MODULE = '[admin:pub-maverick]';
const PUB_MAVERICK_EXPORT_MODULE = '[admin:pub-maverick]';
const INVOICES_MODULE = '[admin:invoices]';
const MAV_PRODUCTS_MODULE = '[admin:mav-products]';
const MAV_PRODUCTS_EXPORT_MODULE = '[admin:mav-products-export]';
const MAV_ASINS_STATS_MODULE = '[admin:mav-asins-stats]';
const BRANDS_PERIODS_MODULE = '[admin:brands-periods]';
const BRANDS_PUBLISHERS_MODULE = '[admin:brands-publishers]';
const PUB_MAVERICK_TABLE_SUMMARY_MODULE = '[admin:pub-maverick-summary]';

const GET_BRANDS_EXPORTS = `${BRANDS_EXPORT_MODULE} GET_BRANDS_EXPORTS`;
const GET_BRANDS_EXPORTS_SUCCESS = `${BRANDS_EXPORT_MODULE} GET_BRANDS_EXPORTS_SUCCESS`;
const GET_BRANDS_EXPORTS_ERROR = `${BRANDS_EXPORT_MODULE} GET_BRANDS_EXPORTS_ERROR`;

const GET_BRANDS_PERFORMANCE_EXPORTS = `${BRANDS_EXPORT_MODULE} GET_BRANDS_PERFORMANCE_EXPORTS`;
const GET_BRANDS_PERFORMANCE_EXPORTS_SUCCESS = `${BRANDS_EXPORT_MODULE} GET_BRANDS_PERFORMANCE_EXPORTS_SUCCESS`;
const GET_BRANDS_PERFORMANCE_EXPORTS_ERROR = `${BRANDS_EXPORT_MODULE} GET_BRANDS_PERFORMANCE_EXPORTS_ERROR`;

const CREATE_BRAND = `${BRANDS_MODULE} CREATE_BRAND`;
const CREATE_BRAND_SUCCESS = `${BRANDS_MODULE} CREATE_BRAND_SUCCESS`;
const CREATE_BRAND_ERROR = `${BRANDS_MODULE} CREATE_BRAND_ERROR`;

const EDIT_BRAND = `${BRANDS_MODULE} EDIT_BRAND`;
const EDIT_BRAND_SUCCESS = `${BRANDS_MODULE} EDIT_BRAND_SUCCESS`;
const EDIT_BRAND_ERROR = `${BRANDS_MODULE} EDIT_BRAND_ERROR`;

const DELETE_BRAND = `${BRANDS_MODULE} DELETE_BRAND`;
const DELETE_BRAND_SUCCESS = `${BRANDS_MODULE} DELETE_BRAND_SUCCESS`;
const DELETE_BRAND_ERROR = `${BRANDS_MODULE} DELETE_BRAND_ERROR`;

const UPDATE_BRAND_BALANCE = `${BRANDS_MODULE} UPDATE_BRAND_BALANCE`;
const UPDATE_BRAND_BALANCE_SUCCESS = `${BRANDS_MODULE} UPDATE_BRAND_BALANCE_SUCCESS`;
const UPDATE_BRAND_BALANCE_ERROR = `${BRANDS_MODULE} UPDATE_BRAND_BALANCE_ERROR`;

const UPDATE_BRAND_ASIN_FEE = `${BRANDS_MODULE} UPDATE_BRAND_ASIN_FEE`;
const UPDATE_BRAND_ASIN_FEE_SUCCESS = `${BRANDS_MODULE} UPDATE_BRAND_ASIN_FEE_SUCCESS`;
const UPDATE_BRAND_ASIN_FEE_ERROR = `${BRANDS_MODULE} UPDATE_BRAND_ASIN_FEE_ERROR`;

const GET_UNIFIED_REPORTING = `${OVERVIEW_MODULE} GET_UNIFIED_REPORTING`;
const GET_UNIFIED_REPORTING_SUCCESS = `${OVERVIEW_MODULE} GET_UNIFIED_REPORTING_SUCCESS`;
const GET_UNIFIED_REPORTING_ERROR = `${OVERVIEW_MODULE} GET_UNIFIED_REPORTING_ERROR`;

const BRAND_SEARCH_VALUE_CHANGE = `${OVERVIEW_MODULE} BRAND_SEARCH_VALUE_CHANGE`;

const GENERATE_INTEGRATION_LINK = `${INTEGRATIONS_MODULE} GENERATE_INTEGRATION_LINK`;
const GENERATE_INTEGRATION_LINK_SUCCESS = `${INTEGRATIONS_MODULE} GENERATE_INTEGRATION_LINK_SUCCESS`;
const GENERATE_INTEGRATION_LINK_ERROR = `${INTEGRATIONS_MODULE} GENERATE_INTEGRATION_LINK_ERROR`;

const GENERATE_ATTR_TAG = `${INTEGRATIONS_MODULE} GENERATE_ATTR_TAG`;
const GENERATE_ATTR_TAG_SUCCESS = `${INTEGRATIONS_MODULE} GENERATE_ATTR_TAG_SUCCESS`;
const GENERATE_ATTR_TAG_ERROR = `${INTEGRATIONS_MODULE} GENERATE_ATTR_TAG_ERROR`;

const GET_ORDER_HISTORY = `${ORDERS_MODULE} GET_ORDER_HISTORY`;
const GET_ORDER_HISTORY_SUCCESS = `${ORDERS_MODULE} GET_ORDER_HISTORY_SUCCESS`;
const GET_ORDER_HISTORY_ERROR = `${ORDERS_MODULE} GET_ORDER_HISTORY_ERROR`;

const ADD_BRANDS_PARENTS = `${BRAND_PARENTS_MODULE} ADD_BRANDS_PARENTS`;
const PATCH_BRANDS_PARENTS = `${BRAND_PARENTS_MODULE} PATCH_BRANDS_PARENTS`;
const DELETE_BRANDS_PARENTS = `${BRAND_PARENTS_MODULE} DELETE_BRANDS_PARENTS`;

const GET_ORDERS_EXPORTS = `${ORDERS_EXPORT_MODULE} GET_ORDERS_EXPORTS`;
const GET_ORDERS_EXPORTS_SUCCESS = `${ORDERS_EXPORT_MODULE} GET_ORDERS_EXPORTS_SUCCESS`;
const GET_ORDERS_EXPORTS_ERROR = `${ORDERS_EXPORT_MODULE} GET_ORDERS_EXPORTS_ERROR`;

const ADD_BRAND_NOTE = `${BRANDS_NOTE_MODULE} ADD_BRAND_NOTE`;
const ADD_BRAND_NOTE_SUCCESS = `${BRANDS_NOTE_MODULE} ADD_BRAND_NOTE_SUCCESS`;
const ADD_BRAND_NOTE_ERROR = `${BRANDS_NOTE_MODULE} ADD_BRAND_NOTE_ERROR`;

const EDIT_BRAND_NOTE = `${BRANDS_NOTE_MODULE} EDIT_BRAND_NOTE`;
const EDIT_BRAND_NOTE_SUCCESS = `${BRANDS_NOTE_MODULE} EDIT_BRAND_NOTE_SUCCESS`;
const EDIT_BRAND_NOTE_ERROR = `${BRANDS_NOTE_MODULE} EDIT_BRAND_NOTE_ERROR`;

const DELETE_BRAND_NOTE = `${BRANDS_NOTE_MODULE} DELETE_BRAND_NOTE`;
const DELETE_BRAND_NOTE_SUCCESS = `${BRANDS_NOTE_MODULE} DELETE_BRAND_NOTE_SUCCESS`;
const DELETE_BRAND_NOTE_ERROR = `${BRANDS_NOTE_MODULE} DELETE_BRAND_NOTE_ERROR`;

const POST_COPY_INTEGRATION = `${BRANDS_NOTE_MODULE} POST_COPY_INTEGRATION`;
const POST_COPY_INTEGRATION_SUCCESS = `${BRANDS_NOTE_MODULE} POST_COPY_INTEGRATION_SUCCESS`;
const POST_COPY_INTEGRATION_ERROR = `${BRANDS_NOTE_MODULE} POST_COPY_INTEGRATION_ERROR`;

const DELETE_ADMIN_ROLE = `${ADMINS_PAGE_MODULE} DELETE_ADMIN_ROLE`;
const DELETE_ADMIN_ROLE_SUCCESS = `${ADMINS_PAGE_MODULE} DELETE_ADMIN_ROLE_SUCCESS`;
const DELETE_ADMIN_ROLE_ERROR = `${ADMINS_PAGE_MODULE} DELETE_ADMIN_ROLE_ERROR`;

const INVITE_ADMIN_MEMBER = `${ADMINS_PAGE_MODULE} INVITE_ADMIN_MEMBER`;
const INVITE_ADMIN_MEMBER_SUCCESS = `${ADMINS_PAGE_MODULE} INVITE_ADMIN_MEMBER_SUCCESS`;
const INVITE_ADMIN_MEMBER_ERROR = `${ADMINS_PAGE_MODULE} INVITE_ADMIN_MEMBER_ERROR`;

const POST_NEW_ADMIN = `${ADMINS_PAGE_MODULE} POST_NEW_ADMIN`;
const POST_NEW_ADMIN_SUCCESS = `${ADMINS_PAGE_MODULE} POST_NEW_ADMIN_SUCCESS`;
const POST_NEW_ADMIN_ERROR = `${ADMINS_PAGE_MODULE} POST_NEW_ADMIN_ERROR`;

const CHARGE_BRAND = `${ADMINS_PAGE_MODULE} CHARGE_BRAND`;

const GET_PUB_MAV_PERFORMANCE_EXPORTS = `${PUB_MAVERICK_EXPORT_MODULE} GET_PUB_MAV_PERFORMANCE_EXPORTS`;
const GET_PUB_MAV_PERFORMANCE_EXPORTS_SUCCESS = `${PUB_MAVERICK_EXPORT_MODULE} GET_PUB_MAV_PERFORMANCE_EXPORTS_SUCCESS`;
const GET_PUB_MAV_PERFORMANCE_EXPORTS_ERROR = `${PUB_MAVERICK_EXPORT_MODULE} GET_PUB_MAV_PERFORMANCE_EXPORTS_ERROR`;

const GET_MAV_PERFORMANCE_SUMMARY = `${PUB_MAVERICK_TABLE_SUMMARY_MODULE} GET_MAV_PERFORMANCE_SUMMARY`;
const GET_MAV_PERFORMANCE_SUMMARY_SUCCESS = `${PUB_MAVERICK_TABLE_SUMMARY_MODULE} GET_MAV_PERFORMANCE_SUMMARY_SUCCESS`;
const GET_MAV_PERFORMANCE_SUMMARY_ERROR = `${PUB_MAVERICK_TABLE_SUMMARY_MODULE} GET_MAV_PERFORMANCE_SUMMARY_ERROR`;

const GET_INVOICES = `${INVOICES_MODULE} GET_INVOICES`;
const GET_INVOICES_SUCCESS = `${INVOICES_MODULE} GET_INVOICES_SUCCESS`;
const GET_INVOICES_ERROR = `${INVOICES_MODULE} GET_INVOICES_ERROR`;

const CREATE_INVOICE = `${INVOICES_MODULE} CREATE_INVOICE`;
const CREATE_INVOICE_SUCCESS = `${INVOICES_MODULE} CREATE_INVOICE_SUCCESS`;
const CREATE_INVOICE_ERROR = `${INVOICES_MODULE} CREATE_INVOICE_ERROR`;

const GET_INVOICE_BY_ID = `${INVOICES_MODULE} GET_INVOICE_BY_ID`;
const GET_INVOICE_BY_ID_SUCCESS = `${INVOICES_MODULE} GET_INVOICE_BY_ID_SUCCESS`;
const GET_INVOICE_BY_ID_ERROR = `${INVOICES_MODULE} GET_INVOICE_BY_ID_ERROR`;

const CHARGE_INVOICE = `${INVOICES_MODULE} CHARGE_INVOICE`;
const CHARGE_INVOICE_SUCCESS = `${INVOICES_MODULE} CHARGE_INVOICE_SUCCESS`;
const CHARGE_INVOICE_ERROR = `${INVOICES_MODULE} CHARGE_INVOICE_ERROR`;

const RESET_INVOICE_DATA = `${INVOICES_MODULE} RESET_INVOICE_DATA`;

const MAV_PRODUCTS_EXPORTS = `${MAV_PRODUCTS_EXPORT_MODULE} MAV_PRODUCTS_EXPORTS`;
const MAV_PRODUCTS_EXPORTS_SUCCESS = `${MAV_PRODUCTS_EXPORT_MODULE} MAV_PRODUCTS_EXPORTS_SUCCESS`;
const MAV_PRODUCTS_EXPORTS_ERROR = `${MAV_PRODUCTS_EXPORT_MODULE} MAV_PRODUCTS_EXPORTS_ERROR`;

const GET_MAV_ASINS_STATS = `${MAV_ASINS_STATS_MODULE} GET_MAV_ASINS_STATS`;
const GET_MAV_ASINS_STATS_SUCCESS = `${MAV_ASINS_STATS_MODULE} GET_MAV_ASINS_STATS_SUCCESS`;
const GET_MAV_ASINS_STATS_ERROR = `${MAV_ASINS_STATS_MODULE} GET_MAV_ASINS_STATS_ERROR`;

const MAV_INTERNAL_PRODUCTS_EXPORTS = `${MAV_PRODUCTS_EXPORT_MODULE} MAV_INTERNAL_PRODUCTS_EXPORTS`;
const MAV_INTERNAL_PRODUCTS_EXPORTS_SUCCESS = `${MAV_PRODUCTS_EXPORT_MODULE} MAV_INTERNAL_PRODUCTS_EXPORTS_SUCCESS`;
const MAV_INTERNAL_PRODUCTS_EXPORTS_ERROR = `${MAV_PRODUCTS_EXPORT_MODULE} MAV_INTERNAL_PRODUCTS_EXPORTS_ERROR`;

const PATCH_BRANDS_SETTINGS_PERIODS = `${BRANDS_PERIODS_MODULE} PATCH_BRANDS_SETTINGS_PERIODS`;

const POST_BRANDS_SETTINGS_PERIOD = `${BRANDS_PERIODS_MODULE} POST_BRANDS_SETTINGS_PERIOD`;

const DELETE_BRANDS_SETTINGS_PERIOD = `${BRANDS_PERIODS_MODULE} DELETE_BRANDS_SETTINGS_PERIOD`;

const GET_BRANDS_PUBLISHERS = `${BRANDS_PUBLISHERS_MODULE} GET_BRANDS_PUBLISHERS`;
const GET_BRANDS_PUBLISHERS_SUCCESS = `${BRANDS_PUBLISHERS_MODULE} GET_BRANDS_PUBLISHERS_SUCCESS`;
const GET_BRANDS_PUBLISHERS_ERROR = `${BRANDS_PUBLISHERS_MODULE} GET_BRANDS_PUBLISHERS_ERROR`;

const ADD_BRANDS_PUBLISHERS = `${BRANDS_PUBLISHERS_MODULE} ADD_BRANDS_PUBLISHERS`;
const ADD_BRANDS_PUBLISHERS_SUCCESS = `${BRANDS_PUBLISHERS_MODULE} ADD_BRANDS_PUBLISHERS_SUCCESS`;
const ADD_BRANDS_PUBLISHERS_ERROR = `${BRANDS_PUBLISHERS_MODULE} ADD_BRANDS_PUBLISHERS_ERROR`;

const DELETE_BRANDS_PUBLISHERS = `${BRANDS_PUBLISHERS_MODULE} DELETE_BRANDS_PUBLISHERS`;
const DELETE_BRANDS_PUBLISHERS_SUCCESS = `${BRANDS_PUBLISHERS_MODULE} DELETE_BRANDS_PUBLISHERS_SUCCESS`;
const DELETE_BRANDS_PUBLISHERS_ERROR = `${BRANDS_PUBLISHERS_MODULE} DELETE_BRANDS_PUBLISHERS_ERROR`;
const SWITCH_BRAND_PRODUCTS_MAV_ELIGIBLE_STATUS = `${BRANDS_MODULE} SWITCH_BRAND_PRODUCTS_MAV_ELIGIBLE_STATUS`;

export const performanceTableActionTypes = getDataListActionTypes(PERFORMANCE_TABLE_MODULE);
export const adminBrandsListActionTypes = getDataListActionTypes(BRANDS_MODULE);
export const adminBrandsFiltersActionTypes = getFiltersActionTypes(BRANDS_MODULE);
export const pendingBrandsActionTypes = getDataListActionTypes(PENDING_BRANDS_MODULE);
export const adminFiltersActionTypes = getFiltersActionTypes(ADMIN_MODULE);
export const ordersFiltersActionTypes = getFiltersActionTypes(ORDERS_MODULE);
export const ordersListActionTypes = getDataListActionTypes(ORDERS_MODULE);
export const performanceFiltersActionTypes = getFiltersActionTypes(PERFORMANCE_TABLE_MODULE);
export const brandParentsActionTypes = getDataListActionTypes(BRAND_PARENTS_MODULE);
export const brandNoteActionTypes = getDataListActionTypes(BRANDS_NOTE_MODULE);
export const publishersActionTypes = getDataListActionTypes(PUBLISHERS_MODULE);
export const adminsPageActionTypes = getDataListActionTypes(ADMINS_PAGE_MODULE);
export const pubMaverickTableActionTypes = getDataListActionTypes(PUB_MAVERICK_TABLE_MODULE);
export const mavProductsActionTypes = getDataListActionTypes(MAV_PRODUCTS_MODULE);
export const mavProductsFiltersActionTypes = getFiltersActionTypes(MAV_PRODUCTS_MODULE);
export const brandsProductsActionTypes = getDataListActionTypes(BRANDS_PERIODS_MODULE);

export const adminActionTypes = {
  GET_BRANDS_EXPORTS,
  GET_BRANDS_EXPORTS_SUCCESS,
  GET_BRANDS_EXPORTS_ERROR,
  CREATE_BRAND,
  CREATE_BRAND_SUCCESS,
  CREATE_BRAND_ERROR,
  EDIT_BRAND,
  EDIT_BRAND_SUCCESS,
  EDIT_BRAND_ERROR,
  DELETE_BRAND,
  DELETE_BRAND_SUCCESS,
  DELETE_BRAND_ERROR,
  GENERATE_INTEGRATION_LINK,
  UPDATE_BRAND_BALANCE,
  UPDATE_BRAND_BALANCE_SUCCESS,
  UPDATE_BRAND_BALANCE_ERROR,
  GET_UNIFIED_REPORTING,
  GET_UNIFIED_REPORTING_SUCCESS,
  GET_UNIFIED_REPORTING_ERROR,
  GET_ORDER_HISTORY,
  GET_ORDER_HISTORY_SUCCESS,
  GET_ORDER_HISTORY_ERROR,
  BRAND_SEARCH_VALUE_CHANGE,
  GET_BRANDS_PERFORMANCE_EXPORTS,
  GET_BRANDS_PERFORMANCE_EXPORTS_SUCCESS,
  GET_BRANDS_PERFORMANCE_EXPORTS_ERROR,
  ADD_BRANDS_PARENTS,
  PATCH_BRANDS_PARENTS,
  DELETE_BRANDS_PARENTS,
  UPDATE_BRAND_ASIN_FEE,
  UPDATE_BRAND_ASIN_FEE_SUCCESS,
  UPDATE_BRAND_ASIN_FEE_ERROR,
  GET_ORDERS_EXPORTS,
  GET_ORDERS_EXPORTS_SUCCESS,
  GET_ORDERS_EXPORTS_ERROR,
  ADD_BRAND_NOTE,
  EDIT_BRAND_NOTE,
  DELETE_BRAND_NOTE,
  ADD_BRAND_NOTE_SUCCESS,
  ADD_BRAND_NOTE_ERROR,
  EDIT_BRAND_NOTE_SUCCESS,
  EDIT_BRAND_NOTE_ERROR,
  DELETE_BRAND_NOTE_SUCCESS,
  DELETE_BRAND_NOTE_ERROR,
  POST_COPY_INTEGRATION,
  POST_COPY_INTEGRATION_SUCCESS,
  POST_COPY_INTEGRATION_ERROR,
  GENERATE_ATTR_TAG,
  GENERATE_INTEGRATION_LINK_SUCCESS,
  GENERATE_INTEGRATION_LINK_ERROR,
  GENERATE_ATTR_TAG_SUCCESS,
  GENERATE_ATTR_TAG_ERROR,
  DELETE_ADMIN_ROLE,
  DELETE_ADMIN_ROLE_SUCCESS,
  DELETE_ADMIN_ROLE_ERROR,
  INVITE_ADMIN_MEMBER,
  INVITE_ADMIN_MEMBER_SUCCESS,
  INVITE_ADMIN_MEMBER_ERROR,
  POST_NEW_ADMIN,
  POST_NEW_ADMIN_SUCCESS,
  POST_NEW_ADMIN_ERROR,
  CHARGE_BRAND,
  GET_PUB_MAV_PERFORMANCE_EXPORTS,
  GET_PUB_MAV_PERFORMANCE_EXPORTS_SUCCESS,
  GET_PUB_MAV_PERFORMANCE_EXPORTS_ERROR,
  GET_MAV_PERFORMANCE_SUMMARY,
  GET_MAV_PERFORMANCE_SUMMARY_SUCCESS,
  GET_MAV_PERFORMANCE_SUMMARY_ERROR,
  GET_INVOICES,
  GET_INVOICES_SUCCESS,
  GET_INVOICES_ERROR,
  CREATE_INVOICE,
  CREATE_INVOICE_SUCCESS,
  CREATE_INVOICE_ERROR,
  GET_INVOICE_BY_ID,
  GET_INVOICE_BY_ID_SUCCESS,
  GET_INVOICE_BY_ID_ERROR,
  CHARGE_INVOICE,
  CHARGE_INVOICE_SUCCESS,
  CHARGE_INVOICE_ERROR,
  RESET_INVOICE_DATA,
  MAV_PRODUCTS_EXPORTS,
  MAV_PRODUCTS_EXPORTS_SUCCESS,
  MAV_PRODUCTS_EXPORTS_ERROR,
  GET_MAV_ASINS_STATS,
  GET_MAV_ASINS_STATS_SUCCESS,
  GET_MAV_ASINS_STATS_ERROR,
  MAV_INTERNAL_PRODUCTS_EXPORTS,
  MAV_INTERNAL_PRODUCTS_EXPORTS_SUCCESS,
  MAV_INTERNAL_PRODUCTS_EXPORTS_ERROR,
  PATCH_BRANDS_SETTINGS_PERIODS,
  POST_BRANDS_SETTINGS_PERIOD,
  DELETE_BRANDS_SETTINGS_PERIOD,
  GET_BRANDS_PUBLISHERS,
  GET_BRANDS_PUBLISHERS_SUCCESS,
  GET_BRANDS_PUBLISHERS_ERROR,
  ADD_BRANDS_PUBLISHERS,
  ADD_BRANDS_PUBLISHERS_SUCCESS,
  ADD_BRANDS_PUBLISHERS_ERROR,
  DELETE_BRANDS_PUBLISHERS,
  DELETE_BRANDS_PUBLISHERS_SUCCESS,
  DELETE_BRANDS_PUBLISHERS_ERROR,
  SWITCH_BRAND_PRODUCTS_MAV_ELIGIBLE_STATUS,
};
