import {
  PropsWithChildren,
  ReactElement,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';

export const menuCtx = createContext({
  open: true,
  timedOpen: true,
  hovered: true,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setHovered: (hovered: boolean) => undefined,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setOpen: (open: boolean) => undefined,
});

export function MenuCtx({ children }: PropsWithChildren): ReactElement {
  const location = useLocation();

  const isAdminPath = location.pathname.includes('/admin');

  const [open, setOpen] = useState<boolean>(!isAdminPath);
  const [timedOpen, setTimedOpen] = useState<boolean>(open);
  const [hovered, setHovered] = useState<boolean>(false);

  const openTimeout = useRef<number>();

  const handleSetOpen = useCallback(
    (open: boolean) => {
      setOpen(!isAdminPath ? true : open);
    },
    [isAdminPath]
  );

  useEffect(() => {
    window.clearTimeout(openTimeout.current);

    if (!isAdminPath) {
      setTimedOpen(true);
      return;
    }

    if (open) {
      openTimeout.current = window.setTimeout(() => {
        setTimedOpen(true);
      }, 180);
    } else {
      setTimedOpen(false);
    }
  }, [isAdminPath, open]);

  useEffect(() => {
    if (!isAdminPath) setOpen(true);
  }, [isAdminPath]);

  const value = useMemo(() => {
    return {
      open,
      setOpen: handleSetOpen,
      timedOpen,
      hovered,
      setHovered,
    };
  }, [handleSetOpen, hovered, open, timedOpen]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <menuCtx.Provider value={value}>{children}</menuCtx.Provider>;
}
