import { getDataListActionTypes } from 'common/store/actions/data-list';
import { getFiltersActionTypes } from 'common/store/actions/filters';

const ORDERS_MODULE = '[attr-performance:orders]';
const ORDERS_PRODUCTS_MODULE = '[attr-performance:orders-products]';
const ORDER_LINKS_MODULE = '[attr-performance:order-links]';

const CREATE_ORDER = `${ORDERS_MODULE} CREATE_ORDER`;
const CREATE_ORDER_SUCCESS = `${ORDERS_MODULE} CREATE_ORDER_SUCCESS`;
const CREATE_ORDER_ERROR = `${ORDERS_MODULE} CREATE_ORDER_ERROR`;

const CREATE_ORDER_WITH_REQUEST = `${ORDERS_MODULE} CREATE_ORDER_WITH_REQUEST`;
const CREATE_ORDER_WITH_REQUEST_SUCCESS = `${ORDERS_MODULE} CREATE_ORDER_WITH_REQUEST_SUCCESS`;
const CREATE_ORDER_WITH_REQUEST_ERROR = `${ORDERS_MODULE} CREATE_ORDER_WITH_REQUEST_ERROR`;

const PATCH_ORDER = `${ORDERS_MODULE} PATCH_ORDER`;
const PATCH_ORDER_SUCCESS = `${ORDERS_MODULE} PATCH_ORDER_SUCCESS`;
const PATCH_ORDER_ERROR = `${ORDERS_MODULE} PATCH_ORDER_ERROR`;

const DELETE_ORDER = `${ORDERS_MODULE} DELETE_ORDER`;
const DELETE_ORDER_SUCCESS = `${ORDERS_MODULE} DELETE_ORDER_SUCCESS`;
const DELETE_ORDER_ERROR = `${ORDERS_MODULE} DELETE_ORDER_ERROR`;

const GET_TOTAL_ORDERS = `${ORDERS_MODULE} GET_TOTAL_ORDERS`;
const GET_TOTAL_ORDERS_SUCCESS = `${ORDERS_MODULE} GET_TOTAL_ORDERS_SUCCESS`;
const GET_TOTAL_ORDERS_ERROR = `${ORDERS_MODULE} GET_TOTAL_ORDERS_ERROR`;

export const attributionOrdersActionTypes = getDataListActionTypes(ORDERS_MODULE);
export const attributionOrdersForProductsActionTypes = getDataListActionTypes(ORDERS_PRODUCTS_MODULE);
export const attributionOrdersFiltersActionTypes = getFiltersActionTypes(ORDERS_MODULE);
export const orderLinksActionTypes = getDataListActionTypes(ORDER_LINKS_MODULE);

export const ordersActionTypes = {
  CREATE_ORDER,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_ERROR,
  PATCH_ORDER,
  PATCH_ORDER_SUCCESS,
  PATCH_ORDER_ERROR,
  GET_TOTAL_ORDERS,
  GET_TOTAL_ORDERS_SUCCESS,
  GET_TOTAL_ORDERS_ERROR,
  DELETE_ORDER,
  DELETE_ORDER_SUCCESS,
  DELETE_ORDER_ERROR,
  CREATE_ORDER_WITH_REQUEST,
  CREATE_ORDER_WITH_REQUEST_SUCCESS,
  CREATE_ORDER_WITH_REQUEST_ERROR,
};
