import { ApiService } from 'common/services';
import { IGetQueryString } from 'common/ui/base-filters/types';
import { parseFilters } from 'common/utils/parse-filters';

class CampaignCreationApiService extends ApiService {
  getOrders = (brandId: number, limit: number) => {
    return this.get('/api/orders', { params: { brand_id: brandId, limit } });
  };

  getHeadlines = (filters: IGetQueryString) => {
    return this.post('/api/v1/tppx/ad/headlines', { asin: filters.asin });
  };

  getDescriptions = (filters: IGetQueryString) => {
    return this.post('/api/v1/tppx/ad/descriptions', { asin: filters.asin });
  };

  getKeywords = (filters: IGetQueryString) => {
    return this.post('/api/v1/tppx/ad/keywords', { asin: filters.asin, brand_id: filters.brand_id });
  };

  getCampaigns = (filters: IGetQueryString) => {
    return this.get('/api/v2/adipx/entity-management/suggestions', { params: parseFilters(filters) });
  };

  getPrecalculatedForm = (data: CampaignCreationTypes.PrecalculatedFormBody) => {
    return this.post('/api/v2/adipx/entity-management/precalculate-ad-stack', data);
  };

  postCampaign = (data: CampaignCreationTypes.CampaignCreationBody) => {
    return this.post('/api/v2/adipx/entity-management/ad-stack', data);
  };
}

export const campaignCreationApi = new CampaignCreationApiService(process.env.REACT_APP_API_URL);
