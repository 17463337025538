import { getDataListActionTypes } from 'common/store/actions/data-list';

const CAMPAIGN_MODULE = '[campaign-creation]';
const ORDERS_MODULE = '[campaign-creation:orders]';
const HEADLINES_MODULE = '[campaign-creation:headlines]';
const DESCRIPTIONS_MODULE = '[campaign-creation:descriptions]';
const KEYWORDS_MODULE = '[campaign-creation:keywords]';
const CAMPAIGNS_MODULE = '[campaign-creation:campaigns]';
const PRECALCULATED_FORM_MODULE = '[campaign-creation:precalculated]';

const GET_PRECALCULATED_FORM = `${PRECALCULATED_FORM_MODULE} GET_PRECALCULATED_FORM`;
const GET_PRECALCULATED_FORM_SUCCESS = `${PRECALCULATED_FORM_MODULE} GET_PRECALCULATED_FORM_SUCCESS`;
const GET_PRECALCULATED_FORM_ERROR = `${PRECALCULATED_FORM_MODULE} GET_PRECALCULATED_FORM_ERROR`;

const POST_CAMPAIGN = `${CAMPAIGN_MODULE} POST_CAMPAIGN`;
const POST_CAMPAIGN_SUCCESS = `${CAMPAIGN_MODULE} POST_CAMPAIGN_SUCCESS`;
const POST_CAMPAIGN_ERROR = `${CAMPAIGN_MODULE} POST_CAMPAIGN_ERROR`;

export const ordersActionTypes = getDataListActionTypes(ORDERS_MODULE);
export const headlinesActionTypes = getDataListActionTypes(HEADLINES_MODULE);
export const descriptionsActionTypes = getDataListActionTypes(DESCRIPTIONS_MODULE);
export const keywordsActionTypes = getDataListActionTypes(KEYWORDS_MODULE);
export const campaignsActionTypes = getDataListActionTypes(CAMPAIGNS_MODULE);

export const campaignCreationActionTypes = {
  GET_PRECALCULATED_FORM,
  GET_PRECALCULATED_FORM_SUCCESS,
  GET_PRECALCULATED_FORM_ERROR,
  POST_CAMPAIGN,
  POST_CAMPAIGN_SUCCESS,
  POST_CAMPAIGN_ERROR,
};
