import { Action } from 'common/interfaces/root';
import { adminActionTypes as T } from '../actions';

const initState: AdminTypes.ExportStoreTypes = {
  loading: false,
  error: null,
  link: '',
};

export default (
  state: AdminTypes.ExportStoreTypes = initState,
  { type, payload }: Action
): AdminTypes.ExportStoreTypes => {
  switch (type) {
    case T.GET_ORDERS_EXPORTS:
      return { ...state, loading: true, link: '', error: null };

    case T.GET_ORDERS_EXPORTS_SUCCESS:
      return { ...state, loading: false, link: payload };

    case T.GET_ORDERS_EXPORTS_ERROR:
      return { ...state, loading: false, error: payload };

    default:
      return state;
  }
};
