import { QUICK_START, QUICK_START_BRAND } from 'common/constants/lc';
import { getProtectedRoutes, getUnProtectedRoutes } from 'common/constants/routes';
import { putDemoMode, putIsEligibleStats, putLanguage } from 'common/store/actions';
import { presetsActions } from 'common/store/actions/presets';
import { selectFiltersPresets, selectGlobalFilters, selectRootLanguage } from 'common/store/selectors';
import { changeBrand } from 'common/utils/change-brand';
import { selectBrand } from 'modules/brand-settings/store/selectors';
import { Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { chechIsRouteUnprotected } from 'root/auth';
import { Layout } from 'root/layout';
import { LoadingContainer } from 'root/layout/loading-container';
import { Onboarding } from 'root/layout/onboarding';

export function App() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const language = useSelector(selectRootLanguage);
  const presets = useSelector(selectFiltersPresets);
  const globalFilters = useSelector(selectGlobalFilters);
  const brand = useSelector(selectBrand);

  const protectedRoutes = getProtectedRoutes(brand?.mav_plus_ui_surfaced).filter(route => route.isAvailable);
  const unprotectedRoutes = getUnProtectedRoutes();

  useEffect(() => {
    if (!language) return;
    i18n.changeLanguage(language);
  }, [language, i18n]);

  useEffect(() => {
    const lang = localStorage.getItem('lang');
    dispatch(putLanguage(lang || i18n.language));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n]);

  useEffect(() => {
    const isDemoModeLS = localStorage.getItem('demo') === '1';
    const isEligibleStatsLS = localStorage.getItem('isEligibleStats') === '1';
    dispatch(putDemoMode(isDemoModeLS));
    dispatch(putIsEligibleStats(isEligibleStatsLS));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (presets || chechIsRouteUnprotected(window.location.pathname)) return;

    dispatch(presetsActions.getFiltersPresets());
  }, [presets, dispatch]);

  useEffect(() => {
    if (!globalFilters?.brand_id) return;
    dispatch(presetsActions.getTimeInfo(globalFilters.brand_id));
  }, [dispatch, globalFilters.brand_id]);

  useEffect(() => {
    const qs = localStorage.getItem(QUICK_START);
    const brand = localStorage.getItem(QUICK_START_BRAND);
    if (qs) {
      changeBrand(brand, globalFilters, dispatch);
      navigate('/brand/integrations');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Onboarding>
      <Routes>
        <Route element={<Layout />}>
          {protectedRoutes.map(route => (
            <Route
              path={route.path}
              key={route.path}
              element={
                <Suspense fallback={<LoadingContainer type="fullwidth" />}>
                  <route.component />
                </Suspense>
              }
            />
          ))}
        </Route>
        {unprotectedRoutes.map(route => (
          <Route path={route.path} key={route.path} element={<route.component />} />
        ))}
      </Routes>
    </Onboarding>
  );
}
