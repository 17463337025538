// eslint-disable-next-line import/no-extraneous-dependencies
import { Step } from 'react-joyride';
import { onboardingTargets } from 'common/constants/onboarding';

const commonOptions: Partial<Step> = {
  disableBeacon: true,
  placement: 'right-start',
};

export const getOnboardingSteps = (): Array<Step> => [
  {
    target: `.${onboardingTargets.dashboard}`,
    title: 'Dashboard',
    content: `The dashboard helps you gain a high level understanding of how our marketing efforts are driving traffic and conversion for your products on amazon. 
      New orders can also be created directly from the dashboard UI.`,
    ...commonOptions,
  },
  {
    target: `.${onboardingTargets.attributionPerformance}`,
    title: 'Attribution performance',
    content: `With the comprehensive attribution performance dashboard, you'll gain a deeper understanding of how each Ad is performing  with a breakdown by campaign, ad groups and keyword level data.
    `,
    ...commonOptions,
  },
  {
    target: `.${onboardingTargets.products}`,
    title: 'Products',
    content: `The products tab houses all the ASINs imported from the amazon store front link provided during sign up. you can create orders directly from this tab as well as set the ASIN as mav+ eligible or not.
    `,
    ...commonOptions,
  },
  {
    target: `.${onboardingTargets.brandSettings}`,
    title: 'Brand settings',
    content: 'Make changes to your profile through the brand settings tab.',
    ...commonOptions,
  },
];
