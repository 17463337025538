import { State } from 'common/store/reducers';

export const selectOrdersList = (state: State) => state.orders.list.data;

export const selectOrdersLoading = (state: State) => state.orders.list.loading;

export const selectOrdersForProductsList = (state: State) => state.orders.listForProducts.data;

export const selectOrdersForProductsLoading = (state: State) => state.orders.listForProducts.loading;

export const selectOrdersPage = (state: State) => state.orders.list.page;

export const selectOrdersLimit = (state: State) => state.orders.list.limit;

export const selectOrdersAmount = (state: State) => state.orders.list.amount;

export const selectOrdersTotal = (state: State) => ({
  combined: state.orders.total.combined,
});

export const selectOrdersTotalLoading = (state: State) => state.orders.total.loading;

export const selectOrdersFilters = (state: State) => state.orders.filters;

export const selectOrderLinks = (state: State) => state.orders.links.data;

export const selectOrderLinksLoading = (state: State) => state.orders.links.loading;
