import { Action } from 'common/interfaces/root';
import { RESET_REDUCER } from 'common/store/actions';
import { OrdersStore } from 'common/ui/create-order-modal/types';
import { ordersActionTypes as T } from '../actions';

const initState: OrdersStore = {
  combined: null,
  error: null,
  loading: true,
};

export default (state: OrdersStore = initState, { type, payload }: Action): OrdersStore => {
  switch (type) {
    case T.GET_TOTAL_ORDERS:
      return { ...state, loading: true };

    case T.GET_TOTAL_ORDERS_SUCCESS:
      return { ...state, loading: false, combined: payload.combined };

    case T.GET_TOTAL_ORDERS_ERROR:
      return { ...state, loading: false, error: payload };

    case RESET_REDUCER:
      return { ...initState };

    default:
      return state;
  }
};
