/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { IGetQueryString } from 'common/ui/base-filters/types';

export interface GetConfig {
  reinit?: boolean;
  attempt?: number;
}

export const getDataListActions = <D>(actionTypes: Record<string, string>) => ({
  get: (params: IGetQueryString, config?: GetConfig) => ({
    type: actionTypes.GET,
    payload: params,
    meta: config,
  }),

  getSuccess: (data: Array<D>, amount?: number, meta?: any) => ({
    type: actionTypes.GET_SUCCESS,
    payload: { data, amount, meta },
  }),

  getSuccessLazy: (data: Array<D>, amount?: number, meta?: any) => ({
    type: actionTypes.GET_SUCCESS_LAZY,
    payload: { data, amount, meta },
  }),

  delete: (id: any) => ({
    type: actionTypes.DELETE,
    payload: id,
  }),

  deleteSuccess: (id: any, field: string) => ({
    type: actionTypes.DELETE_SUCCESS,
    payload: { id, field },
  }),

  deleteError: (error: Error) => ({
    type: actionTypes.DELETE_ERROR,
    payload: error,
  }),

  getError: (error: Error) => ({
    type: actionTypes.GET_ERROR,
    payload: JSON.stringify(error),
  }),

  changePage: (page: number) => ({
    type: actionTypes.CHANGE_PAGE,
    payload: page,
  }),

  changeLimit: (limit: number) => ({
    type: actionTypes.CHANGE_LIMIT,
    payload: limit,
  }),

  changeCanLoadMore: (canLoadMore: boolean) => ({
    type: actionTypes.CHANGE_CAN_LOAD_MORE,
    payload: canLoadMore,
  }),

  updateEntity: (field: string, id: any, updatedFields: Partial<D>) => ({
    type: actionTypes.UPDATE_ENTITY,
    payload: { field, id, updatedFields },
  }),

  reset: () => ({
    type: actionTypes.RESET,
  }),
});
