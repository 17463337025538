import { Action } from 'common/interfaces/root';
import { campaignCreationActionTypes as T } from '../actions';

const initialState: CampaignCreationTypes.PostStore = {
  loading: false,
  error: null,
  data: null,
};

export default (
  state: CampaignCreationTypes.PostStore = initialState,
  { type, payload }: Action
): CampaignCreationTypes.PostStore => {
  switch (type) {
    case T.POST_CAMPAIGN:
      return { ...state, loading: true };
    case T.POST_CAMPAIGN_SUCCESS:
      return { ...state, loading: false };
    case T.POST_CAMPAIGN_ERROR:
      return { ...state, loading: false, error: payload };
    default:
      return state;
  }
};
