import { getDataListReducer } from 'common/store/reducers/data-list';
import { mappingsLazyLoadingLimit } from 'modules/attribution-mappings/constants';
import { combineReducers } from 'redux';
import { attributionMappingsListActionTypes } from '../actions';

export const attributionMappingsReducer = combineReducers({
  list: getDataListReducer<AttributionMappingsModule.ImportMappingItem>(attributionMappingsListActionTypes, {
    limit: mappingsLazyLoadingLimit,
  }),
});
