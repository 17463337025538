import { ApiService } from 'common/services';
import { EditableBrandFields } from 'common/types';
import { IGetQueryString } from 'common/ui/base-filters/types';
import { parseFilters } from 'common/utils/parse-filters';

class AdminBrandsApiService extends ApiService {
  getBrands = (filters: IGetQueryString) => {
    return this.get('/api/brands', {
      params: parseFilters(filters, { search: 'name' }),
    });
  };

  createBrand = (brand: EditableBrandFields) => {
    return this.post('/api/brands', brand);
  };

  editBrand = (brand: EditableBrandFields, id: number) => {
    return this.patch(`/api/brands/${id}`, brand);
  };

  makeAllBrandsMavEligable = (payload: {
    id: number;
    ad_channel: string;
    products_brands_ids?: number[];
    affectAssociatedProducts?: boolean;
  }) => {
    return this.patch(
      `/api/brands/${payload.id}/products`,
      { ad_channel: payload.ad_channel },
      {
        params: {
          products_brands_ids: payload?.products_brands_ids ? payload?.products_brands_ids.join(',') : null,
          affect_associated_products: payload.affectAssociatedProducts,
        },
      }
    );
  };

  deleteBrand = (id: number) => {
    return this.delete(`/api/brands/${id}/soft`);
  };

  addBalance = (walletId: number, value: number, desc: string) => {
    return this.post(`/api/wallets/${walletId}/transactions`, {
      amount: value,
      description: desc || 'ADMIN TRANSACTION',
    });
  };

  getUnifiedReporting = (filters: IGetQueryString) => {
    return this.get('/api/v2/adipx/admin/overview', {
      params: parseFilters({
        start_date: filters.start_date,
        end_date: filters.end_date,
        brand_id: filters.brand_id,
        only_perpetua: filters.only_perpetua,
      }),
    });
  };

  getBrandsOverview = (filters: IGetQueryString) => {
    return this.get('/api/ads/admin/brands-overview', {
      params: parseFilters(filters),
    });
  };

  getPendingBrands = (filters: IGetQueryString) => {
    return this.get('/api/v2/adipx/brands/search', {
      params: { ...parseFilters({ ...filters, exclude_test_brands: 1 }) },
    });
  };

  getOrders = (filters: IGetQueryString) => {
    return this.get('/api/v2/ads/admin/list-stats', { params: parseFilters(filters) });
  };

  getOrderHistory = (orderId: string) => {
    return this.get('/api/ads/change-logs', { params: { entity_type: 1, entity_id: orderId } });
  };

  getBrandsExports = (filters: IGetQueryString) => {
    return this.get('/api/brands/exports', { params: parseFilters(filters) });
  };

  getBrandsPerformanceExports = (filters: IGetQueryString) => {
    return this.get('/api/ads/admin/brands-overview/export', { params: parseFilters(filters) });
  };

  getExportStatus = (jobId: string) => {
    return this.get(`/api/export/${jobId}/status`);
  };

  getBrandsParents = () => {
    return this.get('/api/brands-parents');
  };

  addBrandsParents = (payload: string) => {
    return this.post('/api/brands-parents', { name: payload });
  };

  patchBrandsParents = (payload: { name: string; id: number }) => {
    return this.patch(`/api/brands-parents/${payload.id}`, { name: payload.name });
  };

  deleteBrandsParents = (payload: number) => {
    return this.delete(`/api/brands-parents/${payload}`);
  };

  updateAsinFee = (payload: { brandId: number; asinFee: number }) => {
    return this.patch(`/api/accounting/brands-settings/brands/${payload.brandId}`, { asin_fee: payload.asinFee });
  };

  getListStatsExports = (filters: IGetQueryString) => {
    return this.get('/api/ads/admin/list-stats/export', { params: parseFilters(filters) });
  };

  getBrandsNotes = (id: number) => {
    return this.get(`/api/brands/${id}/notes`);
  };

  postBrandsNote = (payload: { id: number; note: string }) => {
    return this.post(`/api/brands/${payload.id}/notes`, { note: payload.note, type: null });
  };

  deleteBrandsNote = (payload: { id: number; noteId: number }) => {
    return this.delete(`/api/brands/${payload.id}/notes/${payload.noteId}`);
  };

  editBrandsNote = (payload: { id: number; noteId: number; note: string }) => {
    return this.patch(`/api/brands/${payload.id}/notes/${payload.noteId}`, { note: payload.note, type: null });
  };

  postIntegrationCopy = (payload: { integrationID: number; brandId: number }) => {
    return this.post(`/api/v2/integrations/${payload.integrationID}/copy`, { brandsIDs: [payload.brandId] });
  };

  getPublishers = () => {
    return this.get('/api/presets/filters/publishers');
  };

  generateTag = (publisherId: number, asin: string, url: string) => {
    return this.post('/api/amazon-attribution/tags/generate', { publisher_id: publisherId, asin, url });
  };

  getAdminsList = () => {
    return this.get('/api/admins');
  };

  postNewAdmin = (payload: number) => {
    return this.post('/api/admins', {
      user_id: payload,
    });
  };

  deleteAdminRole = (payload: string) => {
    return this.delete(`/api/admins/${payload}`);
  };

  inviteAdminMember = (payload: { email: string; client_type: string }) => {
    return this.post('/api/admins/invitation', payload);
  };

  getPublisherMaverickPerformance = (filters: IGetQueryString) => {
    return this.get('/api/ads/admin/publisher-mavplus', {
      params: parseFilters(filters),
    });
  };

  getPublisherMaverickPerformanceSummary = (filters: IGetQueryString) => {
    return this.get('/api/ads/admin/publisher-mavplus-summary', {
      params: parseFilters(filters),
    });
  };

  getPubMavPerformanceExports = (filters: IGetQueryString) => {
    return this.get('/api/ads/admin/publisher-mavplus/export', { params: parseFilters(filters) });
  };

  getMavProducts = (filters: IGetQueryString) => {
    return this.get('/api/brands/products/mav', {
      params: parseFilters(filters),
    });
  };

  getMavProductsExports = (filters: IGetQueryString) => {
    return this.get('/api/brands/products/mav/export', {
      params: parseFilters(filters),
    });
  };

  getMavStats = (filters: IGetQueryString) => {
    return this.get('/api/brands/products/mav/stats', {
      params: parseFilters(filters),
    });
  };

  getMavInternalProductsExports = (filters: IGetQueryString) => {
    return this.get('/api/export/documents', {
      params: parseFilters(filters),
    });
  };

  getMavInternalProductsExportsDyId = (payload: number) => {
    return this.get(`/api/export/documents/${payload}`);
  };

  getBrandsSettingsPeriods = (filters: IGetQueryString) => {
    return this.get('/api/brands/settings-periods', {
      params: parseFilters(filters),
    });
  };

  patchBrandsSettingsPeriods = (payload: { brandId: number; periodId: number; type: string; date: Date }) => {
    return this.patch(`/api/brands/${payload?.brandId}/settings-periods/${payload?.periodId}`, {
      [payload.type]: payload?.date,
    });
  };

  postBrandsSettingsPeriod = (payload: AdminTypes.BrandsSettingsPeriod) => {
    return this.post(`/api/brands/${payload.id}/settings-periods/`, payload);
  };

  deleteBrandsSettingsPeriod = (payload: { brandId: number; periodId: number }) => {
    return this.delete(`/api/brands/${payload?.brandId}/settings-periods/${payload?.periodId}`);
  };

  getPublishersById = (payload: number) => {
    return this.get(`/api/brands/${payload}/mav-publishers`);
  };

  addPublisherById = (payload: { id: number; publisher_id: number }) => {
    return this.post(`/api/brands/${payload.id}/mav-publishers`, { publisher_id: payload.publisher_id });
  };

  removePublisherById = (payload: { id: number; publisher_id: number }) => {
    return this.delete(`/api/brands/${payload.id}/mav-publishers/${payload.publisher_id}`);
  };
}

export const adminBrandsApi = new AdminBrandsApiService(process.env.REACT_APP_API_URL);
