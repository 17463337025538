import { Action } from 'common/interfaces/root';
import { adminActionTypes as T } from '../actions';

const initState: AdminTypes.InvoicesStoreTypes = {
  loading: true,
  error: null,
  data: null,
};

export default (
  state: AdminTypes.InvoicesStoreTypes = initState,
  { type, payload }: Action
): AdminTypes.InvoicesStoreTypes => {
  switch (type) {
    case T.GET_INVOICES:
      return { ...state, loading: true, error: null };

    case T.GET_INVOICES_SUCCESS:
      return { ...state, loading: false, data: payload };

    case T.GET_INVOICES_ERROR:
      return { ...state, loading: false, error: payload };

    case T.GET_INVOICE_BY_ID_SUCCESS:
      return {
        ...state,
        data: state.data.map(inv => (inv.id === payload.id ? { ...payload, items_types: inv.items_types } : inv)),
      };

    case T.RESET_INVOICE_DATA:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };

    default:
      return state;
  }
};
