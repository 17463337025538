import { LC_SELECTED_BRAND_KEY } from 'common/constants/lc';
import { Action } from 'common/interfaces/root';
import { userService } from 'common/services/user.service';
import { IGetQueryString } from 'common/ui/base-filters/types';
import moment from 'moment';
import { RESET_REDUCER } from '../actions';

const getBrandId = () => {
  const cachedId = Number(localStorage.getItem(LC_SELECTED_BRAND_KEY));
  const isCachedFound = userService.user?.brand_ids.includes(cachedId);
  return isCachedFound ? cachedId : userService.user?.brand_ids[0];
};

const getInitState = (initialValues: IGetQueryString): IGetQueryString => {
  return {
    ...initialValues,
    brand_id: getBrandId(),
    date_from: moment().subtract(6, 'days').startOf('day'),
    date_to: moment().subtract(0, 'day').endOf('day'),
    search: '',
    only_perpetua: -1,
    products_brands: [],
    brands_ids: [],
    filtersChanged: false,
  };
};

export const getFiltersReducer =
  (actionTypes: Record<string, string>, initialValues: IGetQueryString = {}) =>
  (state: IGetQueryString = getInitState(initialValues), { type, payload }: Action): IGetQueryString => {
    switch (type) {
      case actionTypes.CHANGE_BRAND:
        return { ...state, brand_id: payload };

      case actionTypes.CHANGE_RANGE:
        return { ...state, date_from: payload.from, date_to: payload.to };

      case actionTypes.CHANGE_SEARCH:
        return { ...state, search: payload };

      case actionTypes.CHANGE_CLIENT:
        return { ...state, only_perpetua: payload };

      case actionTypes.CHANGE_SORT:
        return { ...state, sort: payload };

      case actionTypes.SET_BRAND_PRODUCTS_BRANDS:
        return { ...state, products_brands: payload };

      case actionTypes.SET_BRANDS_IDS:
        return { ...state, brands_ids: payload.ids };

      case actionTypes.APPLY_FILTERS:
        return { ...state, filtersChanged: !state.filtersChanged };

      case RESET_REDUCER:
        return { ...getInitState(initialValues), date_from: state.date_from, date_to: state.date_to };

      default:
        return state;
    }
  };
