import { Box, Button, Drawer, Grow, Typography } from '@mui/material';
import { text } from 'common/constants/colors';
import { getNavConfig } from 'common/constants/nav';
import { useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { theme } from 'common/constants/theme';
import { MENU_WIDTH_SM, MENU_WIDTH_XL } from 'common/constants/sizes';
import { Styles } from 'common/types';
import { userService } from 'common/services/user.service';
import { BrandsPicker } from 'common/ui/brands-picker';
import { ReactComponent as MenuSquaresIcon } from 'common/svg/menu-squares.svg';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { changeBrand } from 'common/utils/change-brand';
import { selectGlobalFilters } from 'common/store/selectors';
import { ReactComponent as SettingsIcon } from 'common/svg/settings-icon.svg';
import { ReactComponent as LogoutIcon } from 'common/svg/logout-icon.svg';
import { ReactComponent as ProductVideoIcon } from 'common/svg/product-video-sm.svg';
import { selectBrand } from 'modules/brand-settings/store/selectors';
import { selectUserData } from 'modules/user-settings/store/selectors';
import { NavItems } from './nav-items';
import { ProductVideoModal } from './product-video-modal';
import { menuCtx } from './menu-ctx';

interface StylesProps {
  image: string;
  open: boolean;
}

const getStyles = ({ image, open }: StylesProps): Styles => ({
  menuContainer: {
    width: open ? MENU_WIDTH_XL : MENU_WIDTH_SM,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    backgroundColor: theme.palette.primary.light,
    overflowY: 'auto',
    scrollbarWidth: 'none',
    transition: 'width 0.2s ease-in-out',
  },
  brandContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 90,
    minHeight: 90,
    width: '100%',
    boxSizing: 'border-box',
    padding: open ? '0 30px' : '0 8px',
    transition: 'padding 0.2s ease-in-out',
  },
  brand: { alignSelf: 'center', color: theme.palette.background.paper, fontWeight: 700 },
  navItemsWrapper: { marginTop: 5, paddingLeft: '12px' },
  drawer: { border: 'none' },
  userName: { fontSize: 18, color: text, marginTop: 1.5 },
  userWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'center',
    marginTop: 'auto',
    marginBottom: 5,
  },
  userImage: {
    backgroundImage: `url(${image})`,
    borderRadius: '50%',
    backgroundSize: 'cover',
    width: 65,
    height: 65,
    backgroundColor: theme.palette.primary.light,
  },
  userImageWrapper: {
    borderRadius: '50%',
    width: 80,
    height: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${theme.palette.background.default}`,
    cursor: 'pointer',
  },
  userBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: open ? 2 : 0,
    minWidth: '36px',
    height: '36px',
    backgroundColor: '#FFFFFF',
    borderRadius: '10px',
    cursor: 'pointer',
  },
  userInfoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  borderBottom: {
    display: 'flex',
    flexDirection: open ? 'row' : 'column',
    gap: '5px',
  },
  productVideoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '15px 0',
    boxSizing: 'border-box',
    backgroundColor: '#ffffff',
    borderRadius: '10px',
    width: '240px',
    marginBottom: '20px',
  },
  productVideoTitle: {
    marginTop: '5px',
    fontSize: '27px',
    lineHeight: '26px',
    textAlign: 'center',
    fontWeight: 600,
  },
  productVideoDesc: {
    marginTop: '10px',
    color: '#214254',
    textAlign: 'center',
  },
  btn: {
    marginTop: '20px',
    width: '80%',
  },
});

export function Menu() {
  const { logout } = useAuth0();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const filters = useSelector(selectGlobalFilters);
  const user = useSelector(selectUserData);
  const brand = useSelector(selectBrand);

  const [isUserChanged, setUserChanged] = useState<boolean>(false);
  const [isProductModalOpen, setProductModalOpen] = useState<boolean>(false);

  const { open, timedOpen, setOpen, setHovered } = useContext(menuCtx);

  const styles = getStyles({ image: isUserChanged ? user?.avatar : userService.user.avatar, open });

  const handleOpenProductModal = () => {
    setProductModalOpen(true);
  };

  const handleCloseProductModal = () => {
    setProductModalOpen(false);
  };

  const handleBrandChange = (brand: number | null | string) => {
    changeBrand(brand, filters, dispatch);
  };

  const handleOpenUserSettings = () => {
    navigate('/user/settings');
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    logout({ returnTo: window.location.origin });
  };

  const handleMenuExpand = () => {
    setOpen(true);
    setHovered(true);
  };

  const handleMenuShrink = () => {
    setOpen(false);
    setHovered(false);
  };

  useEffect(() => {
    if (user) {
      setUserChanged(true);
    }
  }, [user]);

  return (
    <>
      <Drawer PaperProps={{ sx: styles.drawer }} anchor="left" variant="persistent" open>
        <Box component="div" sx={styles.menuContainer} onMouseEnter={handleMenuExpand} onMouseLeave={handleMenuShrink}>
          <Box sx={styles.brandContainer}>
            <BrandsPicker
              selectedBrand={filters.brand_id}
              handleBrandChange={handleBrandChange}
              shrinked={!timedOpen}
            />
            <MenuSquaresIcon />
          </Box>
          <Box sx={styles.navItemsWrapper}>
            <NavItems items={getNavConfig(t, brand?.mav_plus_ui_surfaced)} />
          </Box>
          <Box sx={styles.userWrapper}>
            <Grow in={timedOpen}>
              <Box sx={styles.productVideoWrapper}>
                <ProductVideoIcon />
                <Typography sx={styles.productVideoTitle}>Refer to Earn!</Typography>
                <Typography sx={styles.productVideoDesc}>
                  Earn commission from brands or publishers that join us.
                </Typography>
                <Button variant="contained" sx={styles.btn} onClick={handleOpenProductModal}>
                  Interested!
                </Button>
              </Box>
            </Grow>
            <Grow in={timedOpen}>
              <Box sx={styles.userInfoWrapper}>
                <Box sx={styles.userImageWrapper}>
                  <Box sx={styles.userImage} />
                </Box>
                <Typography sx={styles.userName}>{user?.name ?? userService.user.name}</Typography>
              </Box>
            </Grow>
            <Box sx={styles.borderBottom}>
              <Box sx={styles.userBtn} onClick={handleOpenUserSettings}>
                <SettingsIcon />
              </Box>
              <Box sx={styles.userBtn} onClick={handleLogout}>
                <LogoutIcon />
              </Box>
            </Box>
          </Box>
        </Box>
      </Drawer>
      <ProductVideoModal open={isProductModalOpen} onClose={handleCloseProductModal} />
    </>
  );
}
