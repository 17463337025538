export const ClientNameMapping: Record<string, string> = {
  XREMk5upQbacphQGcMBYRWfs95WZXUkl: 'Perpetua',
};

export const ClientHashMapping = {
  Perpetua: 'XREMk5upQbacphQGcMBYRWfs95WZXUkl',
};

export enum BrandsHub {
  MaverickX = 'mavx',
  Ignite = 'ignite',
}
