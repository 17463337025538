import { QUICK_START, QUICK_START_BRAND } from 'common/constants/lc';
import { useQuery } from 'common/hooks';
import { ReactElement, ReactNode, useEffect } from 'react';

interface Props {
  children: ReactNode;
}

export function QuickStartProvider({ children }: Props): ReactElement {
  const quickStart = useQuery('quick_start');
  const brandId = useQuery('brand_id');

  useEffect(() => {
    if (quickStart && brandId) {
      localStorage.setItem(QUICK_START, quickStart);
      localStorage.setItem(QUICK_START_BRAND, brandId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quickStart]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
