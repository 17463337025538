import { LC_SELECTED_BRAND_KEY } from 'common/constants/lc';
import { resetReducer } from 'common/store/actions';
import { filtersActions } from 'common/store/actions/filters';
import { IGetQueryString } from 'common/ui/base-filters/types';
import { AnyAction, Dispatch } from 'redux';

export const changeBrand = (brand: number | null | string, filters: IGetQueryString, dispatch: Dispatch<AnyAction>) => {
  if (brand === filters?.brand_id) return;
  localStorage.setItem(LC_SELECTED_BRAND_KEY, String(brand));
  dispatch(resetReducer());
  dispatch(filtersActions.changeBrand(brand));
};
