import toast from 'react-hot-toast';
import { SagaIterator } from '@redux-saga/types';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Action, ErrorRequest } from 'common/interfaces/root';
import { selectGlobalFilters } from 'common/store/selectors';
import { ordersListActions } from 'modules/admin/store/actions';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { ordersApi } from '../../services/orders.service';
import { OrderLink } from '../../types';
import {
  attributionOrdersActions,
  attributionOrdersActionTypes,
  attributionOrdersForProductsActionTypes,
  orderLinksActions,
  orderLinksActionTypes,
  ordersActions,
  ordersActionTypes,
} from '../actions';
import { selectOrdersFilters, selectOrdersLimit, selectOrdersPage } from '../selectors';

function* getOrdersList({ payload }: Action) {
  try {
    const { data } = yield call(ordersApi.getOrdersList, payload);
    yield put(attributionOrdersActions.getSuccess(data?.results?.orders, data?.results?.total));
  } catch (err) {
    yield put(attributionOrdersActions.getError(err as Error));
  }
}

function* getOrdersListForProducts({ payload }: Action) {
  try {
    const { data } = yield call(ordersApi.getOrdersList, payload);
    yield put(attributionOrdersActions.getSuccess(data?.results?.orders, data?.results?.total));
  } catch (err) {
    yield put(attributionOrdersActions.getError(err as Error));
  }
}

function* createOrder({ payload, meta }: Action) {
  try {
    yield call(ordersApi.createBrand, payload);
    yield put(ordersActions.createOrderSuccess());
    meta?.onSuccess();
  } catch (err: ErrorRequest) {
    yield put(ordersActions.createOrderError(err as Error));
    toast.error('Something went wrong');
  }
}

function* createOrderWithRequest({ payload, meta }: Action) {
  try {
    yield call(ordersApi.createOrderWithRequest, payload);
    yield put(ordersActions.createOrderWithRequestSuccess());
    meta?.onSuccess();
  } catch (err: ErrorRequest) {
    yield put(ordersActions.createOrderWithRequestError(err as Error));
    toast.error('Something went wrong');
  }
}

function* patchOrder({ payload, meta }: Action) {
  try {
    yield call(ordersApi.patchBrand, payload);
    yield put(ordersListActions.updateEntity('id', payload.id, { status: payload.data.status }));
    yield put(ordersActions.patchOrderSuccess());
    meta?.onSuccess();
  } catch (err: ErrorRequest) {
    yield put(ordersActions.patchOrderError(err as Error));
    toast.error(err?.response?.data?.message ?? 'Something went wrong');
  }
}

function* getTotalOrders({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(ordersApi.getOrdersList, payload);
    yield put(ordersActions.getTotalOrdersSuccess(data.total));
  } catch (err) {
    yield put(ordersActions.getTotalOrdersError(err as Error));
  }
}

function* deleteOrder({ payload }: Action): SagaIterator {
  try {
    yield call(ordersApi.deleteBrand, payload.brandId, payload.orderId);
    yield put(ordersActions.deleteOrderSuccess());

    const globalFilters = yield select(selectGlobalFilters);
    const page = yield select(selectOrdersPage);
    const limit = yield select(selectOrdersLimit);
    const filters = yield select(selectOrdersFilters);

    yield put(
      attributionOrdersActions.get({
        limit,
        offset: page * limit,
        brand_id: globalFilters?.brand_id,
        search: filters?.search,
      })
    );
  } catch (err) {
    yield put(ordersActions.deleteOrderError(err as Error));
  }
}

function* getOrderLinks({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(ordersApi.getOrderLinks, payload.order_id);

    const linkflow: Array<OrderLink> = data.links.linkflow.map((item: unknown) => ({
      type: 'linkflow',
      content: item,
    }));
    const adv: Array<OrderLink> = data.links.advertising.map((item: unknown) => ({
      type: 'advertising',
      content: item,
    }));

    yield put(orderLinksActions.getSuccess([...linkflow, ...adv]));
  } catch (err) {
    yield put(orderLinksActions.getError(err as Error));
  }
}

export function* ordersSaga(): SagaIterator {
  yield all([
    takeEvery(ordersActionTypes.CREATE_ORDER, createOrder),
    takeEvery(ordersActionTypes.PATCH_ORDER, patchOrder),
    takeEvery(attributionOrdersActionTypes.GET, getOrdersList),
    takeEvery(ordersActionTypes.GET_TOTAL_ORDERS, getTotalOrders),
    takeEvery(ordersActionTypes.DELETE_ORDER, deleteOrder),
    takeEvery(attributionOrdersForProductsActionTypes.GET, getOrdersListForProducts),
    takeEvery(orderLinksActionTypes.GET, getOrderLinks),
    takeEvery(ordersActionTypes.CREATE_ORDER_WITH_REQUEST, createOrderWithRequest),
  ]);
}
