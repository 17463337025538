import { Action } from 'common/interfaces/root';
import { presetsApi } from 'common/services/api/presets-api.service';
import { toast } from 'react-hot-toast';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { presetsActions } from '../actions/presets';

export function* getPresetsSaga(): SagaIterator {
  try {
    const { data } = yield call(presetsApi.getBrands);

    yield put(
      presetsActions.getFiltersPresetsSuccess({
        brands: data?.brands,
      })
    );
  } catch (err) {
    yield put(presetsActions.getFiltersPresetsError(err as Error));
  }
}

export function* getTimeInfo() {
  try {
    const { data } = yield call(presetsApi.getTimeInfo);

    yield put(presetsActions.getTimeInfoSuccess(data.info));
  } catch (err) {
    yield put(presetsActions.getTimeInfoError(err as Error));
  }
}

export function* postProdVideoEmail({ payload }: Action) {
  try {
    yield call(presetsApi.postProdVideoEmail, payload.mail);
    yield call(presetsApi.postVideoRequestsNotify, payload.notify);
    toast.success('Email sent successfully!');
  } catch (err) {
    toast.success('Error while sending an email!');
  }
}
