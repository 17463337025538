/* eslint-disable @typescript-eslint/ban-types */

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { CACHE_DATE_HEADER } from 'common/constants/api';

export class ApiService {
  protected instance: AxiosInstance;

  private host: string;

  constructor(host: string) {
    this.host = host;
    this.instance = axios.create();
  }

  private updateInterceptors() {
    this.instance.interceptors.request.use(
      config => {
        config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;

        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );

    this.instance.interceptors.response.use(response => {
      if (response.data) {
        response.data.meta = {
          cacheTime: response.headers[CACHE_DATE_HEADER] ? Date.now() - Number(response.headers[CACHE_DATE_HEADER]) : 0,
        };
      }

      return response;
    });
  }

  protected async get(url: string, data?: AxiosRequestConfig): Promise<AxiosResponse> {
    this.updateInterceptors();
    return this.instance.get(`${this.host}${url}`, data);
  }

  protected async post(url: string, data?: object, config?: AxiosRequestConfig): Promise<AxiosResponse> {
    this.updateInterceptors();
    return this.instance.post(`${this.host}${url}`, data, config);
  }

  protected async put(url: string, data?: object): Promise<AxiosResponse> {
    this.updateInterceptors();
    return this.instance.put(`${this.host}${url}`, data);
  }

  protected async delete(url: string, data?: AxiosRequestConfig): Promise<AxiosResponse> {
    this.updateInterceptors();
    return this.instance.delete(`${this.host}${url}`, data);
  }

  protected async patch(url: string, data?: object, config?: AxiosRequestConfig): Promise<AxiosResponse> {
    this.updateInterceptors();
    return this.instance.patch(`${this.host}${url}`, data, config);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected async postFormData(url: string, data?: any): Promise<AxiosResponse> {
    return this.rawMultipart('post', url, data);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected async patchFormData(url: string, data?: any): Promise<AxiosResponse> {
    return this.rawMultipart('patch', url, data);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected async rawMultipart(method: string, url: string, data: any): Promise<AxiosResponse> {
    const formData = new FormData();
    if (data) {
      Object.keys(data).forEach(key => {
        if (typeof data[key] !== 'object' || key === 'file') formData.append(key, data[key]);
        else formData.append(key, JSON.stringify(data[key]));
      });
    }
    this.updateInterceptors();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.instance({
      method,
      url: `${this.host}${url}`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}
