import { IGetQueryString } from 'common/ui/base-filters/types';
import moment from 'moment-timezone';

const filtersMap = {
  brand_id: 'brand_id',
  brands_ids: 'brands_ids',
  brands_parents_id: 'brands_parents_id',
  placement: 'placement',
  no_cache: 'no_cache',
  limit: 'limit',
  offset: 'offset',
  search: 'search',
  sort: 'sort',
  date_from: 'date_from',
  date_to: 'date_to',
  start_date: 'start_date',
  end_date: 'end_date',
  startDate: 'startDate',
  endDate: 'endDate',
  daily: 'daily',
  breakdown: 'breakdown',
  platform: 'platform',
  page_type: 'page_type',
  publisher: 'publisher',
  total_daily: 'total_daily',
  only_perpetua: 'only_perpetua',
  has_orders: 'has_orders',
  has_integrations: 'has_integrations',
  has_ad_spend: 'has_ad_spend',
  exclude_test_brands: 'exclude_test_brands',
  orders: 'orders',
  brand_name: 'brand_name',
  asin: 'asin',
  order_id: 'order_id',
  in_stock: 'in_stock',
  previousPerformance: 'previousPerformance',
  total_sales14d_gte: 'total_sales14d[gte]',
  total_sales14d_lte: 'total_sales14d[lte]',
  cost_gte: 'cost[gte]',
  cost_lte: 'cost[lte]',
  expected_ad_cost_gte: 'expected_ad_cost[gte]',
  expected_ad_cost_lte: 'expected_ad_cost[lte]',
  actual_acos_gte: 'actual_acos[gte]',
  actual_acos_lte: 'actual_acos[lte]',
  profitability_gte: 'profitability[gte]',
  profitability_lte: 'profitability[lte]',
  target_acos_gte: 'target_acos[gte]',
  target_acos_lte: 'target_acos[lte]',
  diff_acos_gte: 'diff_acos[gte]',
  diff_acos_lte: 'diff_acos[lte]',
  publisher_id: 'publisher_id',
  period_year: 'period[year]',
  period_month: 'period[month]',
  account_manager_id: 'account_manager_id',
  active: 'active',
  periodStart: 'periodStart',
  periodEnd: 'periodEnd',
  mav_eligible: 'mav_eligible',
  priority_rank: 'priority_rank',
  only_orders: 'only_orders',
  mav_3rd_party: 'mav_3rd_party',
  exportType: 'exportType',
  type: 'type',
  ad_only: 'ad_only',
  brand_parent: 'brand_parent',
  new_mav_eligible_stats: 'new_mav_eligible_stats',
  withPublisher: 'withPublisher',
  only_sr_clients: 'only_sr_clients',
  products_brands_ids: 'products_brands_ids',
  blocking_urls: 'blocking_urls',
};

const dateFormat = 'YYYY-MM-DD';

const getSecondDateRange = (dateRangesStr: string) => {
  const [, secondDateRange] = dateRangesStr.split(',');
  const [date_from, date_to] = secondDateRange.split('_');
  return { date_from, date_to };
};

const prepareDatesToCompare = (startDate: string, endDate: string) => {
  const formattedDates = {
    start: moment(startDate).format('YYYY-MM-DD'),
    end: moment(endDate).format('YYYY-MM-DD'),
  };
  return `${formattedDates.start}_${formattedDates.end}`;
};

export const parseFilters = (
  filters: IGetQueryString,
  keysMap: Partial<typeof filtersMap> = {},
  includeBrandsIds = true
) => {
  const dateFrom = moment(filters.date_from).tz('EST', true);
  const dateTo = moment(filters.date_to).tz('EST', true);
  const startDate = moment(filters.start_date).tz('EST', true);
  const endDate = moment(filters.end_date).tz('EST', true);
  const fromDate = moment(filters.startDate).tz('EST', true);
  const toDate = moment(filters.endDate).tz('EST', true);

  const map = { ...filtersMap, ...keysMap };

  const parsedFilters = {
    [map.brand_id]: filters.brand_id,
    ...(includeBrandsIds ? { [map.brands_ids]: filters.brands_ids } : {}),
    [map.brands_parents_id]: filters.brands_parents_id,
    [map.placement]: filters.placement,
    [map.limit]: filters.limit,
    [map.offset]: filters.offset,
    ...(filters.no_cache ? { [map.no_cache]: filters.no_cache } : {}),
    ...(filters.type ? { [map.type]: filters.type } : {}),
    ...(filters.exportType ? { [map.exportType]: filters.exportType } : {}),
    ...(filters.mav_3rd_party ? { [map.mav_3rd_party]: filters.mav_3rd_party } : {}),
    ...(filters.active ? { [map.active]: filters.active } : {}),
    ...(filters.mav_eligible ? { [map.mav_eligible]: filters.mav_eligible } : {}),
    ...(filters.account_manager_id ? { [map.account_manager_id]: filters.account_manager_id } : {}),
    ...(filters.asin ? { [map.asin]: filters.asin } : {}),
    ...(filters.order_id ? { [map.order_id]: filters.order_id } : {}),
    ...(filters.daily !== null || filters.daily !== undefined ? { [map.daily]: filters.daily } : {}),
    ...(filters.total_daily !== null || filters.total_daily !== undefined
      ? { [map.total_daily]: filters.total_daily }
      : {}),
    ...(filters.breakdown ? { [map.breakdown]: filters.breakdown } : {}),
    ...(filters.search ? { [map.search]: filters.search } : {}),
    ...(filters.only_perpetua !== undefined ? { [map.only_perpetua]: filters.only_perpetua } : {}),
    ...(filters.has_orders !== undefined ? { [map.has_orders]: filters.has_orders } : {}),
    ...(filters.has_integrations !== undefined ? { [map.has_integrations]: filters.has_integrations } : {}),
    ...(filters.has_ad_spend !== undefined ? { [map.has_ad_spend]: filters.has_ad_spend } : {}),
    ...(filters.exclude_test_brands ? { [map.exclude_test_brands]: filters.exclude_test_brands } : {}),
    ...(filters.sort ? { [map.sort]: `${filters.sort.field}:${filters.sort.direction}` } : {}),
    ...(filters.date_from ? { [map.date_from]: dateFrom.format(dateFormat) } : {}),
    ...(filters.start_date ? { [map.start_date]: startDate.format(dateFormat) } : {}),
    ...(filters.startDate ? { [map.startDate]: fromDate.format(dateFormat) } : {}),
    ...(filters.date_to
      ? { [map.date_to]: dateFrom.isSame(dateTo) ? dateTo.endOf('day').format(dateFormat) : dateTo.format(dateFormat) }
      : {}),
    ...(filters.endDate
      ? {
          [map.endDate]: fromDate.isSame(toDate) ? toDate.endOf('day').format(dateFormat) : toDate.format(dateFormat),
        }
      : {}),
    ...(filters.end_date
      ? {
          [map.end_date]: startDate.isSame(endDate)
            ? endDate.endOf('day').format(dateFormat)
            : endDate.format(dateFormat),
        }
      : {}),
    ...(filters.platform ? { [map.platform]: filters.platform } : {}),
    ...(filters.page_type ? { [map.page_type]: filters.page_type } : {}),
    ...(filters.publisher ? { [map.publisher]: filters.publisher } : {}),
    ...(filters.orders ? { [map.orders]: filters.orders } : {}),
    ...(filters.brand_name ? { [map.brand_name]: filters.brand_name } : {}),
    ...(filters.in_stock ? { [map.in_stock]: filters.in_stock } : {}),
    ...(filters.total_sales14d_gte ? { [map.total_sales14d_gte]: filters.total_sales14d_gte } : {}),
    ...(filters.total_sales14d_lte ? { [map.total_sales14d_lte]: filters.total_sales14d_lte } : {}),
    ...(filters.cost_gte ? { [map.cost_gte]: filters.cost_gte } : {}),
    ...(filters.cost_lte ? { [map.cost_lte]: filters.cost_lte } : {}),
    ...(filters.expected_ad_cost_gte ? { [map.expected_ad_cost_gte]: filters.expected_ad_cost_gte } : {}),
    ...(filters.expected_ad_cost_lte ? { [map.expected_ad_cost_lte]: filters.expected_ad_cost_lte } : {}),
    ...(filters.actual_acos_gte ? { [map.actual_acos_gte]: Number(filters.actual_acos_gte) / 100 } : {}),
    ...(filters.actual_acos_lte ? { [map.actual_acos_lte]: Number(filters.actual_acos_lte) / 100 } : {}),
    ...(filters.profitability_gte ? { [map.profitability_gte]: filters.profitability_gte } : {}),
    ...(filters.profitability_lte ? { [map.profitability_lte]: filters.profitability_lte } : {}),
    ...(filters.target_acos_gte ? { [map.target_acos_gte]: Number(filters.target_acos_gte) / 100 } : {}),
    ...(filters.target_acos_lte ? { [map.target_acos_lte]: Number(filters.target_acos_lte) / 100 } : {}),
    ...(filters.diff_acos_gte ? { [map.diff_acos_gte]: Number(filters.diff_acos_gte) / 100 } : {}),
    ...(filters.diff_acos_lte ? { [map.diff_acos_lte]: Number(filters.diff_acos_lte) / 100 } : {}),
    ...(filters.previousPerformance ? { [map.previousPerformance]: filters.previousPerformance } : {}),
    ...(filters.publisher_id ? { [map.publisher_id]: filters.publisher_id } : {}),
    ...(filters.period_year ? { [map.period_year]: filters.period_year } : {}),
    ...(filters.period_month ? { [map.period_month]: filters.period_month } : {}),
    ...(filters.periodStart ? { [map.periodStart]: filters.periodStart } : {}),
    ...(filters.periodEnd ? { [map.periodEnd]: filters.periodEnd } : {}),
    ...(filters.priority_rank ? { [map.priority_rank]: filters.priority_rank } : {}),
    ...(filters.only_orders ? { [map.only_orders]: filters.only_orders } : {}),
    ...(filters.ad_only ? { [map.ad_only]: filters.ad_only } : {}),
    ...(filters.brand_parent ? { [map.brand_parent]: filters.brand_parent } : {}),
    ...(filters.new_mav_eligible_stats ? { [map.new_mav_eligible_stats]: filters.new_mav_eligible_stats } : {}),
    ...(filters.withPublisher ? { [map.withPublisher]: filters.withPublisher } : {}),
    ...(filters.blocking_urls ? { [map.blocking_urls]: filters.blocking_urls } : {}),
    ...(filters.only_sr_clients ? { [map.only_sr_clients]: filters.only_sr_clients } : {}),
    ...(filters.brands_ids && filters.brands_ids.length
      ? { [map.products_brands_ids]: filters.brands_ids.join(',') }
      : {}),
  };

  if (!filters.dates_to_compare || !filters.dates_to_compare.length) {
    return parsedFilters;
  }

  const { date_from, date_to } = getSecondDateRange(filters.dates_to_compare);
  parsedFilters.dates_to_compare = prepareDatesToCompare(date_from, date_to);

  return parsedFilters;
};
