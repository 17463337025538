/* eslint-disable prettier/prettier */
import { ApiService } from 'common/services';
import { IGetQueryString } from 'common/ui/base-filters/types';
import { parseFilters } from 'common/utils/parse-filters';

class ProductsApiService extends ApiService {
  importProducts = (asins: Array<string>, brandId: number, ad_channel: string) => {
    return this.post(`/api/brands/${brandId}/products/import`, { asins, ad_channel });
  };

  getProducts = (data: IGetQueryString) => {
    return this.get(`/api/brands/${data.brand_id}/products`, { params: parseFilters(data, {}, false) });
  };

  exportProducts = (brand_id: number, execInstantly?: boolean) => {
    return this.get(
      `/api/brands/${brand_id}/products/export`,
      {
        params: { ...(execInstantly ? { exec_instantly: true } : {}) },
      }
    );
  }

  getProductsBrands = (brand_id: number | string) => {
    return this.get(`/api/brands/${brand_id}/products-brands`);
  };

  deleteProduct = (productId: number, brandId: number) => {
    return this.delete(`/api/brands/${brandId}/products/${productId}`);
  };

  deleteProducts = (brandId: number, data: { products_ids?: number[]; brands_ids?: number[] }) => {
    const prepare = (val: number[]) => (val && val.length ? val.join(',') : null);

    return this.delete(`/api/brands/${brandId}/products`, {
      params: {
        amazon_brands_ids: prepare(data?.brands_ids),
        products_ids: prepare(data?.products_ids),
      },
    });
  };

  postCategory = (asin: string, brandId: number, category: string) => {
    return this.post(`/api/brands/${brandId}/products/metadata`, { asins: [asin], category });
  };

  patchCategory = (asin: string, brandId: number, category: string) => {
    return this.patch(`/api/brands/${brandId}/products/metadata`, { asins: [asin], category });
  };

  patchTargetAcos = (asin: string, brandId: number, targetAcos: number) => {
    return this.patch(`/api/brands/${brandId}/products/metadata`, { asins: [asin], target_acos: targetAcos });
  };

  patchMavPercent = (asin: string, brandId: number, mavPercent: number) => {
    return this.patch(`/api/brands/${brandId}/products/metadata`, { asins: [asin], mav_percent: mavPercent });
  };

  patchAdChannel = (payload: { brandId: number; id: number; adChannel: string; affectAssociatedProducts?: boolean; }) => {
    return this.patch(
      `/api/brands/${payload.brandId}/products/${payload.id}`,
      { ad_channel: payload.adChannel },
      { 
        params: { 
          ...(payload.affectAssociatedProducts ? { affect_associated_products: payload.affectAssociatedProducts } : {})
        }
      }
    );
  };
}

export const productsApi = new ProductsApiService(process.env.REACT_APP_API_URL);
