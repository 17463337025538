import { adminReducer } from 'modules/admin/store';
import { attributionPerformanceReducer } from 'modules/attribution-performance/store';
import { brandSettingsReducer } from 'modules/brand-settings/store';
import { productsReducer } from 'modules/products/store';
import { attributionMappingsReducer } from 'modules/attribution-mappings/store';
import { userSettingsReducers } from 'modules/user-settings/store';
import { ordersReducers } from 'common/ui/create-order-modal/store/reducers';
import { campaignCreationReducer } from 'modules/campaign-creation/store';
import { combineReducers } from 'redux';
import { newOverviewReducer } from 'modules/overview-new/store';
import { filtersActionTypes } from '../actions/filters';
import { getFiltersReducer } from './filters';

import datePickerComparatorReducer from './date-picker-comparator';
import presets from './presets';
import rootReducer from './root';

export const getReducers = () =>
  combineReducers({
    root: rootReducer,
    filters: getFiltersReducer(filtersActionTypes),
    userSettings: userSettingsReducers,
    products: productsReducer,
    brandSettings: brandSettingsReducer,
    presets,
    admin: adminReducer,
    orders: ordersReducers,
    attributionPerformance: attributionPerformanceReducer,
    attributionMappings: attributionMappingsReducer,
    campaignCreation: campaignCreationReducer,
    overview: newOverviewReducer,
    datePickerComparator: datePickerComparatorReducer,
  });

const reducers = getReducers();

export type State = ReturnType<typeof reducers>;
