import { SagaIterator } from '@redux-saga/types';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Action, ErrorRequest } from 'common/interfaces/root';
import toast from 'react-hot-toast';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { newOverviewApi } from 'modules/overview-new/services/overview-new-api.service';
import { newOverviewActions, newOverviewActionTypes as T } from '../actions';

function* postRequestReport({ payload }: Action): SagaIterator {
  try {
    yield call(newOverviewApi.postRequestReport, payload);
    toast.success('Request report sent succesfully!');
  } catch (err: ErrorRequest) {
    yield put(newOverviewActions.postRequestReportError(err as Error));
    toast.error(err?.response?.data?.message ?? 'Error while sending request report!');
  }
}

function* getOverviewData({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(newOverviewApi.getOverviewData, payload);
    yield put(newOverviewActions.getOverviewDataSuccess(data));
  } catch (err: ErrorRequest) {
    yield put(newOverviewActions.getOverviewDataError(err as Error));
  }
}

export function* newOverviewSaga(): SagaIterator {
  yield all([takeEvery(T.POST_REQUEST_REPORT, postRequestReport)]);
  yield all([takeEvery(T.GET_OVERVIEW_DATA, getOverviewData)]);
}
