import { DateByValue, ITableSort, Traffic } from 'common/ui/base-filters/types';
import { UsedValue } from 'common/ui/base-filters/used-picker';
import { Moment } from 'moment';
import { filtersActionTypes } from './action-types';

export const getFiltersActions = (actionType: Record<string, string>) => ({
  changeRange: (from: Moment, to: Moment) => ({
    type: actionType.CHANGE_RANGE,
    payload: { from, to },
  }),
  changeBrand: (brandId: number | null | string) => ({
    type: actionType.CHANGE_BRAND,
    payload: brandId,
  }),
  changeSearch: (search: string) => ({
    type: actionType.CHANGE_SEARCH,
    payload: search,
  }),
  changeTrafficSource: (trafficSource: Traffic) => ({
    type: actionType.CHANGE_IS_PAID,
    payload: trafficSource,
  }),
  changeUsed: (used: UsedValue) => ({
    type: actionType.CHANGE_USED,
    payload: used,
  }),
  changeAffiliateNetworkType: (id: number | string) => ({
    type: actionType.CHANGE_AFFILIATE_NETWORK_TYPE,
    payload: id,
  }),
  changeDateBy: (dateBy: DateByValue) => ({
    type: actionType.CHANGE_DATE_BY,
    payload: dateBy,
  }),
  changeClient: (client: number) => ({
    type: actionType.CHANGE_CLIENT,
    payload: client,
  }),
  changeSort: (sort: ITableSort) => ({
    type: actionType.CHANGE_SORT,
    payload: sort,
  }),
});

export const filtersActions = getFiltersActions(filtersActionTypes);
