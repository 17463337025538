import { Box, Button, Typography } from '@mui/material';
import { Styles } from 'common/types';
import { Modal } from 'common/ui/modal';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';

const styles: Styles = {
  container: { display: 'flex', flexDirection: 'column', gap: 1.5 },
  primaryText: { fontSize: 14, lineHeight: '26px' },
  secondaryText: { fontSize: 13, color: '#214254', lineHeight: '24px', mt: 2 },
  btns: { display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1.5, width: '100%', mt: 2, mb: 5 },
  connectBtn: { flexGrow: 1 },
  and: { color: '#6F8490', fontSize: 10 },
  footer: { width: '100%', gap: 2, display: 'flex', justifyContent: 'flex-end', mt: 4 },
  footerBtn: { width: 120 },
};

interface Props {
  open: boolean;
  onClose: () => void;
}

export function WelcomeModal({ open, onClose }: Props): ReactElement {
  return (
    <Modal open={open} onClose={onClose} title="Welcome to MaverickX for Brands!">
      <Box sx={styles.container}>
        <Typography sx={styles.primaryText}>
          We are so excited you are here and ready to get started. To make sure everything is set up correctly, please
          take a few minutes to complete the next steps.
        </Typography>
        <Typography sx={styles.secondaryText}>Click below to sign into your seller central account</Typography>
        <Box sx={styles.btns}>
          <Link
            to="/brand/integrations"
            target="_blank"
            style={{
              textDecoration: 'none',
            }}
          >
            <Button sx={styles.connectBtn} variant="contained" color="primary" size="small">
              Sign into your seller central account
            </Button>
          </Link>
        </Box>
      </Box>
      <Box sx={styles.footer}>
        <Button onClick={onClose} variant="outlined" size="small" sx={styles.footerBtn}>
          Skip
        </Button>
        <Button onClick={onClose} variant="contained" size="small" sx={styles.footerBtn}>
          Done
        </Button>
      </Box>
    </Modal>
  );
}
