import { TFunction } from 'i18next';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
// import AppsIcon from '@mui/icons-material/Apps';
import SpeedIcon from '@mui/icons-material/Speed';
import SettingsIcon from '@mui/icons-material/Settings';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
// import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { userService } from 'common/services/user.service';
import { PERMISSIONS } from './permissions';
import { onboardingTargets } from './onboarding';

export interface NavItem {
  title: string;
  path?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: OverridableComponent<SvgIconTypeMap<any, 'svg'>> & {
    muiName: string;
  };
  nested?: Array<NavItem>;
  isAvailable: boolean;
  target?: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getNavConfig = (t: TFunction, isBrandHaveMavAccess: boolean): Array<NavItem> => [
  {
    title: 'Dashboard',
    path: '/',
    icon: HomeIcon,
    isAvailable: true,
    target: onboardingTargets.dashboard,
  },
  {
    title: 'Attribution Performance',
    path: '/performance',
    icon: SpeedIcon,
    isAvailable: userService.hasAccess([PERMISSIONS.PERFORMANCE_READ]) || userService.hasDeprecatedAccess(),
    target: onboardingTargets.attributionPerformance,
  },
  {
    title: 'Mav+ Analytics',
    path: '/mav',
    icon: SpeedIcon,
    isAvailable: isBrandHaveMavAccess,
  },
  {
    title: 'Products',
    path: '/products',
    icon: FormatListBulletedIcon,
    isAvailable: true,
    target: onboardingTargets.products,
  },
  // {
  //   title: 'Campaign Creation',
  //   path: '/campaign-creation',
  //   icon: AddCircleOutlineIcon,
  //   isAvailable: true,
  // },
  {
    title: 'Brand Settings',
    icon: SettingsIcon,
    path: '/brand/general',
    isAvailable: true,
    target: onboardingTargets.brandSettings,
  },
  {
    title: 'Admin Panel',
    icon: AdminPanelSettingsIcon,
    path: '/admin/overview',
    isAvailable: userService.hasAccess([PERMISSIONS.BRANDS_READ]),
  },
  // {
  //   title: 'Brands',
  //   path: '/brands',
  //   icon: AppsIcon,
  //   isAvailable: true,
  // },
];
