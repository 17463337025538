import { Action } from 'common/interfaces/root';
import { adminActionTypes as T } from '../actions';

const initState: AdminTypes.UnifiedReportingStore = {
  loading: true,
  error: null,
  currentData: null,
  previousPeriodData: null,
};

export default (
  state: AdminTypes.UnifiedReportingStore = initState,
  { type, payload }: Action
): AdminTypes.UnifiedReportingStore => {
  switch (type) {
    case T.GET_UNIFIED_REPORTING:
      return { ...state, loading: true };

    case T.GET_UNIFIED_REPORTING_SUCCESS:
      return {
        ...state,
        loading: false,
        currentData: payload.currentData,
        previousPeriodData: payload.previousPeriodData,
      };

    case T.GET_UNIFIED_REPORTING_ERROR:
      return { ...state, loading: false, error: payload };

    default:
      return state;
  }
};
