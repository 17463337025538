import { Box, Button, Typography } from '@mui/material';
import { Styles } from 'common/types';
import { ReactElement } from 'react';
import Confetti from 'react-confetti';

const styles: Styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100vh',
  },
  description: {
    color: '#214254',
    maxWidth: 700,
    width: '100%',
    wordBreak: 'break-word',
    marginTop: 1.5,
    textAlign: 'center',
  },
  btn: { marginTop: 3 },
  imgContainer: { maxWidth: 200, width: '100%' },
  img: { width: '100%' },
  title: { fontSize: 27, fontWeight: 600, marginTop: 4 },
};

interface Props {
  link: string;
}

export function AmazonLogin({ link }: Props): ReactElement {
  return (
    <Box sx={styles.container}>
      <Confetti numberOfPieces={50} wind={0.01} />
      <Box sx={styles.imgContainer}>
        <Box component="img" src="/assets/images/empty-amazon-int.png" sx={styles.img} />
      </Box>
      <Typography sx={styles.title}>Welcome to MaverickX!</Typography>
      <Typography sx={styles.description}>
        We’re excited to provide the most comprehensive amazon attribution solution on the market, leveraging various
        automated techniques to optimize your GMV. We look forward to our journey together
      </Typography>
      <Button variant="contained" sx={styles.btn} onClick={() => window.open(link)}>
        Connect Amazon
      </Button>
    </Box>
  );
}
