import DashboardIcon from '@mui/icons-material/Dashboard';
import SpeedIcon from '@mui/icons-material/Speed';
import GroupIcon from '@mui/icons-material/Group';
import ShareIcon from '@mui/icons-material/Share';
import ListAltIcon from '@mui/icons-material/ListAlt';
import TocIcon from '@mui/icons-material/Toc';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';

export enum BrandAction {
  edit = 'edit',
  create = 'create',
}

export enum BrandsTableSortField {
  name = 'name',
  created_at = 'created_at',
  brands_parents_name = 'brands_parents_name',
  integrations_count = 'integrations_count',
  orders_count = 'orders_count',
  share_of_wallet = 'products_parent_count',
  balance = 'balance',
  cost = 'cost',
  revenue = 'revenue',
  stripe = 'stripe',
  ad_channel = 'ad_channel',
  sp_connected = 'sp_connected',
}

export const sortingBrandsTable = [
  BrandsTableSortField.balance,
  BrandsTableSortField.cost,
  BrandsTableSortField.revenue,
  BrandsTableSortField.stripe,
];

export enum OrdersTableSortField {
  updated_at = 'updated_at',
  created_at = 'created_at',
  brand_name = 'brand_name',
  status = 'status',
  asin = 'asin',
  placement = 'placement',
  budget = 'budget',
  in_stock = 'in_stock',
  sp_acos = 'sp_acos',
  spent = 'spent',
  clicks = 'clicks',
  impressions = 'impressions',
  sponsored_spend = 'sponsored_spend',
}

export enum PerformanceTableSortField {
  brand_name = 'brand_name',
  brand_id = 'brand_id',
  cost = 'ads_spent',
  expected_ad_cost = 'expected_ad_cost',
  total_sales14d = 'ads_revenue',
  profitability = 'profitability',
  impressions = 'impressions',
  acos = 'actual_acos',
  target_acos = 'target_acos',
  acos_diff = 'diff_acos',
  sp_acos = 'sp_acos',
  roas = 'roas',
  priority_rank = 'priority_rank',
}

export enum AdminMavTableSortField {
  price = 'price',
  rating = 'rating',
  num_rating = 'num_rating',
  updated_at = 'updated_at',
}

export enum BrandPriority {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  STRATEGIC = 'STRATEGIC',
  ONBOARDING = 'ONBOARDING',
}

export const menu = [
  {
    title: 'Overview',
    path: '/admin/overview',
    icon: DashboardIcon,
    available: true,
  },
  {
    title: 'Brands',
    path: '/admin/brands',
    icon: GroupIcon,
    available: true,
  },
  {
    title: 'Orders',
    path: '/admin/orders',
    icon: TocIcon,
    available: true,
  },
  {
    title: 'Performance',
    path: '/admin/performance',
    icon: SpeedIcon,
    available: true,
  },
  {
    title: 'Mav+ Analytics',
    path: '/admin/mav',
    icon: SpeedIcon,
    available: true,
  },
  {
    title: 'Mav+ Products',
    path: '/admin/mav-products',
    icon: ProductionQuantityLimitsIcon,
    available: true,
  },
  {
    title: 'Attribution Mapping',
    path: '/attribution-mappings',
    icon: ListAltIcon,
    available: true,
  },
  {
    title: 'Admins',
    path: '/admin/admin-users',
    icon: AddModeratorIcon,
    available: true,
  },
  {
    title: 'Connect',
    path: '/admin/integrations',
    icon: ShareIcon,
    available: true,
  },
];

export enum PerformingTableTitle {
  UNDER_PERFORMING_CAMPAIGNS = 'Under-Performing Campaigns',
  TOP_PERFORMING_CAMPAIGNS = 'Top Performing Campaigns',
  UNDER_PERFORMING_ADGROUPS = 'Under-Performing Adgroups',
}

export enum PerformingFilter {
  TOTAL_SALES_GTE = 'total_sales14d_gte',
  TOTAL_SALES_LTE = 'total_sales14d_lte',
  COST_GTE = 'cost_gte',
  COST_LTE = 'cost_lte',
  EXPECTED_AD_COST_GTE = 'expected_ad_cost_gte',
  EXPECTED_AD_COST_LTE = 'expected_ad_cost_lte',
  ACTUAL_ACOS_GTE = 'actual_acos_gte',
  ACTUAL_ACOS_LTE = 'actual_acos_lte',
  PROFITABILITY_GTE = 'profitability_gte',
  PROFITABILITY_LTE = 'profitability_lte',
  TARGET_ACOS_GTE = 'target_acos_gte',
  TARGET_ACOS_LTE = 'target_acos_lte',
  DIFF_ACOS_GTE = 'diff_acos_gte',
  DIFF_ACOS_LTE = 'diff_acos_lte',
}

export interface PerformanceFilterDataTypes {
  total_sales14d_gte: string;
  total_sales14d_lte: string;
  cost_gte: string;
  cost_lte: string;
  expected_ad_cost_gte: string;
  expected_ad_cost_lte: string;
  actual_acos_gte: string;
  actual_acos_lte: string;
  profitability_gte: string;
  profitability_lte: string;
  target_acos_gte: string;
  target_acos_lte: string;
  diff_acos_gte: string;
  diff_acos_lte: string;
}

export const PerformanceFilterData = {
  [PerformingFilter.TOTAL_SALES_GTE]: '',
  [PerformingFilter.TOTAL_SALES_LTE]: '',
  [PerformingFilter.COST_GTE]: '',
  [PerformingFilter.COST_LTE]: '',
  [PerformingFilter.EXPECTED_AD_COST_GTE]: '',
  [PerformingFilter.EXPECTED_AD_COST_LTE]: '',
  [PerformingFilter.ACTUAL_ACOS_GTE]: '',
  [PerformingFilter.ACTUAL_ACOS_LTE]: '',
  [PerformingFilter.PROFITABILITY_GTE]: '',
  [PerformingFilter.PROFITABILITY_LTE]: '',
  [PerformingFilter.TARGET_ACOS_GTE]: '',
  [PerformingFilter.TARGET_ACOS_LTE]: '',
  [PerformingFilter.DIFF_ACOS_GTE]: '',
  [PerformingFilter.DIFF_ACOS_LTE]: '',
};

export const adminBrandsLazyLoadingLimit = 200;

export enum MavBreakdowns {
  PUBLISHER = 'publisher',
  ASIN = 'asin',
  BRAND = 'brand',
  BRAND_PARENT = 'brandParent',
  PRODUCT = 'product',
}

export enum DailyValues {
  DAILY = 1,
  COMBINED = 0,
}

export const dailyOptions = [
  {
    title: 'Daily',
    value: DailyValues.DAILY,
  },
  {
    title: 'Combined',
    value: DailyValues.COMBINED,
  },
];

export const invoiceTitles = [
  { name: 'cost_month', title: 'Total Ads Cost Month' },
  { name: 'mav_cost', title: 'Mav+ Cost' },
  { name: 'period_fee', title: 'Period Fee (ASINs * fee)' },
  { name: 'mav_revenue', title: 'Mav+ Ad Costs' },
  { name: 'revenue_month', title: 'Mav+ GMV' },
];

export const adChannelTitles = [
  { value: 'MAV_ELIGIBLE', title: 'Mav+ Eligible' },
  { value: 'MAV_ONLY', title: 'Mav+ Only' },
];

export const COLUMNS_LC_KEY = 'admin_brands_columns';
