import { IGetQueryString } from 'common/ui/base-filters/types';
import { newOverviewActionTypes as T } from './action-types';

const postRequestReport = (data: NewOverviewTypes.RequestReportForm) => ({
  type: T.POST_REQUEST_REPORT,
  payload: data,
});

const postRequestReportSuccess = () => ({
  type: T.POST_REQUEST_REPORT_SUCCESS,
});

const postRequestReportError = (error: Error) => ({
  type: T.POST_REQUEST_REPORT_ERROR,
  payload: error,
});

const getOverviewData = (params: IGetQueryString) => ({
  type: T.GET_OVERVIEW_DATA,
  payload: params,
});

const getOverviewDataSuccess = (payload: NewOverviewTypes.OverviewStatsData) => ({
  type: T.GET_OVERVIEW_DATA_SUCCESS,
  payload,
});

const getOverviewDataError = (error: Error) => ({
  type: T.GET_OVERVIEW_DATA_ERROR,
  payload: error,
});

export const newOverviewActions = {
  postRequestReport,
  postRequestReportSuccess,
  postRequestReportError,
  getOverviewData,
  getOverviewDataSuccess,
  getOverviewDataError,
};
