import { SagaIterator } from '@redux-saga/types';
import { Action } from 'common/interfaces/root';
import { campaignCreationApi } from 'modules/campaign-creation/services/campaign-creation-api.service';
import toast from 'react-hot-toast';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
  campaignCreationActions,
  campaignCreationActionTypes as T,
  campaignsActions,
  campaignsActionTypes,
  descriptionsActions,
  descriptionsActionTypes,
  headlinesActions,
  headlinesActionTypes,
  keywordsActions,
  keywordsActionTypes,
  ordersActions,
  ordersActionTypes,
} from '../actions';

function* getOrders({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(campaignCreationApi.getOrders, payload.brand_id, payload.limit);
    yield put(ordersActions.getSuccess(data.results));
  } catch (err) {
    yield put(ordersActions.getError(err as Error));
  }
}

function* getHeadlines({ payload, meta }: Action): SagaIterator {
  if (meta.attempt > 5) {
    yield put(headlinesActions.getError(new Error(`Unable to generate headlines for ${payload.asin}`)));
    return;
  }

  try {
    const { data } = yield call(campaignCreationApi.getHeadlines, payload);

    if (!data?.content?.headlines?.length) {
      yield put(headlinesActions.get(payload, { ...meta, attempt: meta.attempt + 1 }));
      return;
    }

    yield put(headlinesActions.getSuccess(data.content.headlines));
  } catch (err) {
    yield put(headlinesActions.getError(err as Error));
    yield put(headlinesActions.get(payload, { ...meta, attempt: meta.attempt + 1 }));
  }
}

function* getDescriptions({ payload, meta }: Action): SagaIterator {
  if (meta.attempt > 5) {
    yield put(descriptionsActions.getError(new Error(`Unable to generate descriptions for ${payload.asin}`)));
    return;
  }

  try {
    const { data } = yield call(campaignCreationApi.getDescriptions, payload);

    if (!data?.content?.descriptions?.length) {
      yield put(descriptionsActions.get(payload, { ...meta, attempt: meta.attempt + 1 }));
      return;
    }

    yield put(descriptionsActions.getSuccess(data.content.descriptions));
  } catch (err) {
    yield put(descriptionsActions.getError(err as Error));
    yield put(descriptionsActions.get(payload, { ...meta, attempt: meta.attempt + 1 }));
  }
}

function* getKeywords({ payload, meta }: Action): SagaIterator {
  if (meta.attempt > 5) {
    yield put(keywordsActions.getError(new Error(`Unable to generate keywords for ${payload.asin}`)));
    return;
  }

  try {
    const { data } = yield call(campaignCreationApi.getKeywords, payload);

    if (!data?.content?.keywords?.length) {
      yield put(keywordsActions.get(payload, { ...meta, attempt: meta.attempt + 1 }));
      return;
    }

    yield put(keywordsActions.getSuccess(data.content.keywords));
  } catch (err) {
    yield put(keywordsActions.getError(err as Error));
    yield put(keywordsActions.get(payload, { ...meta, attempt: meta.attempt + 1 }));
  }
}

function* getCampaigns({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(campaignCreationApi.getCampaigns, payload);
    yield put(campaignsActions.getSuccess(data.suggestions));
  } catch (err) {
    yield put(campaignsActions.getError(err as Error));
  }
}

function* getPrecalculatedForm({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(campaignCreationApi.getPrecalculatedForm, payload);
    yield put(campaignCreationActions.getPrecalculatedFormSuccess(data));
  } catch (err) {
    yield put(campaignCreationActions.getPrecalculatedFormError(err as Error));
  }
}

function* postCampaign({ payload, meta }: Action): SagaIterator {
  try {
    yield call(campaignCreationApi.postCampaign, payload);
    yield put(campaignCreationActions.postCampaignSuccess());
    toast.success('Campaign created succesfully!');
    meta.onSuccess();
  } catch (err) {
    yield put(campaignCreationActions.postCampaignError(err as Error));
    toast.error('Error while creating campaign!');
  }
}

export function* campaignCreationSaga(): SagaIterator {
  yield all([
    takeEvery(ordersActionTypes.GET, getOrders),
    takeEvery(headlinesActionTypes.GET, getHeadlines),
    takeEvery(descriptionsActionTypes.GET, getDescriptions),
    takeEvery(keywordsActionTypes.GET, getKeywords),
    takeEvery(campaignsActionTypes.GET, getCampaigns),
    takeEvery(T.GET_PRECALCULATED_FORM, getPrecalculatedForm),
    takeEvery(T.POST_CAMPAIGN, postCampaign),
  ]);
}
