import { getDataListReducer } from 'common/store/reducers/data-list';
import { getFiltersReducer } from 'common/store/reducers/filters';
import { combineReducers } from 'redux';
import { Order, OrderLink } from '../../types';
import {
  attributionOrdersActionTypes,
  attributionOrdersFiltersActionTypes,
  attributionOrdersForProductsActionTypes,
  orderLinksActionTypes,
} from '../actions';
import total from './total';

export const ordersReducers = combineReducers({
  total,
  list: getDataListReducer<Order>(attributionOrdersActionTypes),
  listForProducts: getDataListReducer<Order>(attributionOrdersForProductsActionTypes),
  filters: getFiltersReducer(attributionOrdersFiltersActionTypes),
  links: getDataListReducer<OrderLink>(orderLinksActionTypes),
});
