import { ApiService } from 'common/services';

class TeamApiService extends ApiService {
  getBrandTeam = (id: number) => {
    return this.get(`/api/settings/brands/${id}/team`);
  };

  sendInvitation = (payload: { email: string; clientId: string }) => {
    return this.post('/api/settings/invitation', {
      email: payload.email,
      client_type: 'brand',
      client_id: payload.clientId,
    });
  };

  removeTeamMember = (payload: { brandId: string; userId: string }) => {
    return this.delete(`/api/settings/brands/${payload.brandId}/team/${payload.userId}`);
  };

  getPendingInvitations = (payload: { clientId: string }) => {
    return this.get('/api/settings/invitation/pending', {
      params: {
        client_type: 'brand',
        client_id: payload.clientId,
      },
    });
  };
}

export const teamApi = new TeamApiService(process.env.REACT_APP_API_URL);
