import { ApiService } from 'common/services';

export interface Integration {
  id: number;
  type: string;
  tenantRef: string;
  status: string;
  statusText: string;
  expires_at: string;
  updated_at: string;
  created_at: string;
  refresh_token?: string;
}

class IntegrationsApiService extends ApiService {
  getIntegrations = (brandId: number) => {
    return this.get(`/api/v2/integrations/tenant/${brandId}`);
  };

  initIntegration = (brandId: number, type: 'amazonads' | 'googleads', redirectUrl: string) => {
    return this.post(`/api/v2/integrations/tenant/${brandId}/${type}`, { redirect_url: redirectUrl });
  };

  getIntegration = (integrationId: number) => {
    return this.get(`/api/v2/integrations/${integrationId}`);
  };

  revokeIntegration = (data: { integrationId: number; brandId: number }) => {
    return this.delete(`/api/v2/integrations/${data.integrationId}?brand_id=${data.brandId}`);
  };
}

export const integrationsApi = new IntegrationsApiService(process.env.REACT_APP_API_URL);
