import { getDataListActions } from 'common/store/actions/data-list';
import { getFiltersActions } from 'common/store/actions/filters';
import { IGetQueryString } from 'common/ui/base-filters/types';
import { Order, TotalSummary } from '../../types';
import {
  attributionOrdersActionTypes,
  attributionOrdersFiltersActionTypes,
  attributionOrdersForProductsActionTypes,
  orderLinksActionTypes,
  ordersActionTypes as T,
} from './action-types';

const createOrder = (payload: Order, onSuccess: () => void) => ({
  type: T.CREATE_ORDER,
  payload,
  meta: { onSuccess },
});

const createOrderSuccess = () => ({
  type: T.CREATE_ORDER_SUCCESS,
});

const createOrderError = (error: Error) => ({
  type: T.CREATE_ORDER_ERROR,
  payload: error,
});

const createOrderWithRequest = (payload: Order, onSuccess: () => void) => ({
  type: T.CREATE_ORDER_WITH_REQUEST,
  payload,
  meta: { onSuccess },
});

const createOrderWithRequestSuccess = () => ({
  type: T.CREATE_ORDER_WITH_REQUEST_SUCCESS,
});

const createOrderWithRequestError = (error: Error) => ({
  type: T.CREATE_ORDER_WITH_REQUEST_ERROR,
  payload: error,
});

const patchOrder = (data: Order, id: string, brandId: number, onSuccess: () => void) => ({
  type: T.PATCH_ORDER,
  payload: { data, id, brandId },
  meta: { onSuccess },
});

const patchOrderSuccess = () => ({
  type: T.PATCH_ORDER_SUCCESS,
});

const patchOrderError = (error: Error) => ({
  type: T.PATCH_ORDER_ERROR,
  payload: error,
});

const deleteOrder = (orderId: string, brandId: number) => ({
  type: T.DELETE_ORDER,
  payload: { orderId, brandId },
});

const deleteOrderSuccess = () => ({
  type: T.DELETE_ORDER_SUCCESS,
});

const deleteOrderError = (error: Error) => ({
  type: T.DELETE_ORDER_ERROR,
  payload: error,
});

const getTotalOrders = (filters: IGetQueryString) => ({
  type: T.GET_TOTAL_ORDERS,
  payload: filters,
});

const getTotalOrdersSuccess = (combined: TotalSummary) => ({
  type: T.GET_TOTAL_ORDERS_SUCCESS,
  payload: { combined },
});

const getTotalOrdersError = (error: Error) => ({
  type: T.GET_TOTAL_ORDERS_ERROR,
  payload: error,
});

export const attributionOrdersActions = getDataListActions(attributionOrdersActionTypes);
export const attributionOrdersForProductsActions = getDataListActions(attributionOrdersForProductsActionTypes);
export const attributionOrdersFiltersActions = getFiltersActions(attributionOrdersFiltersActionTypes);
export const orderLinksActions = getDataListActions(orderLinksActionTypes);

export const ordersActions = {
  createOrder,
  createOrderSuccess,
  createOrderError,
  patchOrder,
  patchOrderSuccess,
  patchOrderError,
  getTotalOrders,
  getTotalOrdersSuccess,
  getTotalOrdersError,
  deleteOrder,
  deleteOrderSuccess,
  deleteOrderError,
  createOrderWithRequest,
  createOrderWithRequestSuccess,
  createOrderWithRequestError,
};
