import { Action } from 'common/interfaces/root';
import { attributionPerformanceActionTypes as T } from '../actions';

const initState: AdminTypes.ExportStoreTypes = {
  loading: false,
  error: null,
  link: '',
};

export default (
  state: AdminTypes.ExportStoreTypes = initState,
  { type, payload }: Action
): AdminTypes.ExportStoreTypes => {
  switch (type) {
    case T.GET_ATTRIBUTION_PERFORMANCE_ORDER_EXPORT:
      return { ...state, loading: true, link: '', error: null };

    case T.GET_ATTRIBUTION_PERFORMANCE_ORDER_EXPORT_SUCCESS:
      return { ...state, loading: false, link: payload };

    case T.GET_ATTRIBUTION_PERFORMANCE_ORDER_EXPORT_ERROR:
      return { ...state, loading: false, error: payload };

    default:
      return state;
  }
};
