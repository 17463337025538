import { PERMISSIONS } from 'common/constants/permissions';
import { User } from 'common/interfaces/root';

const brandsWithDeprecatedAccess = [1100];

class UserService {
  permissions: Array<string> = [];

  user: User = null;

  setPermissions = (permissions: Array<string>) => {
    this.permissions = permissions;
  };

  setUser = (user: User) => {
    this.user = user;
  };

  hasAccess = (permissions: Array<string>) => {
    if (!this.permissions?.length) return false;
    return permissions.every(requestedPermission =>
      this.permissions.some(permission => permission === requestedPermission)
    );
  };

  hasDeprecatedAccess = () => {
    return !!this.user.brand_ids.filter(value => brandsWithDeprecatedAccess.includes(value)).length;
  };
}

export const userService = new UserService();

userService.setPermissions(Object.values(PERMISSIONS));
