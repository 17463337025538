const NEW_OVERVIEW_MODULE = '[new-overview]';

const POST_REQUEST_REPORT = `${NEW_OVERVIEW_MODULE} POST_REQUEST_REPORT`;
const POST_REQUEST_REPORT_SUCCESS = `${NEW_OVERVIEW_MODULE} POST_REQUEST_REPORT_SUCCESS`;
const POST_REQUEST_REPORT_ERROR = `${NEW_OVERVIEW_MODULE} POST_REQUEST_REPORT_ERROR`;

const GET_OVERVIEW_DATA = `${NEW_OVERVIEW_MODULE} GET_OVERVIEW_DATA`;
const GET_OVERVIEW_DATA_SUCCESS = `${NEW_OVERVIEW_MODULE} GET_OVERVIEW_DATA_SUCCESS`;
const GET_OVERVIEW_DATA_ERROR = `${NEW_OVERVIEW_MODULE} GET_OVERVIEW_DATA_ERROR`;

export const newOverviewActionTypes = {
  POST_REQUEST_REPORT,
  POST_REQUEST_REPORT_SUCCESS,
  POST_REQUEST_REPORT_ERROR,
  GET_OVERVIEW_DATA,
  GET_OVERVIEW_DATA_SUCCESS,
  GET_OVERVIEW_DATA_ERROR,
};
