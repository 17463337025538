import { Action } from 'common/interfaces/root';
import { adminActionTypes as T } from '../actions';

const initState: AdminTypes.MavStatsStoreTypes = {
  loading: false,
  error: null,
  data: {
    total: 0,
    single_count: 0,
    parent_count: 0,
    variations_count: 0,
  },
};

export default (
  state: AdminTypes.MavStatsStoreTypes = initState,
  { type, payload }: Action
): AdminTypes.MavStatsStoreTypes => {
  switch (type) {
    case T.GET_MAV_ASINS_STATS:
      return { ...state, loading: true, error: null };

    case T.GET_MAV_ASINS_STATS_SUCCESS:
      return { ...state, loading: false, data: payload };

    case T.GET_MAV_ASINS_STATS_ERROR:
      return { ...state, loading: false, error: payload };

    default:
      return state;
  }
};
