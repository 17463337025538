import { Integration } from 'common/services/api/integrations-api.service';
import { getDataListReducer } from 'common/store/reducers/data-list';
import { combineReducers } from 'redux';
import {
  attributionProfilesListTypes,
  attributionPublishersListTypes,
  attributionStoresListTypes,
  attributionAsinsListTypes,
  integrationsListActionTypes,
  userWalletActionTypes,
  walletTransactionsActionTypes,
  docsListTypes,
} from '../actions';
import brand from './brand';
import attribution from './attribution';
import wallet from './wallet';
import team from './team';
import newTeam from './new-team';
import pendingInvitations from './pending-invitations';

export const brandSettingsReducer = combineReducers({
  integrations: getDataListReducer<Integration>(integrationsListActionTypes),
  brand,
  attributionProfiles: getDataListReducer<BrandSettingsTypes.AttributionProfile>(attributionProfilesListTypes),
  attributionPublishers: getDataListReducer<BrandSettingsTypes.AttributionPublisher>(attributionPublishersListTypes),
  attribution,
  wallet,
  team,
  newTeam,
  userWallet: getDataListReducer<BrandSettingsTypes.Card>(userWalletActionTypes),
  walletTransactions: getDataListReducer<BrandSettingsTypes.Transaction>(walletTransactionsActionTypes),
  attributionStores: getDataListReducer<BrandSettingsTypes.AttributionStoreEntity>(attributionStoresListTypes),
  asins: getDataListReducer<string>(attributionAsinsListTypes),
  documents: getDataListReducer<BrandSettingsTypes.Document>(docsListTypes),
  pendingInvitations,
});
