import { ApiService } from 'common/services';
import { IGetQueryString } from 'common/ui/base-filters/types';
import { parseFilters } from 'common/utils/parse-filters';

class NewOverviewApiService extends ApiService {
  getOverviewData = (filters: IGetQueryString) => {
    return this.get(`/api/brands/${filters.brand_id}/overview`, { params: parseFilters(filters) });
  };

  postRequestReport = (data: NewOverviewTypes.RequestReportForm) => {
    return this.post(`/api/brands/${data.brand_id}/requests/reports`, data);
  };
}

export const newOverviewApi = new NewOverviewApiService(process.env.REACT_APP_API_URL);
