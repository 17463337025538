import { getDataListActions } from 'common/store/actions/data-list';
import { IGetQueryString } from 'common/ui/base-filters/types';
import {
  attributionAdGroupsActionTypes,
  attributionPerformanceActionTypes as T,
  attributionPerformanceOrdersActionTypes,
} from './action-types';

const getAttributionPerformance = (params: IGetQueryString) => ({
  type: T.GET_ATTRIBUTION_PERFORMANCE,
  payload: params,
});

const getAttributionPerformanceSuccess = (
  adGroups: Array<AttributionPerformanceTypes.PerformanceAdGroup>,
  total: Array<AttributionPerformanceTypes.PerformanceWidgetsData>,
  campaigns: Array<string>
) => ({
  type: T.GET_ATTRIBUTION_PERFORMANCE_SUCCESS,
  payload: { adGroups, total, campaigns },
});

const getAttributionPerformanceError = (error: Error) => ({
  type: T.GET_ATTRIBUTION_PERFORMANCE_ERROR,
  payload: error,
});

const getAttributionPerformanceOrderExport = (params: IGetQueryString) => ({
  type: T.GET_ATTRIBUTION_PERFORMANCE_ORDER_EXPORT,
  payload: params,
});

const getAttributionPerformanceOrderExportSuccess = (payload: string) => ({
  type: T.GET_ATTRIBUTION_PERFORMANCE_ORDER_EXPORT_SUCCESS,
  payload,
});

const getAttributionPerformanceOrderExportError = (error: Error) => ({
  type: T.GET_ATTRIBUTION_PERFORMANCE_ORDER_EXPORT_ERROR,
  payload: error,
});

const changeKeywordInfo = (
  params: { brandId: number; keywordId: string; cpc?: number; status?: string },
  onSuccess: (value?: string) => void
) => ({
  type: T.CHANGE_KEYWORD_INFO,
  payload: params,
  meta: { onSuccess },
});

const changeCpcSuccess = () => ({
  type: T.CHANGE_CPC_SUCCESS,
});

const changeCpcError = () => ({
  type: T.CHANGE_CPC_ERROR,
});

const changeCampaignStatus = (brandId: number, campaignId: string, status: string, onSuccess?: () => void) => ({
  type: T.CHANGE_CAMPAIGN_STATUS,
  payload: { brandId, campaignId, status },
  meta: { onSuccess },
});

const changeCampaignStatusSuccess = () => ({
  type: T.CHANGE_CAMPAIGN_STATUS_SUCCESS,
});

const changeCampaignStatusError = () => ({
  type: T.CHANGE_CAMPAIGN_STATUS_ERROR,
});

const changeAdGroupStatus = (brandId: number, adgroupId: string, status: string, onSuccess?: () => void) => ({
  type: T.CHANGE_ADGROUP_STATUS,
  payload: { brandId, adgroupId, status },
  meta: { onSuccess },
});

const changeAdGroupStatusSuccess = () => ({
  type: T.CHANGE_ADGROUP_STATUS_SUCCESS,
});

const changeAdGroupStatusError = () => ({
  type: T.CHANGE_ADGROUP_STATUS_ERROR,
});

export const attributionPerformanceOrdersActions = getDataListActions<AttributionPerformanceTypes.Order>(
  attributionPerformanceOrdersActionTypes
);
export const attributionAdGroupsActions =
  getDataListActions<AttributionPerformanceTypes.PerformanceAdGroup>(attributionAdGroupsActionTypes);

export const attributionPerformanceActions = {
  getAttributionPerformance,
  getAttributionPerformanceSuccess,
  getAttributionPerformanceError,
  getAttributionPerformanceOrderExport,
  getAttributionPerformanceOrderExportSuccess,
  getAttributionPerformanceOrderExportError,
  changeKeywordInfo,
  changeCpcSuccess,
  changeCpcError,
  changeCampaignStatus,
  changeCampaignStatusSuccess,
  changeCampaignStatusError,
  changeAdGroupStatus,
  changeAdGroupStatusSuccess,
  changeAdGroupStatusError,
};
