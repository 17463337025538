import { SagaIterator } from '@redux-saga/types';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Action, ErrorRequest } from 'common/interfaces/root';
import { integrationsApi } from 'common/services/api/integrations-api.service';
import { presetsActions } from 'common/store/actions/presets';
import { adminBrandsApi } from 'modules/admin/services/admin-brands.service';
import { invoicesApi } from 'modules/admin/services/invoices.service';
import { getPreviousRange } from 'modules/admin/utils';
import { paymentApi } from 'modules/brand-settings/services/payment-api.service';
import toast from 'react-hot-toast';
import { all, call, delay, put, select, takeEvery } from 'redux-saga/effects';
import {
  adminActionTypes,
  adminActions,
  adminBrandsListActions,
  adminBrandsListActionTypes,
  performanceTableActions,
  performanceTableActionTypes,
  pendingBrandsActions,
  pendingBrandsActionTypes,
  ordersListActions,
  ordersListActionTypes,
  brandParentsActionTypes,
  brandParentsActions,
  brandNoteActionTypes,
  brandNoteActions,
  publishersActionTypes,
  publishersActions,
  adminsPageActions,
  adminsPageActionTypes,
  pubMaverickTableActionTypes,
  pubMaverickTableAction,
  mavProductsActions,
  mavProductsActionTypes,
  brandsProductsActionTypes,
  brandsProductsActions,
} from '../actions';
import { selectAdminBrandsFilters, selectAdminBrandsLimitPageAmount } from '../selectors';
import { utilRequestErrorMessage } from '../../../../common/utils/utilRequestErrorMessage';

// eslint-disable-next-line consistent-return
export function* checkExportStatus(id: string): SagaIterator<string> {
  let status = '';

  while (status !== 'success') {
    const { data } = yield call(adminBrandsApi.getExportStatus, id);

    switch (data.status) {
      case 'pending':
        status = data.status;
        yield delay(1000);
        break;
      case 'success':
        status = data.status;
        return data.link;
      case 'failed':
        status = data.status;
        throw new Error('export failed');
      default:
        throw new Error('export failed');
    }
  }
}

function* getBrands({ payload, meta }: Action) {
  try {
    const { data } = yield call(adminBrandsApi.getBrands, payload);
    if (meta.reinit) {
      yield put(adminBrandsListActions.getSuccess(data.brands, data.brands_count, data.meta));
    } else {
      yield put(adminBrandsListActions.getSuccessLazy(data.brands, data.brands_count, data.meta));
    }
    yield put(adminBrandsListActions.changeCanLoadMore(data.brands.length !== 0));
  } catch (err) {
    yield put(adminBrandsListActions.getError(err as Error));
  }
}

function* refreshBrands(): SagaIterator {
  const { limit } = yield select(selectAdminBrandsLimitPageAmount);
  const filters = yield select(selectAdminBrandsFilters);
  yield put(
    adminBrandsListActions.get(
      { limit, offset: 0, search: filters.search, previousPerformance: true },
      { reinit: true }
    )
  );
}

function* createBrand({ payload }: Action) {
  try {
    if (payload.newBrand) {
      const { data } = yield call(adminBrandsApi.addBrandsParents, payload.newBrand);
      yield call(adminBrandsApi.createBrand, { ...payload.brand, brands_parents_id: data.brands_parents.id });
    } else {
      yield call(adminBrandsApi.createBrand, payload.brand);
    }
    if (payload?.brand?.mav_eligible && payload?.brand?.mav_eligible_products) {
      yield call(adminBrandsApi.makeAllBrandsMavEligable, { id: payload?.id, ad_channel: 'MAV_ELIGIBLE' });
    }
    yield call(refreshBrands);
    toast.success('Brand created successfully!');
    yield put(presetsActions.getFiltersPresets());
  } catch (err) {
    utilRequestErrorMessage(err, 'Error while creating a brand!');
    yield put(adminActions.createBrandError(err as Error));
  }
}

function* editBrand({ payload }: Action) {
  try {
    if (payload.newBrand) {
      const { data } = yield call(adminBrandsApi.addBrandsParents, payload.newBrand);
      yield call(adminBrandsApi.editBrand, payload.brand, data.brands_parents.id);
    } else {
      yield call(adminBrandsApi.editBrand, payload.brand, payload.id);
    }
    yield put(adminBrandsListActions.updateEntity('id', payload.id, payload.brand));
    toast.success('Brand updated successfully!');
    yield put(presetsActions.getFiltersPresets());
  } catch (err) {
    utilRequestErrorMessage(err, 'Error while updating a brand!');
    yield put(adminActions.editBrandError(err as Error));
  }
}

function* deleteBrand({ payload }: Action) {
  try {
    yield call(adminBrandsApi.deleteBrand, payload);
    yield put(adminBrandsListActions.deleteSuccess('id', payload));
    toast.success('Brand deleted successfully!');
    yield put(presetsActions.getFiltersPresets());
  } catch (err) {
    toast.error('Error while deleting a brand!');
    yield put(adminActions.deleteBrandError(err as Error));
  }
}

function* generateIntegrationLink({ payload }: Action) {
  try {
    const { data } = yield call(
      integrationsApi.initIntegration,
      payload.brandId,
      payload.integrationType,
      `${window.location.origin}/success`
    );
    navigator.clipboard.writeText(data.link);
    toast.success('OAuth link copied to the clipboard!');
    yield put(adminActions.generateIntegrationLinkSuccess());
  } catch (err) {
    toast.error('Error while initializing integration');
    yield put(adminActions.generateIntegrationLinkError());
  }
}

function* generateAttrTag({ payload }: Action) {
  try {
    const { data } = yield call(adminBrandsApi.generateTag, payload.publisherId, payload.asin, payload.url);
    if (!data.tag) throw new Error();
    navigator.clipboard.writeText(data.tag);
    toast.success('Attribution tag copied to the clipboard!');
    yield put(adminActions.generateAttrTagSuccess());
  } catch (err) {
    toast.error('ASIN doesn’t exist');
    yield put(adminActions.generateAttrTagError());
  }
}

function* updateBalance({ payload }: Action) {
  try {
    let walletID = payload.walletId;

    if (!walletID) {
      const { data } = yield call(paymentApi.addNewPayment, { client_id: payload.brandId, client_type: 'brand' });
      walletID = data.wallet.id;
    }

    yield call(adminBrandsApi.addBalance, walletID, payload.value, payload.desc);
    yield put(adminActions.updateBrandBalanceSuccess());
    yield put(
      adminBrandsListActions.updateEntity('id', payload.brandId, {
        wallet_id: walletID,
        balance: (payload.oldBalance || 0) + payload.value,
      })
    );
    toast.success('Brand balance updated successfully!');
  } catch (err) {
    // eslint-disable-next-line quotes
    toast.error("Error while updating brand's balance");
    yield put(adminActions.updateBrandBalanceError(err as Error));
  }
}

function* getUnifiedReporting({ payload }: Action) {
  try {
    const { start_date, end_date } = getPreviousRange(payload.params.start_date, payload.params.end_date);

    const [{ data: currentData }, { data: previousData }] = yield all([
      call(adminBrandsApi.getUnifiedReporting, payload.params),
      call(adminBrandsApi.getUnifiedReporting, { ...payload.params, start_date, end_date }),
    ]);
    yield put(adminActions.getUnifiedReportingSuccess(currentData, previousData));
  } catch (err) {
    yield put(adminActions.getUnifiedReportingError(err as Error));
  }
}

function* getBrandsOverview({ payload }: Action) {
  try {
    const { data } = yield call(adminBrandsApi.getBrandsOverview, payload);
    yield put(performanceTableActions.getSuccess(data.results, data.count));
  } catch (err) {
    yield put(performanceTableActions.getError(err as Error));
  }
}

function* getPendingBrands({ payload }: Action) {
  try {
    const { data } = yield call(adminBrandsApi.getPendingBrands, payload);
    yield put(pendingBrandsActions.getSuccess(data));
  } catch (err) {
    yield put(pendingBrandsActions.getError(err as Error));
  }
}

function* getOrders({ payload }: Action) {
  try {
    const { data } = yield call(adminBrandsApi.getOrders, payload);
    yield put(ordersListActions.getSuccess(data.orders, data.total));
  } catch (err) {
    yield put(ordersListActions.getError(err as Error));
  }
}

function* getOrderHistory({ payload }: Action) {
  try {
    const { data } = yield call(adminBrandsApi.getOrderHistory, payload);
    yield put(adminActions.getOrderHistorySuccess(data));
  } catch (err) {
    yield put(adminActions.getOrderHistoryError(err as Error));
  }
}

function* getBrandsExports({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(adminBrandsApi.getBrandsExports, payload.params);

    const link = yield call(checkExportStatus, data.job_id);

    window.open(link);

    yield put(adminActions.getBrandsExportsSuccess(link));
  } catch (err) {
    yield put(adminActions.getBrandsExportsError(err as Error));
  }
}

function* getOrdersExports({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(adminBrandsApi.getListStatsExports, payload.params);

    const link = yield call(checkExportStatus, data.job_id);

    window.open(link);

    yield put(adminActions.getOrdersExportsSuccess(link));
  } catch (err) {
    yield put(adminActions.getOrdersExportsError(err as Error));
  }
}

function* getBrandsPerformanceExports({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(adminBrandsApi.getBrandsPerformanceExports, payload.params);

    const link = yield call(checkExportStatus, data.job_id);

    window.open(link);

    yield put(adminActions.getBrandsPerformanceExportSuccess(link));
  } catch (err) {
    toast.error('Error while exporting data!');
    yield put(adminActions.getBrandsPerformanceExportError(err as Error));
  }
}

function* getBrandsParents(): SagaIterator {
  try {
    const { data } = yield call(adminBrandsApi.getBrandsParents);
    yield put(brandParentsActions.getSuccess(data.brands_parents, data.brands_parents_count));
  } catch (err) {
    yield put(brandParentsActions.getError(err as Error));
  }
}

function* addBrandsParents({ payload, meta }: Action): SagaIterator {
  try {
    const { data } = yield call(adminBrandsApi.addBrandsParents, payload.name);
    if (data?.brands_parents?.id) {
      meta?.onSuccess({ ...payload.brand, brands_parents_id: data?.brands_parents?.id });
      meta.onRefresh();
    }
  } catch (err) {
    toast.error((err as AdminTypes.ErrorMessage).response?.data?.message);
  }
}

function* patchBrandsParents({ payload, meta }: Action): SagaIterator {
  try {
    yield call(adminBrandsApi.patchBrandsParents, payload);
    meta.onRefresh();
    toast.success('Brands parent updated successfully!');
  } catch (err) {
    toast.error((err as AdminTypes.ErrorMessage).response?.data?.message ?? 'Error while updating brands parent!');
  }
}

function* deleteBrandsParents({ payload, meta }: Action): SagaIterator {
  try {
    yield call(adminBrandsApi.deleteBrandsParents, payload);
    meta?.onSuccess();
  } catch (err) {
    //
  }
}

function* updateAsinFee({ payload }: Action) {
  try {
    yield call(adminBrandsApi.updateAsinFee, payload);
    yield put(adminBrandsListActions.updateEntity('id', payload.brandId, { asin_fee: payload.asinFee }));
    toast.success('Brand updated successfully!');
  } catch (err) {
    yield put(adminActions.updateBrandAsinFeeError(err as Error));
  }
}

function* getBrandNotes({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(adminBrandsApi.getBrandsNotes, payload.brand_id);
    yield put(brandNoteActions.getSuccess(data.brand_notes, data.brand_notes_count));
  } catch (err) {
    yield put(brandNoteActions.getError(err as Error));
  }
}

function* addBrandNote({ payload, meta }: Action): SagaIterator {
  try {
    yield call(adminBrandsApi.postBrandsNote, payload);
    yield put(adminActions.addBrandNoteSuccess());
    meta.onSuccess();
    toast.success('Note added successfully!');
  } catch (err) {
    toast.error('Error while adding note!');
    yield put(adminActions.addBrandNoteError(err as Error));
  }
}

function* deleteBrandNote({ payload, meta }: Action): SagaIterator {
  try {
    yield call(adminBrandsApi.deleteBrandsNote, payload);
    yield put(adminActions.deleteBrandNoteSuccess());
    meta.onSuccess();
    toast.success('Note removed successfully!');
  } catch (err) {
    toast.error('Error while removing note!');
    yield put(adminActions.deleteBrandNoteError(err as Error));
  }
}

function* editBrandNote({ payload, meta }: Action): SagaIterator {
  try {
    yield call(adminBrandsApi.editBrandsNote, payload);
    yield put(adminActions.editBrandNoteSuccess());
    meta.onSuccess();
    toast.success('Note updated successfully!');
  } catch (err) {
    toast.error('Error while updating note!');
    yield put(adminActions.editBrandNoteError(err as Error));
  }
}

function* postIntegrationCopy({ payload }: Action): SagaIterator {
  try {
    yield call(adminBrandsApi.postIntegrationCopy, payload);
    yield put(adminActions.postCopyIntegrationSuccess());
    toast.success('Integration copied successfully!');
  } catch (err) {
    toast.error('Error while copying integration!');
    yield put(adminActions.postCopyIntegrationError(err as Error));
  }
}

function* getPublishers(): SagaIterator {
  try {
    const { data } = yield call(adminBrandsApi.getPublishers);
    yield put(publishersActions.getSuccess(data.publishers));
  } catch (err) {
    yield put(publishersActions.getError(err as Error));
  }
}

function* getAdminsList(): SagaIterator {
  try {
    const { data } = yield call(adminBrandsApi.getAdminsList);
    yield put(adminsPageActions.getSuccess(data.admins));
  } catch (err) {
    yield put(adminsPageActions.getError(err as Error));
  }
}

function* deleteAdminRole({ payload, meta }: Action): SagaIterator {
  try {
    yield call(adminBrandsApi.deleteAdminRole, payload);
    yield put(adminActions.deleteAdminRoleSuccess());
    toast.success('Admin role removed successfully!');
    meta.onSuccess();
  } catch (err) {
    toast.error('Error while removing admin role!');
    yield put(adminActions.deleteAdminRoleError(err as Error));
  }
}

function* postNewAdmin({ payload, meta }: Action): SagaIterator {
  try {
    yield call(adminBrandsApi.postNewAdmin, payload);
    yield put(adminActions.postNewAdminSuccess());
    toast.success('New admin added successfully!');
    meta.onSuccess();
  } catch (err) {
    toast.error('Error while adding new admin!');
    yield put(adminActions.postNewAdminError(err as Error));
  }
}

function* inviteAdminMember({ payload, meta }: Action): SagaIterator {
  try {
    const { data } = yield call(adminBrandsApi.inviteAdminMember, { email: payload, client_type: 'brand' });
    yield put(adminActions.inviteAdminMemberSuccess());
    toast.success(data.message);
    meta.onSuccess();
  } catch (err) {
    toast.error('Error sending admin invitation!');
    yield put(adminActions.inviteAdminMemberError(err as Error));
  }
}

function* getPublisherMavPerformance({ payload }: Action) {
  try {
    const { data } = yield call(adminBrandsApi.getPublisherMaverickPerformance, payload);
    yield put(pubMaverickTableAction.getSuccess(data.results, data.count));
  } catch (err) {
    yield put(pubMaverickTableAction.getError(err as Error));
  }
}

function* getPubMavPerformanceSummary({ payload }: Action) {
  try {
    const { data } = yield call(adminBrandsApi.getPublisherMaverickPerformanceSummary, payload);
    yield put(adminActions.getMavPlusPerformanceSummarySuccess(data));
  } catch (err) {
    yield put(adminActions.getPubMavPerformanceExportError(err as Error));
  }
}

function* getPubMavPerformanceExport({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(adminBrandsApi.getPubMavPerformanceExports, payload.params);

    const link = yield call(checkExportStatus, data.job_id);

    window.open(link);

    yield put(adminActions.getPubMavPerformanceExportSuccess(link));
  } catch (err) {
    toast.error('Error while exporting data!');
    yield put(adminActions.getPubMavPerformanceExportError(err as Error));
  }
}

function* getInvoices({ payload, meta }: Action) {
  try {
    const { data } = yield call(invoicesApi.getInvoices, payload);
    meta?.onSuccess(data.invoices);
    yield put(adminActions.getInvoicesSuccess(data.invoices));
  } catch (err) {
    yield put(adminActions.getInvoicesError(err as Error));
  }
}

function* createInvoice({ payload, meta }: Action) {
  try {
    const { data } = yield call(invoicesApi.createInvoice, payload);
    if (!payload.markPaid) {
      meta?.onUpdated(data.invoiceId, false);
      const { data: char } = yield call(invoicesApi.chargeInvoice, { id: data.invoiceId, markPaid: false });
      toast.success(char.reason);
    }
    meta?.onUpdated(data.invoiceId, true);
    if (data?.url) {
      meta?.onSuccess(data.url);
      yield put(adminActions.createInvoiceSuccess(data.url));
    }
    toast.success('Invoice created successfully!');
  } catch (error: ErrorRequest) {
    if (error.response.data?.message?.reason) {
      toast.error(error.response.data?.message?.reason);
    } else {
      toast.error('Error while creating the invoice!');
    }
  }
}

function* getInvoiceById({ payload }: Action) {
  try {
    const { data } = yield call(invoicesApi.getInvoiceById, payload);
    yield put(adminActions.getInvoiceByIdSuccess(data.invoice));
  } catch (err) {
    yield put(adminActions.getInvoiceByIdError(err as Error));
  }
}

function* chargeInvoice({ payload, meta }: Action) {
  try {
    const { data } = yield call(invoicesApi.chargeInvoice, payload);
    if (data?.message?.charged) {
      toast.success('Invoice charged successfully!');
      meta?.onUpdated(payload.id, true);
      yield put(adminActions.chargeInvoiceSuccess(data?.message?.reason));
    }
  } catch (error: ErrorRequest) {
    if (error.response.data?.message?.reason) {
      toast.error(error.response.data?.message?.reason);
      meta?.onUpdated(payload.id, false);
    } else {
      toast.error('Error while charging invoice!');
    }
  }
}

function* getMavProducts({ payload }: Action) {
  try {
    const { data } = yield call(adminBrandsApi.getMavProducts, payload);
    yield put(mavProductsActions.getSuccess(data.products, data.products_count));
  } catch (err) {
    yield put(mavProductsActions.getError(err as Error));
  }
}

function* getMavProductsExports({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(adminBrandsApi.getMavProductsExports, payload.params);

    const link = yield call(checkExportStatus, data.job_id);

    window.open(link);

    yield put(adminActions.mavProductsExportsSuccess(link));
  } catch (err) {
    yield put(adminActions.mavProductsExportsError(err as Error));
  }
}

function* getMavStats({ payload }: Action) {
  try {
    const { data } = yield call(adminBrandsApi.getMavStats, payload);
    yield put(adminActions.getMavAsinsStatsSuccess(data));
  } catch (err) {
    yield put(adminActions.getMavAsinsStatsError(err as Error));
  }
}

function* getMavInternalProductsExports({ payload }: Action): SagaIterator {
  try {
    const { data } = yield call(adminBrandsApi.getMavInternalProductsExports, payload.params);

    const doc = yield call(
      adminBrandsApi.getMavInternalProductsExportsDyId,
      data.documents.filter((item: { export_type: string }) => item.export_type === payload.params.exportType)[0]?.id
    );

    window.open(doc?.data?.document?.link);

    yield put(adminActions.mavInternalProductsExportsSuccess());
  } catch (err) {
    yield put(adminActions.mavInternalProductsExportsError(err as Error));
  }
}

function* getBrandsSettingsPeriods({ payload }: Action) {
  try {
    const { data } = yield call(adminBrandsApi.getBrandsSettingsPeriods, payload);
    yield put(brandsProductsActions.getSuccess(data.results, data.total));
  } catch (err) {
    yield put(brandsProductsActions.getError(err as Error));
  }
}

function* patchBrandsSettingsPeriods({ payload, meta }: Action) {
  try {
    yield call(adminBrandsApi.patchBrandsSettingsPeriods, payload);
    meta?.onSuccess();
  } catch (err: ErrorRequest) {
    toast.error(err?.response?.data?.message ?? 'Something went wrong');
  }
}

function* postBrandsSettingsPeriod({ payload, meta }: Action) {
  try {
    yield call(adminBrandsApi.postBrandsSettingsPeriod, payload);
    meta?.onSuccess();
  } catch (err: ErrorRequest) {
    toast.error(err?.response?.data?.message ?? 'Something went wrong');
  }
}

function* deleteBrandsSettingsPeriod({ payload, meta }: Action) {
  try {
    yield call(adminBrandsApi.deleteBrandsSettingsPeriod, payload);
    meta?.onSuccess();
  } catch (err: ErrorRequest) {
    toast.error(err?.response?.data?.message ?? 'Something went wrong');
  }
}

function* getPublishersById({ payload }: Action) {
  try {
    const { data } = yield call(adminBrandsApi.getPublishersById, payload);
    yield put(adminActions.getBrandsPublishersSuccess(data.publishers));
  } catch (err: ErrorRequest) {
    toast.error(err?.response?.data?.message ?? 'Something went wrong');
    yield put(adminActions.getBrandsPublishersError(err as Error));
  }
}

function* addPublisherById({ payload }: Action) {
  try {
    yield call(adminBrandsApi.addPublisherById, { id: payload.id, publisher_id: payload.publisher_id });
  } catch (err: ErrorRequest) {
    yield put(adminActions.addBrandsPublishersError(err as Error));
  }
}

function* deletePublisherById({ payload }: Action) {
  try {
    yield call(adminBrandsApi.removePublisherById, { id: payload.id, publisher_id: payload.publisher_id });
  } catch (err: ErrorRequest) {
    yield put(adminActions.deleteBrandsPublishersError(err as Error));
  }
}

function* switchAllBrandProductsMavEligibleStatus({ payload, meta }: Action) {
  try {
    yield call(adminBrandsApi.makeAllBrandsMavEligable, {
      id: payload?.id,
      ad_channel: payload.isMavEligible ? 'MAV_ELIGIBLE' : null,
      products_brands_ids: payload.products_brands_ids,
      affectAssociatedProducts: payload.affectAssociatedProducts,
    });
    toast.success('Products Mav+ Eligible status updated');
    meta.onSuccess();
  } catch (err: ErrorRequest) {
    toast.error(err?.response?.data?.message ?? 'Something went wrong');
    meta.onError();
  }
}

export function* adminSaga(): SagaIterator {
  yield all([
    takeEvery(adminBrandsListActionTypes.GET, getBrands),
    takeEvery(adminActionTypes.CREATE_BRAND, createBrand),
    takeEvery(adminActionTypes.EDIT_BRAND, editBrand),
    takeEvery(adminActionTypes.DELETE_BRAND, deleteBrand),
    takeEvery(adminActionTypes.GENERATE_INTEGRATION_LINK, generateIntegrationLink),
    takeEvery(adminActionTypes.UPDATE_BRAND_BALANCE, updateBalance),
    takeEvery(adminActionTypes.GET_UNIFIED_REPORTING, getUnifiedReporting),
    takeEvery(performanceTableActionTypes.GET, getBrandsOverview),
    takeEvery(pendingBrandsActionTypes.GET, getPendingBrands),
    takeEvery(ordersListActionTypes.GET, getOrders),
    takeEvery(adminActionTypes.GET_ORDER_HISTORY, getOrderHistory),
    takeEvery(adminActionTypes.GET_BRANDS_EXPORTS, getBrandsExports),
    takeEvery(adminActionTypes.GET_ORDERS_EXPORTS, getOrdersExports),
    takeEvery(adminActionTypes.GET_BRANDS_PERFORMANCE_EXPORTS, getBrandsPerformanceExports),
    takeEvery(brandParentsActionTypes.GET, getBrandsParents),
    takeEvery(adminActionTypes.ADD_BRANDS_PARENTS, addBrandsParents),
    takeEvery(adminActionTypes.PATCH_BRANDS_PARENTS, patchBrandsParents),
    takeEvery(adminActionTypes.DELETE_BRANDS_PARENTS, deleteBrandsParents),
    takeEvery(adminActionTypes.UPDATE_BRAND_ASIN_FEE, updateAsinFee),
    takeEvery(brandNoteActionTypes.GET, getBrandNotes),
    takeEvery(adminActionTypes.ADD_BRAND_NOTE, addBrandNote),
    takeEvery(adminActionTypes.DELETE_BRAND_NOTE, deleteBrandNote),
    takeEvery(adminActionTypes.EDIT_BRAND_NOTE, editBrandNote),
    takeEvery(adminActionTypes.POST_COPY_INTEGRATION, postIntegrationCopy),
    takeEvery(publishersActionTypes.GET, getPublishers),
    takeEvery(adminActionTypes.GENERATE_ATTR_TAG, generateAttrTag),
    takeEvery(adminsPageActionTypes.GET, getAdminsList),
    takeEvery(adminActionTypes.DELETE_ADMIN_ROLE, deleteAdminRole),
    takeEvery(adminActionTypes.POST_NEW_ADMIN, postNewAdmin),
    takeEvery(adminActionTypes.INVITE_ADMIN_MEMBER, inviteAdminMember),
    takeEvery(pubMaverickTableActionTypes.GET, getPublisherMavPerformance),
    takeEvery(adminActionTypes.GET_PUB_MAV_PERFORMANCE_EXPORTS, getPubMavPerformanceExport),
    takeEvery(adminActionTypes.GET_MAV_PERFORMANCE_SUMMARY, getPubMavPerformanceSummary),
    takeEvery(adminActionTypes.GET_INVOICES, getInvoices),
    takeEvery(adminActionTypes.CREATE_INVOICE, createInvoice),
    takeEvery(adminActionTypes.GET_INVOICE_BY_ID, getInvoiceById),
    takeEvery(adminActionTypes.CHARGE_INVOICE, chargeInvoice),
    takeEvery(mavProductsActionTypes.GET, getMavProducts),
    takeEvery(adminActionTypes.MAV_PRODUCTS_EXPORTS, getMavProductsExports),
    takeEvery(adminActionTypes.GET_MAV_ASINS_STATS, getMavStats),
    takeEvery(adminActionTypes.MAV_INTERNAL_PRODUCTS_EXPORTS, getMavInternalProductsExports),
    takeEvery(brandsProductsActionTypes.GET, getBrandsSettingsPeriods),
    takeEvery(adminActionTypes.PATCH_BRANDS_SETTINGS_PERIODS, patchBrandsSettingsPeriods),
    takeEvery(adminActionTypes.POST_BRANDS_SETTINGS_PERIOD, postBrandsSettingsPeriod),
    takeEvery(adminActionTypes.DELETE_BRANDS_SETTINGS_PERIOD, deleteBrandsSettingsPeriod),
    takeEvery(adminActionTypes.GET_BRANDS_PUBLISHERS, getPublishersById),
    takeEvery(adminActionTypes.ADD_BRANDS_PUBLISHERS, addPublisherById),
    takeEvery(adminActionTypes.DELETE_BRANDS_PUBLISHERS, deletePublisherById),
    takeEvery(adminActionTypes.SWITCH_BRAND_PRODUCTS_MAV_ELIGIBLE_STATUS, switchAllBrandProductsMavEligibleStatus),
  ]);
}
