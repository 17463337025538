import moment, { Moment } from 'moment';

export const getPriorityRankInt = (rank: string) => {
  switch (rank) {
    case 'HIGH':
      return 1;
    case 'MEDIUM':
      return 2;
    case 'LOW':
      return 3;
    default:
      return 2;
  }
};

export const getPreviousRange = (from: Moment, to: Moment) => {
  const diff = moment(to).diff(from, 'days');
  const start_date = moment(from).subtract(diff, 'days');
  const end_date = from.clone();

  return { start_date, end_date };
};
