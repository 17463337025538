import { getDataListReducer } from 'common/store/reducers/data-list';
import { combineReducers } from 'redux';
import {
  campaignsActionTypes,
  descriptionsActionTypes,
  headlinesActionTypes,
  keywordsActionTypes,
  ordersActionTypes,
} from '../actions';
import post from './post';
import precalculated from './precalculated';

export const campaignCreationReducer = combineReducers({
  orders: getDataListReducer<CampaignCreationTypes.Order>(ordersActionTypes),
  suggestedHeadlines: getDataListReducer<string>(headlinesActionTypes),
  suggestedDescriptions: getDataListReducer<string>(descriptionsActionTypes),
  suggestedKeywords: getDataListReducer<string>(keywordsActionTypes),
  campaigns: getDataListReducer<CampaignCreationTypes.Campaign>(campaignsActionTypes),
  precalculated,
  post,
});
