import { ApiService } from 'common/services';
import { IGetQueryString } from 'common/ui/base-filters/types';
import { parseFilters } from 'common/utils/parse-filters';

class MappingsApiService extends ApiService {
  getMappingsImports = (filters: IGetQueryString) => {
    return this.get(`/api/ads/reporting/${filters.brand_id}/imports`, { params: parseFilters(filters) });
  };

  getMappings = (brandId: number, importId: number) => {
    return this.get(`/api/ads/reporting/${brandId}/imports/${importId}/mapping`);
  };
}

export const mappingsApi = new MappingsApiService(process.env.REACT_APP_API_URL);
