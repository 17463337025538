import { getDataListReducer } from 'common/store/reducers/data-list';
import { getFiltersReducer } from 'common/store/reducers/filters';
import { combineReducers } from 'redux';
import exportReducer from './products-export';
import { productsFiltersActionTypes, productsListActionTypes, productsForOrdersActionTypes } from '../actions';

export const productsReducer = combineReducers({
  filters: getFiltersReducer(productsFiltersActionTypes),
  list: getDataListReducer<Products.Product>(productsListActionTypes),
  export: exportReducer,
  listForOrders: getDataListReducer<Products.Product>(productsForOrdersActionTypes),
});
