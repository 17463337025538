import ReactDOM from 'react-dom/client';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter as Router } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { ThemeProvider } from '@mui/material';
import { InternalAuthProvider } from 'root/auth';
import { theme } from 'common/constants/theme';
import { MenuCtx } from 'root/layout/menu/menu-ctx';
import { QuickStartProvider } from 'root/layout/quick-start-provider';
import { App } from './root';
import './config/i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    redirectUri={window.location.origin}
    audience="https://carrick-ui.advoncommerce.com/api"
    cacheLocation="localstorage"
  >
    <Router>
      <ThemeProvider theme={theme}>
        <QuickStartProvider>
          <InternalAuthProvider>
            <MenuCtx>
              <Toaster />
              <App />
            </MenuCtx>
          </InternalAuthProvider>
        </QuickStartProvider>
      </ThemeProvider>
    </Router>
  </Auth0Provider>
);
