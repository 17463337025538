import { getDataListActionTypes } from 'common/store/actions/data-list';
import { getFiltersActionTypes } from 'common/store/actions/filters';

const PRODUCTS_MODULE = '[products]';
const PRODUCTS_FILTERS_MODULE = '[products-filters]';
const PRODUCTS_FOR_ORDERS_MODULE = '[products:orders]';

const IMPORT_PRODUCTS = `${PRODUCTS_MODULE} IMPORT_PRODUCTS`;

const EXPORT_PRODUCTS = `${PRODUCTS_MODULE} EXPORT_PRODUTCS`;
const EXPORT_SUCCESS = `${PRODUCTS_MODULE} EXPORT_SUCCESS`;
const EXPORT_ERROR = `${PRODUCTS_MODULE} EXPORT_ERROR`;

const DELETE_PRODUCT = `${PRODUCTS_MODULE} DELETE_PRODUCT`;
const DELETE_PRODUCT_SUCCESS = `${PRODUCTS_MODULE} DELETE_PRODUCT_SUCCESS`;
const DELETE_PRODUCT_ERROR = `${PRODUCTS_MODULE} DELETE_PRODUCT_ERROR`;
const DELETE_PRODUCTS = `${PRODUCTS_MODULE} DELETE_PRODUCTS`;

const CHANGE_CATEGORY = `${PRODUCTS_MODULE} CHANGE_CATEGORY`;
const CHANGE_CATEGORY_SUCCESS = `${PRODUCTS_MODULE} CHANGE_CATEGORY_SUCCESS`;
const CHANGE_CATEGORY_ERROR = `${PRODUCTS_MODULE} CHANGE_CATEGORY_ERROR`;

const CHANGE_TARGET_ACOS = `${PRODUCTS_MODULE} CHANGE_TARGET_ACOS`;
const CHANGE_TARGET_ACOS_SUCCESS = `${PRODUCTS_MODULE} CHANGE_TARGET_ACOS_SUCCESS`;
const CHANGE_TARGET_ACOS_ERROR = `${PRODUCTS_MODULE} CHANGE_CATEGORY_ERROR`;

const CHANGE_MAV_PERCENT = `${PRODUCTS_MODULE} CHANGE_MAV_PERCENT`;
const CHANGE_MAV_PERCENT_SUCCESS = `${PRODUCTS_MODULE} CHANGE_MAV_PERCENT_SUCCESS`;
const CHANGE_MAV_PERCENT_ERROR = `${PRODUCTS_MODULE} CHANGE_MAV_PERCENT_ERROR`;

const UPDATE_AD_CHANNEL = `${PRODUCTS_MODULE} UPDATE_AD_CHANNEL`;
const UPDATE_AD_CHANNEL_SUCCESS = `${PRODUCTS_MODULE} UPDATE_AD_CHANNEL_SUCCESS`;
const UPDATE_AD_CHANNEL_ERROR = `${PRODUCTS_MODULE} UPDATE_AD_CHANNEL_ERROR`;

export const productsFiltersActionTypes = {
  ...getFiltersActionTypes(PRODUCTS_FILTERS_MODULE),
  UPDATE_BRANDS_IDS: `${PRODUCTS_MODULE} UPDATE_BRANDS_IDS`,
  SET_BRANDS_IDS: `${PRODUCTS_MODULE} SET_BRANDS_IDS`,
  GET_BRAND_PRODUCTS_BRANDS: `${PRODUCTS_MODULE} GET_BRAND_PRODUCTS_BRANDS`,
  SET_BRAND_PRODUCTS_BRANDS: `${PRODUCTS_MODULE} SET_BRAND_PRODUCTS_BRANDS`,
  APPLY_FILTERS: `${PRODUCTS_MODULE} APPLY_FILTERS`,
};
export const productsListActionTypes = getDataListActionTypes(PRODUCTS_MODULE);
export const productsForOrdersActionTypes = getDataListActionTypes(PRODUCTS_FOR_ORDERS_MODULE);

export const productsActionTypes = {
  IMPORT_PRODUCTS,
  EXPORT_PRODUCTS,
  EXPORT_SUCCESS,
  EXPORT_ERROR,
  DELETE_PRODUCT,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_ERROR,
  CHANGE_CATEGORY,
  CHANGE_CATEGORY_SUCCESS,
  CHANGE_CATEGORY_ERROR,
  CHANGE_TARGET_ACOS,
  CHANGE_TARGET_ACOS_SUCCESS,
  CHANGE_TARGET_ACOS_ERROR,
  CHANGE_MAV_PERCENT,
  CHANGE_MAV_PERCENT_SUCCESS,
  CHANGE_MAV_PERCENT_ERROR,
  UPDATE_AD_CHANNEL,
  UPDATE_AD_CHANNEL_SUCCESS,
  UPDATE_AD_CHANNEL_ERROR,
  DELETE_PRODUCTS,
};
