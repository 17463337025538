import { Action } from 'common/interfaces/root';
import { RESET_REDUCER } from 'common/store/actions';
import { brandSettingsActionTypes as T } from '../actions';

const initState: BrandSettingsTypes.WalletStore = {
  id: 0,
  balance: 0,
};

export default (
  state: BrandSettingsTypes.WalletStore = initState,
  { type, payload }: Action
): BrandSettingsTypes.WalletStore => {
  switch (type) {
    case T.GET_WALLET_INFO:
      return { ...state, id: payload.id, balance: payload.balance };

    case RESET_REDUCER:
      return { ...initState };

    default:
      return state;
  }
};
