const MODULE = '[global:filters]';

export const getFiltersActionTypes = (module: string) => ({
  CHANGE_RANGE: `${module} CHANGE_RANGE`,
  CHANGE_BRAND: `${module} CHANGE_BRAND`,
  CHANGE_SEARCH: `${module} CHANGE_SEARCH`,
  CHANGE_IS_PAID: `${module} CHANGE_IS_PAID`,
  CHANGE_USED: `${module} CHANGE_USED`,
  CHANGE_AFFILIATE_NETWORK_TYPE: `${module} CHANGE_AFFILIATE_NETWORK_TYPE`,
  CHANGE_DATE_BY: `${module} CHANGE_DATE_BY`,
  CHANGE_CLIENT: `${module} CHANGE_CLIENT`,
  CHANGE_SORT: `${module} CHANGE_SORT`,
});

export const filtersActionTypes = getFiltersActionTypes(MODULE);
