import { ApiService } from 'common/services';
import { EditableBrandFields } from 'common/types';

class BrandApiService extends ApiService {
  getBrand = (id: number) => {
    return this.get(`/api/brands/${id}`);
  };

  editBrand = (brand: EditableBrandFields, id: number) => {
    return this.patchFormData(`/api/settings/brands/${id}`, {
      ...(brand.logo ? { file: brand.logo } : {}),
      ...(brand.logo === null ? { logo: null } : {}),
      ...(brand.name ? { name: brand.name } : {}),
      ...(brand.address ? { address: brand.address } : {}),
      ...(brand.socials ? { socials: brand.socials } : {}),
      ...(brand.url ? { url: brand.url } : {}),
      ...(brand.acct_contact_email || brand.acct_contact_email === ''
        ? { acct_contact_email: brand.acct_contact_email }
        : {}),
      ...(brand.acct_contact_first_name || brand.acct_contact_first_name === ''
        ? { acct_contact_first_name: brand.acct_contact_first_name }
        : {}),
      ...(brand.acct_contact_last_name || brand.acct_contact_last_name === ''
        ? { acct_contact_last_name: brand.acct_contact_last_name }
        : {}),
      ...(brand.ar_contact_email || brand.ar_contact_email === '' ? { ar_contact_email: brand.ar_contact_email } : {}),
      ...(brand.ar_contact_first_name || brand.ar_contact_first_name === ''
        ? { ar_contact_first_name: brand.ar_contact_first_name }
        : {}),
      ...(brand.ar_contact_last_name || brand.ar_contact_last_name === ''
        ? { ar_contact_last_name: brand.ar_contact_last_name }
        : {}),
      ...(brand.lifetime_budget ? { lifetime_budget: brand.lifetime_budget } : {}),
      ...(brand.account_manager_id ? { account_manager_id: brand.account_manager_id } : {}),
      ...(brand.account_manager_id === '' ? { account_manager_id: null } : {}),
    });
  };
}

export const brandApi = new BrandApiService(process.env.REACT_APP_API_URL);
