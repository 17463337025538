import { Integration } from 'common/services/api/integrations-api.service';
import { getDataListActions } from 'common/store/actions/data-list';
import { Brand, EditableBrandFields } from 'common/types';
import {
  attributionProfilesListTypes,
  attributionPublishersListTypes,
  attributionStoresListTypes,
  attributionAsinsListTypes,
  brandSettingsActionTypes as T,
  integrationsListActionTypes,
  userWalletActionTypes,
  walletTransactionsActionTypes,
  docsListTypes,
} from './action-types';

const revokeIntegration = (payload: { integrationId: number; brandId: number }) => ({
  type: T.REVOKE_INTEGRATION,
  payload,
});

const initIntegration = (brandId: number, integrationType: string, redirectUrl: string) => ({
  type: T.INIT_INTEGRATION,
  payload: { brandId, integrationType, redirectUrl },
});

const getBrand = (brandId: number) => ({
  type: T.GET_BRAND,
  payload: brandId,
});

const getBrandSuccess = (brand: Brand) => ({
  type: T.GET_BRAND_SUCCESS,
  payload: brand,
});

const getBrandError = (error: Error) => ({
  type: T.GET_BRAND,
  payload: error,
});

const updateBrand = (brand: EditableBrandFields, brandId: number) => ({
  type: T.UPDATE_BRAND,
  payload: { brand, brandId },
});

const updateBrandSuccess = () => ({
  type: T.UPDATE_BRAND_SUCCESS,
});

const updateBrandError = (error: Error) => ({
  type: T.UPDATE_BRAND_ERROR,
  payload: error,
});

const getAttributionData = (brandId: number) => ({
  type: T.GET_ATTRIBUTION_DATA,
  payload: brandId,
});

const getAttributionDataSuccess = (data: BrandSettingsTypes.AttributionData) => ({
  type: T.GET_ATTRIBUTION_DATA_SUCCESS,
  payload: data,
});

const getAttributionDataError = (error: Error) => ({
  type: T.GET_ATTRIBUTION_DATA_ERROR,
  payload: error,
});

const postAttributionData = (data: BrandSettingsTypes.AttributionForm, brandId: number) => ({
  type: T.POST_ATTRIBUTION_DATA,
  payload: { data, brandId },
});

const postAttributionDataSuccess = () => ({
  type: T.POST_ATTRIBUTION_DATA_SUCCESS,
});

const postAttributionDataError = (error: Error) => ({
  type: T.POST_ATTRIBUTION_DATA_ERROR,
  payload: error,
});

const patchAttributionData = (data: BrandSettingsTypes.AttributionForm, brandId: number, attributionId: number) => ({
  type: T.PATCH_ATTRIBUTION_DATA,
  payload: { data, brandId, attributionId },
});

const patchAttributionDataSuccess = () => ({
  type: T.PATCH_ATTRIBUTION_DATA_SUCCESS,
});

const patchAttributionDataError = (error: Error) => ({
  type: T.PATCH_ATTRIBUTION_DATA_ERROR,
  payload: error,
});

const getAttributionTag = (
  brandId: number,
  profileId: string,
  publisherId: string,
  onSuccess: (tag: string) => void
) => ({
  type: T.GET_ATTRIBUTION_TAG,
  payload: { brandId, profileId, publisherId },
  meta: { onSuccess },
});

const getWalletInfo = (payload: { id: number; balance: number }) => ({
  type: T.GET_WALLET_INFO,
  payload,
});

const addNewCard = (payload: BrandSettingsTypes.NewCard, onSuccess: () => void) => ({
  type: T.ADD_NEW_CARD,
  payload,
  meta: { onSuccess },
});

const addNewCardToWallet = (payload: { data: BrandSettingsTypes.NewCard; id: number }, onSuccess: () => void) => ({
  type: T.ADD_NEW_CARD_TO_WALLET,
  payload,
  meta: { onSuccess },
});

const deleteCard = (payload: { id: number; card_id: number }, onSuccess: () => void) => ({
  type: T.DELETE_CARD,
  payload,
  meta: { onSuccess },
});

const addFunds = (payload: { id: number; funds: BrandSettingsTypes.FundsForm }) => ({
  type: T.ADD_FUNDS,
  payload,
});

const addFundsSuccess = () => ({
  type: T.ADD_FUNDS_SUCCESS,
});

const addFundsError = (error: Error) => ({
  type: T.ADD_FUNDS_ERROR,
  payload: { error },
});

const exportPdfTransaction = (payload: { id: number; name: string }) => ({
  type: T.EXPORT_PDF_TRANSACTION,
  payload,
});

const getBrandTeam = (payload: string) => ({
  type: T.GET_BRAND_TEAM,
  payload,
});

const getBrandTeamSuccess = (data: Array<BrandSettingsTypes.UserFormData>) => ({
  type: T.GET_BRAND_TEAM_SUCCESS,
  payload: { data },
});

const getBrandTeamError = (error: Error) => ({
  type: T.GET_BRAND_TEAM_ERROR,
  payload: error,
});

const sendInvitation = (email: string, clientId: string, onSuccess: () => void, onError: () => void) => ({
  type: T.SEND_INVITATION,
  payload: { email, clientId },
  meta: { onSuccess, onError },
});

const sendInvitationSuccess = () => ({
  type: T.SEND_INVITATION_SUCCESS,
});

const sendInvitationError = (error: Error) => ({
  type: T.SEND_INVITATION_ERROR,
  payload: error,
});

const getPendingInvitations = (clientId: string) => ({
  type: T.GET_PENDING_INVITATIONS,
  payload: { clientId },
});

const getPendingInvitationsSuccess = (data: Array<BrandSettingsTypes.UserFormData>) => ({
  type: T.GET_PENDING_INVITATIONS_SUCCESS,
  payload: { data },
});

const getPendingInvitationsError = (error: Error) => ({
  type: T.GET_PENDING_INVITATIONS_ERROR,
  payload: error,
});

const removeTeamMember = (payload: { brandId: string; userId: string }) => ({
  type: T.REMOVE_TEAM_MEMBER,
  payload,
});

const removeTeamMemberSuccess = (payload: string) => ({
  type: T.REMOVE_TEAM_MEMBER_SUCCESS,
  payload,
});

const removeTeamMemberError = (error: Error) => ({
  type: T.REMOVE_TEAM_MEMBER_ERROR,
  payload: error,
});

const postDocument = (file: File, brandId: number) => ({
  type: T.POST_DOCUMENT,
  payload: { file, brandId },
});

const postDocumentSuccess = () => ({
  type: T.POST_DOCUMENT_SUCCESS,
});

const postDocumentError = (error: Error) => ({
  type: T.POST_ATTRIBUTION_DATA_ERROR,
  payload: error,
});

const getTeam = (payload?: number) => ({
  type: T.GET_TEAM,
  payload,
});

const getTeamSuccess = (data: Array<BrandSettingsTypes.UserTeamData>) => ({
  type: T.GET_TEAM_SUCCESS,
  payload: { data },
});

const getTeamError = (error: Error) => ({
  type: T.GET_TEAM_ERROR,
  payload: error,
});

const getClients = () => ({
  type: T.GET_CLIENTS,
});

const getClientsSuccess = (data: Array<BrandSettingsTypes.UserFormData>) => ({
  type: T.GET_CLIENTS_SUCCESS,
  payload: { data },
});

const getClientsError = (error: Error) => ({
  type: T.GET_CLIENTS_ERROR,
  payload: error,
});

const patchTeamUser = (payload: BrandSettingsTypes.PatchUserTeam, onSuccess: () => void) => ({
  type: T.PATCH_TEAM_USER,
  payload,
  meta: { onSuccess },
});

const patchTeamUserSuccess = (payload: BrandSettingsTypes.PatchUserTeam) => ({
  type: T.PATCH_TEAM_USER_SUCCESS,
  payload,
});

const patchTeamUserError = (error: Error) => ({
  type: T.PATCH_TEAM_USER_ERROR,
  payload: error,
});

const cancelTeamUser = (payload: string, onSuccess: () => void) => ({
  type: T.CANCEL_TEAM_USER,
  payload,
  meta: { onSuccess },
});

const cancelTeamUserSuccess = () => ({
  type: T.CANCEL_TEAM_USER_SUCCESS,
});

const cancelTeamUserError = (error: Error) => ({
  type: T.CANCEL_TEAM_USER_ERROR,
  payload: error,
});

const resendTeamUser = (payload: string, onSuccess: () => void) => ({
  type: T.RESEND_TEAM_USER,
  payload,
  meta: { onSuccess },
});

const resendTeamUserSuccess = () => ({
  type: T.RESEND_TEAM_USER_SUCCESS,
});

const resendTeamUserError = (error: Error) => ({
  type: T.RESEND_TEAM_USER_ERROR,
  payload: error,
});

export const integrationsListActions = getDataListActions<Integration>(integrationsListActionTypes);
export const userWalletListActions = getDataListActions(userWalletActionTypes);
export const walletTransactionsListActions = getDataListActions(walletTransactionsActionTypes);
export const attributionProfilesListActions =
  getDataListActions<BrandSettingsTypes.AttributionProfile>(attributionProfilesListTypes);
export const attributionPublishersListActions =
  getDataListActions<BrandSettingsTypes.AttributionPublisher>(attributionPublishersListTypes);
export const attributionStoresListActions =
  getDataListActions<BrandSettingsTypes.AttributionStoreEntity>(attributionStoresListTypes);
export const attributionAsinsListActions = getDataListActions<string>(attributionAsinsListTypes);
export const docsListActions = getDataListActions<BrandSettingsTypes.Document>(docsListTypes);

export const brandSettingsActions = {
  revokeIntegration,
  initIntegration,
  getAttributionData,
  getAttributionDataSuccess,
  getAttributionDataError,
  postAttributionData,
  postAttributionDataSuccess,
  postAttributionDataError,
  patchAttributionData,
  patchAttributionDataSuccess,
  patchAttributionDataError,
  getAttributionTag,
  getBrand,
  getBrandSuccess,
  getBrandError,
  updateBrand,
  updateBrandSuccess,
  updateBrandError,
  getWalletInfo,
  addNewCard,
  addNewCardToWallet,
  deleteCard,
  addFunds,
  addFundsSuccess,
  addFundsError,
  exportPdfTransaction,
  getBrandTeam,
  getBrandTeamSuccess,
  getBrandTeamError,
  sendInvitation,
  sendInvitationSuccess,
  sendInvitationError,
  postDocument,
  postDocumentSuccess,
  postDocumentError,
  removeTeamMember,
  removeTeamMemberSuccess,
  removeTeamMemberError,
  getPendingInvitations,
  getPendingInvitationsSuccess,
  getPendingInvitationsError,
  getTeam,
  getTeamSuccess,
  getTeamError,
  getClients,
  getClientsSuccess,
  getClientsError,
  patchTeamUser,
  patchTeamUserSuccess,
  patchTeamUserError,
  cancelTeamUser,
  cancelTeamUserSuccess,
  cancelTeamUserError,
  resendTeamUser,
  resendTeamUserSuccess,
  resendTeamUserError,
};
