import { saveAs } from 'file-saver';
import { ApiService } from 'common/services';
import { IGetQueryString } from 'common/ui/base-filters/types';
import { parseFilters } from 'common/utils/parse-filters';

class PaymentApiService extends ApiService {
  addNewPayment = (data: BrandSettingsTypes.NewCard) => {
    return this.post('/api/wallets', data);
  };

  addNewCardToWallet = (payload: { data: BrandSettingsTypes.NewCard; id: number }) => {
    return this.post(`/api/wallets/${payload.id}/cards`, payload.data);
  };

  deleteCard = (payload: { id: number; card_id: number }) => {
    return this.delete(`/api/wallets/${payload.id}/cards/${payload.card_id}`);
  };

  getPaymentInfo = (payload: IGetQueryString) => {
    return this.get(`/api/wallets${payload.brand_id ? `?client_id=${payload.brand_id}&client_type=brand` : ''}`);
  };

  getWalletCards = (id: number) => {
    return this.get(`/api/wallets/${id}/cards`);
  };

  getWalletTransactions = (payload: IGetQueryString) => {
    return this.get(`/api/wallets/${payload.wallet_id}/transactions`, { params: parseFilters(payload) });
  };

  addFunds = (payload: { id: number; funds: BrandSettingsTypes.FundsForm }) => {
    return this.post(`/api/wallets/${payload.id}/replenishment`, payload.funds);
  };

  exportPdfTransaction = (payload: { id: number; name: string }) => {
    return this.get(`/api/accounting/charge/transactions/${payload.id}/export-pdf?customerName=${payload.name}`, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(response => {
      const blob = new Blob([response.data], {
        type: 'application/pdf',
      });
      const filename = 'invoice.pdf';
      saveAs(blob, filename);
    });
  };
}

export const paymentApi = new PaymentApiService(process.env.REACT_APP_API_URL);
