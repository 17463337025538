import { Action } from 'common/interfaces/root';
import { RESET_REDUCER } from '../actions';

export interface DataStore<D> {
  loading: boolean;
  error: Error | null;
  data: Array<D>;
  cacheTime?: number;
  page?: number;
  limit?: number;
  amount?: number;
  canLoadMore?: boolean;
}

interface ReducerOptions {
  limit?: number;
  skipGlobalReset?: boolean;
}

const getInitState = <D>(limit = 10): DataStore<D> => ({
  loading: false,
  error: null,
  data: [],
  cacheTime: 0,
  page: 0,
  amount: 0,
  limit,
  canLoadMore: true,
});

export const getDataListReducer =
  <D>(actionTypes: Record<string, string>, options?: ReducerOptions) =>
  (state: DataStore<D> = getInitState<D>(options?.limit ?? 10), { type, payload }: Action): DataStore<D> => {
    switch (type) {
      case actionTypes.GET:
        return { ...state, loading: true };

      case actionTypes.GET_SUCCESS:
        return {
          ...state,
          loading: false,
          data: payload.data,
          amount: payload.amount,
          cacheTime: payload.meta?.cacheTime || 0,
        };

      case actionTypes.GET_SUCCESS_LAZY:
        return {
          ...state,
          loading: false,
          data: [...state.data, ...payload.data],
          amount: payload.amount,
          cacheTime: payload.meta?.cacheTime || 0,
        };

      case actionTypes.GET_ERROR:
        return { ...state, loading: false, error: payload };

      case actionTypes.CHANGE_PAGE:
        return { ...state, page: payload };

      case actionTypes.CHANGE_LIMIT:
        return { ...state, limit: payload };

      case actionTypes.DELETE_SUCCESS:
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return { ...state, data: state.data.filter(entry => (entry as any)[payload.field] !== payload.id) };

      case actionTypes.UPDATE_ENTITY:
        return {
          ...state,
          data: state.data.map(entry => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return String((entry as any)[payload.field]) !== String(payload.id)
              ? entry
              : { ...entry, ...payload.updatedFields };
          }),
        };

      case actionTypes.CHANGE_CAN_LOAD_MORE:
        return { ...state, canLoadMore: payload };

      case actionTypes.RESET:
        return getInitState(options?.limit ?? 10);

      case RESET_REDUCER:
        return options?.skipGlobalReset ? state : getInitState(options?.limit ?? 10);

      default:
        return state;
    }
  };
