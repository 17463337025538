import { ApiService } from 'common/services';
import { IGetQueryString } from 'common/ui/base-filters/types';
import { parseFilters } from 'common/utils/parse-filters';
import { Order } from '../types';

class StatisticsApiService extends ApiService {
  getOrdersList = (filters: IGetQueryString) => {
    return this.get('/api/orders/summary', {
      params: parseFilters(filters, {
        date_from: 'start_date',
        date_to: 'end_date',
      }),
    });
  };

  getDirectRevenue = (filters: IGetQueryString) => {
    return this.get(`/api/v2/adipx/reporting/${filters.brand_id}/direct-revenue`, {
      params: parseFilters(filters, { date_from: 'start_date', date_to: 'end_date' }),
    });
  };

  createBrand = (payload: Order) => {
    return this.post('/api/orders', payload);
  };

  createOrderWithRequest = (payload: Order) => {
    return this.post('/api/orders/request', payload);
  };

  patchBrand = (payload: { data: Order; id: string; brandId: number }) => {
    return this.patch(`/api/orders/${payload.id}?brand_id=${payload.brandId}`, payload.data);
  };

  deleteBrand = (brandId: number, orderId: string) => {
    return this.delete(`/api/orders/${orderId}?brand_id=${brandId}`);
  };

  getOrderLinks = (orderId: string) => {
    return this.get(`/api/v2/adpx/orders/${orderId}/links`);
  };
}

export const ordersApi = new StatisticsApiService(process.env.REACT_APP_API_URL);
