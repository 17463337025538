import { Box, Dialog, Typography, TextField, Button } from '@mui/material';
import { ReactElement } from 'react';
import { Styles } from 'common/types';
import { ReactComponent as ProductVideoIcon } from 'common/svg/product-video.svg';
import { useSelector } from 'react-redux';
import { userService } from 'common/services/user.service';
import { selectUserData } from 'modules/user-settings/store/selectors';
import { ReactComponent as CopyIcon } from 'common/svg/copy-icon.svg';
import { toast } from 'react-hot-toast';

const styles: Styles = {
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '57px',
    boxSizing: 'border-box',
  },
  title: {
    marginTop: '5px',
    fontSize: '22px',
    lineHeight: '26px',
    textAlign: 'center',
    fontWeight: 600,
  },
  desc: {
    marginTop: '10px',
    color: '#214254',
  },
  select: {
    width: '100%',
  },
  btn: {
    marginTop: '20px',
    width: '100%',
  },
  selectArea: {
    maxHeight: '200px',
  },
  link: {
    color: '#4288F0',
    fontSize: '12px',
    cursor: 'pointer',
  },
  copyBtn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    marginLeft: '10px',
  },
  copyText: {
    color: '#4288F0',
  },
  linkWrapper: {
    marginTop: '28px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
};

interface Props {
  open: boolean;
  onClose: () => void;
}

export function ProductVideoModal({ open, onClose }: Props): ReactElement {
  const user = useSelector(selectUserData);

  const handleSubmit = () => {
    // dispatch(
    //   postProdVideoEmail(
    //     {
    //       to: 'vlad@advoncommerce.com',
    //       subject: 'Interested Video Client!',
    //       html: `<p> The below brand is interested in video -<br>Brand: ${selectedBrandName}<br>User: ${
    //         user?.email ?? userService.user.email
    //       }<br><br>Thank you!</p>`,
    //     },
    //     {
    //       brand: selectedBrandName,
    //       user: userService.user.email ?? '',
    //       asin: productAsin,
    //     }
    //   )
    // );
    onClose();
  };

  const handleCopyCode = () => {
    const input = document.getElementById('link');
    input.focus();
    navigator.clipboard.writeText(
      `https://register.maverickx.io/quick-start?ref=ui${user?.id ?? userService.user.internal_id}`
    );
    toast.success('Link has been copied');
  };

  return (
    <Dialog maxWidth="xs" fullWidth open={open} onClose={onClose}>
      <Box sx={styles.content}>
        <ProductVideoIcon />
        <Typography sx={styles.title}>Up to 10% commission earn for referrals that sign up through you.</Typography>
        <Typography sx={styles.desc}>Interested? Share your unique referral Link:</Typography>
        <Box sx={styles.linkWrapper}>
          <TextField
            id="link"
            value={`https://register.maverickx.io/quick-start?ref=ui${user?.id ?? userService.user.internal_id}`}
            sx={styles.select}
          />
          <Box sx={styles.copyBtn} onClick={handleCopyCode}>
            <CopyIcon />
            <Typography sx={styles.copyText}>Copy</Typography>
          </Box>
        </Box>
        <Button variant="contained" sx={styles.btn} onClick={handleSubmit}>
          Submit
        </Button>
      </Box>
    </Dialog>
  );
}
