import { State } from 'common/store/reducers';

export const selectProductsLimitPageAmount = (state: State) => ({
  limit: state.products.list.limit,
  page: state.products.list.page,
  amount: state.products.list.amount,
});

export const selectExportLoading = (state: State) => state.products.export.loading;

export const selectProductsFilters = (state: State) => state.products.filters;

export const selectProducts = (state: State) => state.products.list.data;

export const selectProductsLoading = (state: State) => state.products.list.loading;

export const selectProductsForOrders = (state: State) => state.products.listForOrders.data;

export const selectProductsForOrdersLoading = (state: State) => state.products.listForOrders.loading;
