import { Action } from 'common/interfaces/root';
import { mappingsLazyLoadingLimit } from 'modules/attribution-mappings/constants';
import { mappingsApi } from 'modules/attribution-mappings/services/mappings-api.service';
import { SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { attributionMappingsListActions, attributionMappingsListActionTypes } from '../actions';

function* getMappings({ payload }: Action) {
  try {
    const { data } = yield call(mappingsApi.getMappingsImports, payload);
    yield put(attributionMappingsListActions.getSuccessLazy(data.items));
    yield put(attributionMappingsListActions.changeCanLoadMore(data.items.length === mappingsLazyLoadingLimit));
  } catch (err) {
    yield put(attributionMappingsListActions.getError(err as Error));
  }
}

export function* attributionMappingsSaga(): SagaIterator {
  yield all([takeEvery(attributionMappingsListActionTypes.GET, getMappings)]);
}
