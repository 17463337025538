import { Action } from 'common/interfaces/root';
import { RESET_REDUCER } from 'common/store/actions';
import { attributionPerformanceActionTypes as T } from '../actions';

const initState: AttributionPerformanceTypes.PerformanceWidgetsStore = {
  loading: false,
  error: null,
  adGroups: null,
  total: null,
  campaigns: null,
};

export default (
  state: AttributionPerformanceTypes.PerformanceWidgetsStore = initState,
  { type, payload }: Action
): AttributionPerformanceTypes.PerformanceWidgetsStore => {
  switch (type) {
    case T.GET_ATTRIBUTION_PERFORMANCE:
      return { ...state, loading: true };

    case T.GET_ATTRIBUTION_PERFORMANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        adGroups: payload.adGroups,
        total: payload.total,
        campaigns: payload.campaigns,
      };

    case T.GET_ATTRIBUTION_PERFORMANCE_ERROR:
      return { ...state, loading: false, error: payload };

    case RESET_REDUCER:
      return { ...initState };

    default:
      return state;
  }
};
